import React, { useState, useEffect } from 'react';
import LandingHeader from "../components/LandingHeader";
import Top20px from "../screens/Top20px";
import FooterComponent from "../components/footer";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Container, Divider, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CalendarMonth, GroupAdd, Search } from '@mui/icons-material';
import SlidingHeader from '../components/SlidingHeader';

const BookingsPage = () => {

    const today = new Date();
    const theme = useTheme();


    const [bookings, setBookings] = useState([])

    useEffect(() => {

        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified")
        const url = `https://backend.shubadinam.com/getbookings?localId=${localId}`;

        axios.get(url)
            .then((response) => {
                // console.log(response);
                setBookings(response.data)

            })
            .catch((error) => {
                // console.log(error);
            })

    }, [])

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const getStatus = (dateString) => {
        // Example input: 'Friday, 09/08/24'
        // console.log(dateString);

        // Convert the input date to a Date object
        // Extract date parts from the string
        const [weekday, datePart] = dateString.split(', ');
        const [day, month, year] = datePart.split('/').map(Number);

        const inputDate = new Date(`20${year}`, month - 1, day); // '20${year}' to handle 2-digit year

        const today = new Date();
        today.setHours(6, 0, 0, 0);

        inputDate.setHours(6, 0, 0, 0);
        // console.log(inputDate);

        const now = new Date();
        const localHours = now.getHours();
        const localMinutes = now.getMinutes();




        if (inputDate < today) {
            return 'Completed';
        }
        else if (inputDate > today) {
            return 'Upcoming';
        }
        // Check if the current time is after 6:00 AM local time
        else {
            if (localHours > 7 || (localHours === 7 && localMinutes > 0)) {
                return "Completed";
            }
            else {
                return "Today";
            }
        }
    };

    const statusStyles = {
        Upcoming: { color: 'green', fontWeight: 'bold' },
        Completed: { color: '#c40000', fontWeight: 'bold' },
        Today: { color: 'blue' }
    };

    const groupByOrderId = (data) => {
        return data.reduce((result, item, idx) => {
            const { order_id, homamName, homam_date } = item;

            if (!result[order_id]) {
                // Store order-level information and the first index of the order
                result[order_id] = {
                    homamName,
                    homam_date,
                    orderIndex: idx, // Store the index of the first entry for this order_id
                    entries: [] // Initialize an empty array for storing entries
                };
            }

            // Push the item along with its index for individual entries
            result[order_id].entries.push({ ...item, entryIndex: idx });

            return result;
        }, {});
    };

    const groupedData = groupByOrderId(bookings);

    const [searchQuery, setSearchQuery] = useState('');

    const lowerCaseQuery = searchQuery.toLowerCase();

    // Filter grouped data based on homamName matching the search query
    const filteredData = Object.keys(groupedData).reduce((acc, orderId) => {
        const { homamName } = groupedData[orderId];
        if (homamName.toLowerCase().includes(lowerCaseQuery)) {
            acc[orderId] = groupedData[orderId];
        }
        return acc;
    }, {});




    return (
        <Box>
            <SlidingHeader />

            <Container sx={{ mt: 15 }}>
                <Typography variant='h6' gutterBottom>My Bookings</Typography>

                <Box sx={{ width: "100%", display: "flex", justifyContent: "end", p: 2 }}>
                    <TextField
                        label="Search here"
                        variant="outlined"
                        sx={{ maxWidth: 300 }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        margin="normal"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>


                {bookings.length === 0 ? (
                    <Typography variant="h6" color="text.secondary" align="center" sx={{ p: 2 }}>
                        You haven't booked any homams yet.
                    </Typography>
                ) :

                    isMobile ? (
                        // Mobile view using cards
                        <div>
                            {Object.keys(filteredData).map((orderId, index) => {
                                const { homamName, homam_date, entries, orderIndex } = filteredData[orderId];

                                return (
                                    <Accordion key={orderId} sx={{ mb: 1, boxShadow: 0, }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ bgcolor: "#eeee", mb: 1, boxShadow: 0, p: 1, }}>
                                            <Grid container spacing={3} alignItems="center">

                                                {/* Homam Name and Status */}
                                                <Grid container item xs={12} direction="row" alignItems="center" spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body5" style={{ fontWeight: 600, color: '#333', }}>
                                                            {index + 1}. {homamName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Chip
                                                            label={getStatus(homam_date)}
                                                            color={
                                                                getStatus(homam_date) === "Upcoming"
                                                                    ? "success"
                                                                    : getStatus(homam_date) === "Today"
                                                                        ? "primary"
                                                                        : "warning"
                                                            }
                                                            style={{
                                                                fontWeight: 'bold',
                                                                padding: '6px 12px',
                                                                borderRadius: '20px',
                                                                fontSize: '14px',
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                {/* Event Date Section */}
                                                <Grid container item xs={12} direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body2" color="textSecondary" style={{ fontWeight: 500 }}>
                                                            Event On :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography variant="subtitle2" style={{ fontWeight: 600, color: '#444' }}>
                                                            {homam_date}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </AccordionSummary>

                                        <AccordionDetails>

                                            {/* Titles for columns */}
                                            <Grid container spacing={2} style={{ marginBottom: '8px', fontWeight: 'bold' }}>

                                                <Grid item xs={12}>
                                                    <Typography variant="caption" color="textSecondary">No. of Participants:  {entries.length} <GroupAdd sx={{ ml: 1 }} /></Typography>
                                                </Grid>

                                                {/* <Grid item xs={2}>
                                                <Typography variant="body1" color="textSecondary">
                                                    S.no
                                                </Typography>
                                            </Grid> */}
                                                <Grid item xs={4}>
                                                    <Typography variant="body1" color="textSecondary">
                                                        Name
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1" color="textSecondary">
                                                        Nakshatra
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            {/* Entries data */}
                                            {entries.map((entry) => (
                                                <Grid container key={entry.id} spacing={2} style={{ marginBottom: '8px' }}>

                                                    {/* <Grid item xs={2}>
                                                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                        {entry.id}.
                                                    </Typography>
                                                </Grid> */}
                                                    <Grid item xs={4}>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                            {entry.customersName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                            -    {entry.customersNakshatra}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </AccordionDetails>

                                    </Accordion>
                                );
                            })}
                        </div>
                    )
                        : (
                            // Desktop view using table
                            <div>
                                {Object.keys(filteredData).map((orderId, index) => {
                                    const { homamName, homam_date, entries, orderIndex } = filteredData[orderId];

                                    return (
                                        <Accordion key={orderId} sx={{ mb: 2, boxShadow: 0, maxWidth: 1024, placeSelf: "center" }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ bgcolor: "#eeee", mb: 1, boxShadow: 0, p: 1 }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    {/* Homam Name and Status */}
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography variant="h6" color='primary' sx={{ fontWeight: 600, }}>
                                                            {index + 1}. {" "}{homamName}
                                                        </Typography>
                                                    </Grid>

                                                    {/* Event Date */}
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography variant="body5" sx={{ fontWeight: 500 }}>
                                                            <CalendarMonth color='primary' sx={{ mr: 1 }} />-  {homam_date}
                                                        </Typography>
                                                    </Grid>

                                                    {/* Status Chip */}
                                                    <Grid item xs={12} sm={2}>
                                                        <Chip
                                                            label={getStatus(homam_date)}
                                                            color={
                                                                getStatus(homam_date) === "Upcoming"
                                                                    ? "success"
                                                                    : getStatus(homam_date) === "Today"
                                                                        ? "primary"
                                                                        : "warning"
                                                            }
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                padding: '6px 12px',
                                                                borderRadius: '20px',
                                                                fontSize: '14px',
                                                                width: 'fit-content', // Ensure chip fits content
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>


                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {/* Table for Entries */}
                                                    <TableContainer sx={{ marginTop: '8px' }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <Typography variant="body1" sx={{ p: 2 }} color="textSecondary">  No. of Participants :  {entries.length} <GroupAdd sx={{ ml: 1 }} /> </Typography>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell><Typography variant="body1" color="textSecondary">Name</Typography></TableCell>
                                                                    <TableCell><Typography variant="body1" color="textSecondary">Nakshatra</Typography></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {entries.map((entry) => (
                                                                    <TableRow key={entry.id}>
                                                                        <TableCell><Typography variant="body1" sx={{ fontWeight: 500 }}>{entry.customersName}</Typography></TableCell>
                                                                        <TableCell><Typography variant="body1" sx={{ fontWeight: 500 }}> - {entry.customersNakshatra}</Typography></TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                            </div>
                        )
                }

            </Container>
            <FooterComponent />
        </Box >
    );
};

export default BookingsPage;