import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Grid, Card, CardContent, CardMedia, Typography, Button, Container } from '@mui/material';
import LandingHeader from "../components/LandingHeader";
import FooterComponent from "../components/footer";
import BottomSpace from "./BottomSpace";
import { NavigateNext } from "@mui/icons-material";
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";
import { tithiData } from "./utils/tithiData";

const TithiBlogs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Tithi Blogs</title>
                <meta name="description" content="Tithi Blogs - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle={`Tithi Related Blogs`}
            />

            <Container maxWidth="lg" sx={{ p: 4, }}>


                <Grid container spacing={4}>
                    {tithiData.map((blog, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <a href={blog.link} style={{ textDecoration: 'none' }}>
                                <Card elevation={3} sx={{ height: '100%', borderRadius: "24px", bgcolor: "aliceblue" }}>
                                    <CardMedia
                                        component="img"
                                        height="160"
                                        image={blog.image}
                                        alt={blog.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5">
                                            {blog.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {blog.description}
                                        </Typography>
                                    </CardContent>
                                    <Button endIcon={<NavigateNext />} size="medium" variant="contained" color="primary" sx={{ margin: 2, textTransform: "none" }}>
                                        Read more
                                    </Button>
                                </Card>
                            </a>
                        </Grid>
                    ))}
                </Grid>

                <BottomSpace />
            </Container>
            <FooterComponent />
        </HelmetProvider>
    );
};

export default TithiBlogs;
