import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container,
    Grid,
    Typography,
    Button,
    useMediaQuery,
} from "@mui/material";
import ScrollToTop from "react-scroll-to-top";
import FooterComponent from "../components/footer";
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";


const WhyUsPage = () => {
    const isLesser768 = useMediaQuery("(max-width:768px)");

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);



    return (
        <HelmetProvider>
            <Helmet>
                <title>Why Us</title>
                <meta name="description" content="Why Us - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>

            <ScrollToTop smooth />

            {/* Hero Section */}
            <PageSlider
                bgImage="https://img.freepik.com/free-vector/cloudy-watercolor-background_91008-454.jpg?t=st=1733214096~exp=1733217696~hmac=80438bdbc706b7c1b15bf018b9dde02acaf4288302c6e958b83541e78025d4b2&w=900"
                pageTitle={`Why ${isLesser768 ? "Shubadinam" : "Us"}`}
                subTitle="Welcome to Shubadinam.com – where tradition meets technology, and your cosmic journey begins."

            />


            <SlidingHeader />


            <Container sx={{ mt: 5 }}>


                {/* First Content Block */}
                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <img
                            src={require("../images/why-us1.png")}
                            alt="Why Us"
                            style={{
                                width: "100%",
                                borderRadius: "10px",
                                // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" gutterBottom>
                            Empowering You to Celebrate Your Identity:
                        </Typography>
                        <Typography variant="body1" paragraph>
                            At Shubadinam, we're more than just a website. We're here to help you rediscover your roots and celebrate your {isLesser768 ? "Indian" : "Hindu"} identity. Imagine us as your guide on a journey to the ancient wisdom that's part of your heritage.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Shubadinam goes beyond dates, unveiling the magic of Birth Stars (Janma nakshatra)/Janma Tithi. It's like unlocking secrets of the stars on the day you were born.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our mission is to empower you, fostering a deep understanding and appreciation of your unique Janma Dinam. With our user-friendly Shubadinam finder, effortlessly receive notifications for your real Birthday and those of your friends and family.
                        </Typography>
                        {isLesser768 && (
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    mt: 2,
                                    borderRadius: "6px",
                                    '&:hover': {
                                        backgroundColor: '#ffffff',
                                        color: '#000000',
                                    },
                                }}
                                component={NavLink}
                                to="/pricing"
                            >
                                Register Now
                            </Button>
                        )}
                    </Grid>
                </Grid>

                {/* Second Content Block */}
                <Grid container spacing={6} sx={{ mt: 4 }}>
                    <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} sx={{ p: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Discover the Sacred Connection:
                        </Typography>
                        <Typography variant="body1" paragraph>
                            At Shubadinam, we blend tradition seamlessly into your daily life, fostering a community of individuals who cherish their Hindu heritage. Recognizing and celebrating your Janma Dinam becomes a sacred connection with the divine.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <NavLink to="/meanings-of-janma-nakshatra" style={{ textDecoration: "none" }}>
                                (Click here to read more on 'Meanings of Janma Nakshatra')
                            </NavLink>
                            Celebrate your Janma Dinam for Good Health, Longer Life, Ample Wealth, Great fame, and spiritual Wisdom as you journey through the celestial influences shaping your life.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Join us to celebrate your unique cosmic spark, learning about your roots.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                mt: 2,
                                borderRadius: "6px",
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#000000',
                                },
                            }}
                            component={NavLink}
                            to="/pricing"
                        >
                            Get Started
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                        <img
                            src={require("../images/why-us2.png")}
                            alt="Why Us"
                            style={{
                                width: "100%",
                                borderRadius: "10px",
                            }}
                        />
                    </Grid>
                </Grid>

            </Container>


            <FooterComponent />
        </HelmetProvider>
    );
};

export default WhyUsPage;
