import React, { useState, useEffect, } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import FooterComponent from "../components/footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import HomamEvents from "../components/HomamEvents";
import ShopComponent from "../Shopee/ShopComponent";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

import web_banner1 from "../images/banners/web-banner1.jpg";
import web_banner2 from "../images/banners/web-banner2.jpg";
import web_banner3 from "../images/banners/web-banner3.jpg";

import ph_banner1 from "../images/banners/ph-banner1.png"
import ph_banner2 from "../images/banners/ph-banner2.png"
import ph_banner3 from "../images/banners/ph-banner3.jpg"
import axios from "axios";
import { NavigateNext, PlayArrow } from "@mui/icons-material";
import SevalandingTestimonial from "../homamutils/SevalandingTestimonial";
import SlidingHeader from "../components/SlidingHeader";
import GallerySection from "./utils/GallerySection";



const LoginScreen = () => {

    const navigate = useNavigate();


    const [dashsignOut, setSignOut] = useState(false)

    const [isHomeauth, setisHomeauth] = useState(false)


    useEffect(() => {

        const auth = localStorage.getItem("homeauth");
        if (auth) {
            setSignOut(true)
            setisHomeauth(true)
        }
    }, []);


    const [ismobile, setIsmobile] = useState(false)


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsmobile(true);
            } else {
                setIsmobile(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // ip info
    // useEffect(() => {
    //     const fetchIPData = async () => {
    //         try {
    //             // Replace with your ipinfo.io API token
    //             const ipInfoToken = 'b0ecbaf60e55e4'; // Get this from ipinfo.io/account/home

    //             // Fetch IP data
    //             const response = await axios.get(`https://ipinfo.io?token=${ipInfoToken}`);
    //             const { ip, city, region, country, loc } = response.data;

    //             // Prepare payload for the backend
    //             const payload = {
    //                 ip_address: ip,
    //                 city,
    //                 state: region,
    //                 country,
    //                 coordinates: loc, // Already in "latitude,longitude" format
    //             };

    //             // Send data to your backend
    //             await axios.post('https://backend.shubadinam.com/website-views', payload);
    //         } catch (error) {
    //             console.error('Error fetching or sending IP data:', error);
    //         }
    //     };

    //     // fetchIPData();
    // }, []);


    const handleBanner = (page) => {
        if (page === "shop") {
            // Redirect to the external shop URL in a new tab
            window.open("https://shop.shubadinam.com/", "_blank");
        } else {
            // Navigate to a different page in your app
            navigate(`/${page}`);
        }
    };


    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        setIsPlaying(true);
    };

    const buttonStyles = {
        p: { xs: 1, md: 2 },
        textTransform: "none",
        borderRadius: 3,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.15)',
            color: '#fff',
        },
        '&:active': {
            transform: 'scale(1)',
            boxShadow: 'none',
        },
    };

    const BlogCard = ({ image, title, subtext, button, route }) => {


        return (
            <a href={route} >
                <Card
                    sx={{
                        cursor: "pointer",
                        position: "relative",
                        borderRadius: 6,
                        overflow: "hidden",
                        boxShadow: 5,
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                            transform: "translateY(-10px)",
                            boxShadow: 12,
                        },
                    }}
                >
                    <CardMedia
                        component="img"
                        height="300"
                        image={image}
                        alt={title}
                        onContextMenu={(e) => e.preventDefault()}
                        sx={{
                            filter: "brightness(80%)",
                            transition: "filter 0.3s ease",
                            "&:hover": { filter: "brightness(100%)" },
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            bgcolor: "rgba(255, 255, 255, 0.8)",
                            padding: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                                bgcolor: "rgba(255, 255, 255, 0.9)",
                            },
                        }}
                    >
                        <Typography
                            variant="body5"
                            fontWeight="bold"
                            color="#333"
                            sx={{ mb: 1, textTransform: "uppercase", letterSpacing: 1 }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 2, lineHeight: 1, fontSize: "0.9rem" }}
                        >
                            {subtext}
                        </Typography>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{
                                p: 1,
                                textTransform: "none",
                                borderRadius: 2,
                            }}
                        >
                            {button}
                        </Button>
                    </Box>
                </Card>
            </a>
        );
    };

    const [bgColor, setBgColor] = useState('#fadfaa'); // Initial background color

    const handleSlideChange = (splide) => {
        const activeIndex = splide.index;

        // Change background color based on the active slide
        if (activeIndex === 0) {
            setBgColor('#fadfaa'); // For the first slide
        } else if (activeIndex === 1) {
            setBgColor('#9ecfcc'); // For the second slide
        } else if (activeIndex === 2) {
            setBgColor('#7eacf1'); // For the third slide
        }
    };



    return (
        <Box sx={{ bgcolor: 'aliceblue' }}>
            <HelmetProvider>
                <Helmet>
                    <title>Shubadinam</title>
                    <meta name="description" content="Shubadinam - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />

            <SlidingHeader />

            <Box sx={{ p: { xs: 2, md: 4 }, }}>

                {/* hero slider */}
                <Box sx={{ mt: "55px", }}>
                    <Splide
                        onMove={handleSlideChange} // Listen for slide change
                        className="splide-landing"
                        options={{
                            type: 'loop',
                            autoplay: true,
                            interval: 5000, // 5 seconds per slide
                            arrows: false,  // Optional: Hide arrows
                            pagination: true, // Optional: Hide pagination
                            // padding: "80px", // Optional: You can still keep padding for other sides
                        }}
                    >

                        {
                            ismobile &&
                            <>
                                {/* premium */}
                                <SplideSlide onClick={() => handleBanner("premium-checkout")}>

                                    <Box
                                        sx={{
                                            height: "100%",  // Set a height for the box
                                            position: 'relative',  // Ensure the video fits inside the container
                                            overflow: 'hidden',
                                            cursor: "pointer"  // Hide any overflowing content
                                        }}
                                    >
                                        <img
                                            src={ph_banner1}
                                            alt="Background Image"
                                            style={{
                                                borderRadius: "20px",
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',   // Ensures the image stays behind any content
                                            }}
                                        />

                                    </Box>


                                </SplideSlide>
                                {/* shop */}
                                <SplideSlide onClick={() => handleBanner("shop")}>

                                    <Box
                                        sx={{
                                            height: "100%",  // Set a height for the box
                                            borderRadius: "20px",
                                            position: 'relative',  // Ensure the video fits inside the container
                                            overflow: 'hidden',    // Hide any overflowing content
                                        }}
                                    >
                                        <img
                                            src={ph_banner2}
                                            alt="Background Image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',   // Ensures the image stays behind any content
                                            }}
                                        />

                                    </Box>
                                </SplideSlide>
                                {/* seva */}
                                <SplideSlide onClick={() => handleBanner("virtual-puja-seva")}>
                                    <Box
                                        sx={{
                                            height: "100%",  // Set a height for the box
                                            borderRadius: "20px",
                                            position: 'relative',  // Ensure the video fits inside the container
                                            overflow: 'hidden',    // Hide any overflowing content
                                        }}
                                    >
                                        <img
                                            src={ph_banner3}
                                            alt="Background Image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',   // Ensures the image stays behind any content
                                            }}
                                        />

                                    </Box>
                                </SplideSlide>
                            </>
                        }
                        {
                            !ismobile &&

                            <>
                                {/* premium */}
                                <SplideSlide onClick={() => handleBanner("premium-checkout")} >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100%",  // Set a height for the box
                                            backgroundImage: `url(${web_banner1})`,
                                            backgroundSize: 'cover',  // Cover the entire box
                                            backgroundPosition: 'center', // Center the background image
                                            position: 'relative',
                                            borderRadius: 3,
                                            backgroundRepeat: "no-repeat",
                                            cursor: "pointer"
                                        }}
                                    >
                                    </Box>


                                </SplideSlide>
                                {/* shop */}
                                <SplideSlide onClick={() => handleBanner("shop")}>

                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100%",  // Set a height for the box
                                            backgroundImage: `url(${web_banner2})`,
                                            backgroundSize: 'cover',  // Cover the entire box
                                            backgroundPosition: 'center', // Center the background image
                                            position: 'relative',
                                            borderRadius: 3,
                                            backgroundRepeat: "no-repeat",
                                            cursor: "pointer"
                                        }}
                                    >
                                    </Box>

                                </SplideSlide>
                                {/* seva */}
                                <SplideSlide onClick={() => handleBanner("virtual-puja-seva")}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100%",  // Set a height for the box
                                            backgroundImage: `url(${web_banner3})`,
                                            backgroundSize: 'cover',  // Cover the entire box
                                            backgroundPosition: 'center', // Center the background image
                                            position: 'relative',
                                            borderRadius: 3,
                                            backgroundRepeat: "no-repeat",
                                            cursor: "pointer"
                                        }}
                                    >
                                    </Box>


                                </SplideSlide>
                            </>

                        }
                    </Splide>
                </Box>

                {/* hero content */}
                <Container maxWidth="xl" sx={{ py: { xs: 3, md: 5 } }}>
                    <Grid container spacing={4} alignItems="center">
                        {/* Left Section */}
                        <Grid item xs={12} md={6}>
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2', mb: 2 }}>
                                    Birthdays are Special
                                </Typography>
                                <Typography variant="body5" sx={{ color: '#1976d2', mb: 2, fontSize: "large" }}>
                                    Make it Impactful... Make it Shubadinam...
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                        mb: { xs: 2, sm: 3, md: 4 }, // Adjust margin-bottom for different screen sizes
                                        fontSize: { xs: '0.975rem', sm: '1rem', md: '1.125rem', xl: "1.5rem" }, // Responsive font sizes
                                        textAlign: { xs: 'left', md: 'left' }, // Center text on smaller screens
                                    }}
                                >
                                    Get reminders to celebrate the Janma Nakshatra / Janma Tithi as per the Indian calendar and make every celebration memorable.
                                </Typography>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Link to={isHomeauth ? "/dashboard" : "/pricing"} style={{ textDecoration: 'none' }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                fontSize: "1rem",
                                                border: '1px solid transparent',
                                                textTransform: "none",
                                                width: { xs: '100%', sm: 'auto' },
                                                color: 'white', bgcolor: "#1976d2",
                                                borderRadius: 1,
                                                '&:hover': {
                                                    border: '1px solid black',
                                                    bgcolor: "white",
                                                    color: "#1b3e69",
                                                    boxShadow: 2,
                                                },
                                            }}
                                        >
                                            {isHomeauth ? "Go to Dashboard" : "Sign Up Now"}
                                        </Button>
                                    </Link>
                                    <Button onClick={handlePlayClick} variant="outlined" size="small" sx={{
                                        color: '#1b3e69', bgcolor: "#fff", fontSize: "1rem",
                                        textTransform: "none",
                                    }}>
                                        <PlayArrow />   Play Video
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Right Section */}
                        <Grid item xs={12} md={6} sx={{ position: 'relative', pt: { xs: "10px !important", md: "20px !important" } }}>
                            <Box onClick={handlePlayClick}
                                sx={{
                                    cursor: "pointer",
                                    width: { xs: '100%', sm: '100%' },
                                    padding: { xs: '10px', sm: '10px' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                    borderRadius: '8px', // Add rounded corners for a modern look
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                                    overflow: 'hidden', // Ensures that the image and iframe are clipped to rounded corners
                                    backgroundImage: !isPlaying
                                        ? `url(${require("../images/thumbnails/lg_hero.png")})`
                                        : 'none', // Set the background image when not playing
                                    backgroundSize: 'cover', // Ensure the image covers the entire container
                                    backgroundPosition: 'center', // Keep the background centered
                                    backgroundRepeat: 'no-repeat', // Prevent image repetition
                                    height: '300px', // Set a fixed height for the container
                                    transition: 'background 0.3s ease', // Smooth transition for the background change
                                }}
                            >
                                {/* Play Button */}
                                {!isPlaying && (
                                    <IconButton
                                        size='large'
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 3,
                                            fontSize: '60px', // Larger play button
                                            color: '#fff',
                                            backgroundColor: "black",
                                            opacity: 0.8,
                                            transition: 'opacity 0.3s ease',
                                            '&:hover': {
                                                opacity: 1, // On hover, make the play button fully visible
                                            },
                                        }}
                                        onClick={handlePlayClick}
                                    >
                                        <PlayArrow />
                                    </IconButton>
                                )}

                                {/* Video Iframe */}
                                {isPlaying && (
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src="https://www.youtube.com/embed/x1YUd2Ane2s?autoplay=1"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{ borderRadius: '8px' }} // Keep iframe with rounded corners
                                    ></iframe>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>


                {/* services */}
                <Box sx={{ mt: 2, }}>
                    <HomamEvents />
                </Box>


                {/* tutorial */}
                <Box sx={{ width: "100%", mt: 2, display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <Box
                        sx={{
                            px: { xs: 2, sm: 3 },
                            py: 4,
                            textAlign: 'center',
                            background: 'linear-gradient(135deg, #056697, #003366)',
                            borderRadius: 5,
                            // boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2)',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                marginBottom: 3,
                                fontWeight: 'bold',
                                fontSize: { xs: '1.5rem', sm: '1.5em', md: '2rem' },
                                color: '#fff',
                                letterSpacing: '2px',
                                textTransform: 'capitalize',
                                lineHeight: 1.3,
                                textShadow: '4px 4px 10px rgba(0, 0, 0, 0.3)',
                                fontFamily: 'Montserrat, sans-serif',
                            }}
                        >
                            How to use Shubadinam?
                        </Typography>

                        {/* Image and Button Section */}
                        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                            {/* Image Section */}
                            <Grid item xs={12} sm={6} md={6}>
                                <Box
                                    sx={{
                                        borderRadius: 5,
                                        overflow: 'hidden',
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
                                        textAlign: 'center',
                                    }}
                                >
                                    <img
                                        src={require("../images/banners/landing_tutorial.jpg")}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '5px',
                                            objectFit: 'contain',
                                            maxHeight: '250px',
                                        }}
                                        loading="lazy"
                                    />
                                </Box>
                            </Grid>

                            {/* Button Section */}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 3,
                                    textAlign: 'center',
                                }}
                            >
                                {/* Description */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        marginBottom: 2,
                                        color: '#fff',
                                        fontSize: { xs: '1rem', sm: '1.1rem', md: '1.3rem' },
                                        lineHeight: 1.6,
                                        fontStyle: 'italic',
                                        maxWidth: '800px',
                                        marginX: 'auto',
                                        opacity: 0.85,
                                        fontFamily: 'Roboto, sans-serif',
                                    }}
                                >
                                    Explore our detailed step-by-step tutorials to maximize your experience with our website and services. These tutorials include detailed videos and engaging slideshows, thoughtfully designed to guide you through each step with ease.
                                </Typography>

                                {/* Buttons */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                    {/* <a href="/tutorial" style={{ textDecoration: 'none' }}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="secondary"
                                            sx={{
                                                fontSize: "1rem",
                                                ...buttonStyles,
                                                bgcolor: "#0080ff",
                                                '&:hover': {
                                                    bgcolor: "#0080ff",
                                                },
                                            }}
                                        >
                                            Demo Video
                                        </Button>
                                    </a> */}

                                    <a href="/tutorial" style={{ textDecoration: 'none' }}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                fontSize: "1rem",
                                                ...buttonStyles,
                                                bgcolor: "#1eb4b9",
                                                '&:hover': {
                                                    bgcolor: "#1eb4b9",
                                                },
                                            }}
                                        >
                                            View Tutorial
                                        </Button>
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* shop */}
                <Box maxwidth="xl">
                    <ShopComponent />
                </Box>


                {/* gallery */}
                <Box sx={{ textAlign: "center", }}>
                    <GallerySection />
                </Box>

                {/* blogs */}
                <Box maxwidth="lg" sx={{ mt: 2, p: 2, }}>
                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#2c3e50',
                            mb: 2,
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        Explore Our Blogs
                    </Typography>

                    <Typography variant="body1"
                        align="center"
                        sx={{
                            color: '#7f8c8d',
                            mb: 4,
                            fontStyle: 'italic',
                        }}>
                        Our blogs bring you expert advice, tips, and in-depth knowledge to keep you informed on our culture and tradition.
                    </Typography>

                    <Grid container spacing={6} justifyContent="center">
                        <Grid item xs={12} sm={6} md={4}>
                            <BlogCard
                                image={require("../images/boxesImages/deity2.png")}
                                title="Nakshatra Related Blogs"
                                subtext="Unlocking Destiny: Understanding Janma Nakshatra's Influence. Explore the cosmic blueprint shaping personalities."
                                button="Read More"
                                route="/blogs-nakshatra"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <BlogCard
                                image={require("../images/boxesImages/deity1.png")}
                                title="Tithi Related Blogs"
                                subtext="Decoding Destiny: Unraveling Janma Tithi's Significance. Discover the profound impact of Janma Tithi on life's journey."
                                button="Read More"
                                route="/blogs-tithi"

                            />
                        </Grid>
                    </Grid>
                </Box>

                {/* testimonial */}
                <Box maxwidth="lg" sx={{ mt: 2, textAlign: "center", p: 2, }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <SevalandingTestimonial />
                    </Box>
                </Box>

                {/* about */}
                <Box maxwidth="lg" sx={{ textAlign: "center", mt: 1, }}>
                    {/* Title Section */}

                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#2c3e50',
                            mb: 2,
                            p: 2,
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        About Us
                    </Typography>

                    {/* Description Section */}
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginTop: 2,
                            lineHeight: 1.5,
                            color: 'text.primary', // You can adjust the text color here
                        }}
                        align="left"
                    >
                        Shubadinam stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of Hinduism. Our mission is to illuminate the importance of celebrating Birth Stars, known as Janma Nakshatra / Janma Tithi. We firmly believe that{' '}
                        <a
                            href="/about-us"
                            style={{
                                textDecoration: 'underline',
                                color: 'primary.main', // You can change this to any color you prefer
                                '&:hover': {
                                    textDecoration: 'none',
                                },
                            }}
                        >
                            read more
                        </a>
                        .
                    </Typography>
                </Box>

            </Box>
            <FooterComponent />


        </Box >

    )
}
export default LoginScreen;