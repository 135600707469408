import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, } from "react-router-dom";
import axios from "axios";

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import Swal from 'sweetalert2';
import { Box, Button, CircularProgress, FormControl, TextField, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";


AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});


const PhoneVerification = () => {


    const location = useLocation();

    useEffect(() => {
        // window.scrollTo(0, 0);
        AOS.refresh();
    }, []);

    const [dynamicInput, dynamicInputFunction] = useState({
        userPhone: "",
        countryCode: "91"
    });


    // phone handler
    const handlePhoneChange = (value, country) => {
        console.log(country.dialCode);
        console.log(value);
        dynamicInputFunction({
            ...dynamicInput,
            userPhone: value, // Store only the phone number
            countryCode: country.dialCode,
        });

    };


    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const [isOptSent, SetIsOtpSent] = useState(false);

    const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);

    const clickSubmitcheck = () => {
        setIsSubmitClicked(true)
        const countryCode = dynamicInput.countryCode;

        const dialRemovedPhone = dynamicInput.userPhone.startsWith(countryCode)
            ? dynamicInput.userPhone.slice(countryCode.length) // Remove the dialCode if it’s at the start
            : dynamicInput.userPhone; // Keep original if it doesn’t start with dialCode

        dynamicInputFunction((prev) => ({
            ...prev,
            userPhone: dialRemovedPhone
        }));


        const url = `https://backend.shubadinam.com/createSigninOtp?userPhone=${dialRemovedPhone}&&countryCode=${countryCode}`;

        axios.get(url)
            .then((response) => {
                console.log(response);
                const userEmail = response.data.userEmail; // User email if available

                // Assuming that userEmail is sent in the response, handle it accordingly
                if (userEmail) {
                    localStorage.setItem("fkey", btoa(JSON.stringify(userEmail))); // Store the encoded user email
                }
                // Set the flag indicating OTP has been sent
                SetIsOtpSent(true);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 501) {
                    Swal.fire({
                        title: 'No Records Found',
                        text: error.response.data.message,
                        icon: 'warning',
                        confirmButtonText: 'Got it!',
                        confirmButtonColor: '#3085d6',
                        background: '#f5f5f5',
                    });
                } else {
                    // Handle other errors
                    Swal.fire({
                        title: 'Error',
                        text: 'An unexpected error occurred. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'Got it!',
                        confirmButtonColor: '#3085d6',
                        background: '#f5f5f5',
                    });
                }
            });
    };



    const verifySigninOtp = () => {

        const userPhone = dynamicInput.userPhone
        const countryCode = dynamicInput.countryCode

        const signOtp = sessionStorage.getItem("otp");
        // console.log(signOtp)

        const url = `https://backend.shubadinam.com/verifySigninOtp?userPhone=${userPhone}&&userOtp=${signOtp}`

        axios.get(url)
            .then((response) => {
                // const validOtp = response.data.message
                // const navTo = localStorage.getItem("nav_")
                // if (navTo === "profile") {
                //     window.location.href = "/profile";
                //     return;
                // } else {
                const SD_Id = response.data.uniqueId;
                const token = response.data.token;


                localStorage.setItem("token", token);
                localStorage.setItem("SD_Id", SD_Id);
                localStorage.setItem('homeauth', 'true')
                window.location.reload();
                // window.location.href = "/premium-checkout";
                // clickSubmitbtn()
                // console.log(response);
                // }
            })

            .catch((error) => {
                // console.log(error);
                const invaildOtp = error.response.data.message

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5',
                });
            })
    }

    const resendOtp = () => {
        clickSubmitcheck()
    }

    const inputRefs = useRef([]);
    const [isNotNumber, setIsNotNumber] = useState(false)

    const handleInputChange = (index, value) => {

        if (!/^\d*$/.test(value)) {
            value = ""; // Set value to an empty string if not a number
            setIsNotNumber(true)
            return
        }
        setIsNotNumber(false)
        const updatedInputs = [...otpInputs];
        updatedInputs[index] = value;
        setOtpInputs(updatedInputs);

        // Combine all input values and store in sessionStorage
        const otpValue = updatedInputs.join("").trim();
        sessionStorage.setItem("otp", otpValue);

        setOtpInputs(updatedInputs);

        if (value && index < otpInputs.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index, event) => {
        // if (!/^\d$/.test(event.key)) {
        //     event.preventDefault();
        // }
        if (event.keyCode === 8 && !otpInputs[index] && index > 0) {
            // Handle Backspace key when input is empty
            inputRefs.current[index - 1].focus();
        }
    };




    return (

        < >


            <FormControl fullWidth sx={{ mb: 2 }}>
                <PhoneInput
                    validateMobilePrefix={true}
                    country={'in'} // Default country code
                    // value={dynamicInput.userPhone} // Display with the country code
                    name="userPhone"
                    onChange={handlePhoneChange}
                    inputStyle={{
                        width: '100%',
                        height: '40px',
                        borderRadius: '5px',
                        border: '1px solid #ced4da',
                        paddingLeft: '50px', // Space for the country code dropdown
                    }}
                    dropdownStyle={{
                        borderRadius: '5px',
                    }}
                />
            </FormControl>

            {
                isOptSent &&

                <Box
                    id="reg-form"
                    component="form"
                    className="reg-form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        alignSelf: 'center',
                        padding: 2,
                        // border: '1px solid #ddd',
                        borderRadius: 2,
                        width: '100%',
                        maxWidth: 400,
                        backgroundColor: "aliceblue",
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {/* Title */}
                    <Typography id="reg-title1" variant="h6" color="primary" fontWeight="bold">
                        OTP Verification
                    </Typography>

                    {/* Message */}
                    <Typography id="reg-message" variant="body2" color="textSecondary" align="center">
                        We have sent a verification code to your mobile number and email.
                    </Typography>

                    {/* Note for non-numeric input */}
                    {isNotNumber && (
                        <Typography variant="caption" color="error" align="center">
                            (Note: Please enter numeric values only.)
                        </Typography>
                    )}

                    {/* OTP Input Boxes */}
                    <Box id="reg-inputs" display="flex" gap={1}>
                        {otpInputs.map((value, index) => (
                            <TextField
                                key={index}
                                value={value}
                                inputRef={(ref) => (inputRefs.current[index] = ref)}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                variant="outlined"
                                inputProps={{
                                    maxLength: 1,
                                    inputMode: "numeric",
                                    style: { textAlign: 'center', width: '1.5rem', fontSize: '1rem' },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 1,
                                        backgroundColor: "white"
                                    },
                                }}
                            />
                        ))}
                    </Box>
                </Box>
            }



            <Box display="flex" flexDirection="column" alignItems="center" gap={2} sx={{
                mt: 2,
            }}>
                {isOptSent ? (
                    <Box display="flex" justifyContent="center" gap={2}>
                        {/* Resend OTP Button */}
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={resendOtp}
                            sx={{
                                borderRadius: 2,
                                textTransform: 'none',
                                fontWeight: 'bold',
                            }}
                        >
                            Resend OTP
                        </Button>

                        {/* Verify Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={verifySigninOtp}
                            sx={{
                                borderRadius: 2,
                                textTransform: 'none',
                                fontWeight: 'bold',
                            }}
                        >
                            Verify me
                        </Button>
                    </Box>
                ) : (
                    /* Submit Button */
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={clickSubmitcheck}
                        disabled={dynamicInput.userPhone.length < 10}
                        fullWidth
                        sx={{
                            maxWidth: 200,
                            borderRadius: 2,
                            textTransform: 'none',
                            fontWeight: 'bold',
                        }}
                    >
                        {isSubmitClicked ? (
                            <Box display="flex" alignItems="center" gap={1}>
                                <CircularProgress size={20} color="inherit" />
                                Submitting...
                            </Box>
                        ) : (
                            'Submit'
                        )}
                    </Button>
                )}
            </Box>

            {
                location.pathname !== "/virtual-seva/Avahanti-Homam" &&

                <Typography variant="body2" align="center" sx={{ mt: 2, color: 'text.secondary' }}>
                    Don’t have an account?{' '}
                    <Link
                        component={NavLink}
                        to="/pricing"
                        underline="hover"
                        color="primary"
                        sx={{
                            fontWeight: 'bold',
                            '&:hover': { textDecoration: 'underline' }
                        }}
                    >
                        Sign Up
                    </Link>
                </Typography>
            }


        </>

    );
};

export default PhoneVerification;