import React, { useState, useEffect } from 'react';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


import LandingHeader from '../components/LandingHeader';
import Top20px from '../screens/Top20px';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';
import HomamslotCard from './HomamslotCard';
import { Label } from '@mui/icons-material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import FooterComponent from '../components/footer';

import axios from 'axios';
import Swal from "sweetalert2";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import SlidingHeader from '../components/SlidingHeader';

const GanapathiHomam = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const isMobile = useMediaQuery('(max-width: 768px)');
    const isTablet = useMediaQuery('(min-width: 769px) and (max-width: 1024px)');

    useEffect(() => {
        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        if (localId) {
            axios.get(`https://backend.shubadinam.com/checkuser?userId=${localId}`)
                .then((response) => {
                    // console.log(response.data);
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'User not found !',
                        text: 'Please login to continue .',
                        icon: 'Info',
                        confirmButtonText: 'Okay',
                    })
                    // navigate("/signin")
                })
        }
        else {
            Swal.fire({
                title: 'User not found !',
                text: 'Please login to continue .',
                icon: 'info',
                confirmButtonText: 'Okay',
            })
            // navigate("/signin")
        }

    }, [])

    const [serviceName, setServiceName] = useState(''); // State for serviceName
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const getDefaultValues = () => {
            const title = location.pathname.split('/').pop();

            switch (title) {
                case 'Avahanti-Homam-booking':
                    return { service: 10, age: 10, serviceName: 'Avahanti-Homam' };
                case 'Navagraha-Homam-booking':
                    return { service: 20, age: 20, serviceName: 'Navagraha-Homam' };
                case 'Ganapathi-Homam-booking':
                    return { service: 30, age: 30, serviceName: 'Ganapathi-Homam' };
                case 'Surya-Namaskaram-booking':
                    return { service: 40, age: 40, serviceName: 'Surya-Namaskaram' };
                default:
                    return { service: 30, age: 30, serviceName: 'Ganapathi-Homam-booking' };
            }
        };

        const { serviceName: defaultServiceName } = getDefaultValues();
        setServiceName(defaultServiceName); // Set serviceName using useState hook

        sessionStorage.removeItem("homam-options");
        sessionStorage.removeItem("added users");


    }, [location.pathname]);



    const [count, setCount] = useState(1);

    const handleCount = (event) => {
        setCount(event.target.value);
    };



    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const handleContinueClick = () => {
        const queryParams = new URLSearchParams({
            // selectedOption,
            count,
            serviceName,
            chosenDate,
            amount
        }).toString();

        navigate(`/homam-details?${queryParams}`);
    };

    const handleBack = (e) => {
        window.history.back();
    }


    const handleAmountChange = (amount) => {
        setAmount(amount);
    };

    const handleEventDate = (eventtime) => {
        // console.log(`Selected date: ${eventtime}`);
    };

    const isContinueDisabled = selectedOption === '';



    const [startDate, setStartDate] = useState(new Date()); // Start with current month
    const [chosenDate, setChosenDate] = useState(); // State for chosen date



    const navtoHomam = (selectedPage) => {
        switch (selectedPage) {
            case "10":
                navigate(`/Avahanti-Homam-booking`)
                break;
            case "20":
                navigate(`/Navagraha-Sukta-Parayanam-booking`)
                break;
            case "30":
                navigate(`/Ganapathi-Homam-booking`)
                break;
            case "40":
                navigate(`/Surya-Namaskaram-booking`)
                break;

            default:
                break;
        }
    }

    const allowedDates = [
        new Date(2024, 5, 25),  // June 25, 2024
        new Date(2024, 6, 24),  // July 24, 2024
        new Date(2024, 7, 23),  // August 23, 2024
        new Date(2024, 8, 21),  // September 21, 2024
        new Date(2024, 9, 31),  // October 31, 2024
        new Date(2024, 10, 19), // November 19, 2024
        new Date(2024, 11, 19), // December 19, 2024
        new Date(2025, 0, 17),  // January 17, 2025
        new Date(2025, 1, 16),  // February 16, 2025
        new Date(2025, 2, 18),  // March 18, 2025
        new Date(2025, 3, 17),  // April 17, 2025
        new Date(2025, 4, 16),  // May 16, 2025
    ];



    const isAllowedDate = (date) => {
        return allowedDates.some(allowedDate =>
            date.getFullYear() === allowedDate.getFullYear() &&
            date.getMonth() === allowedDate.getMonth() &&
            date.getDate() === allowedDate.getDate()
        );
    };

    // const tileDisabled = ({ date, view }) => {
    //     const monthToShow = new Date(startDate.getFullYear(), startDate.getMonth() + view, 1);
    //     const startMonth = monthToShow.getMonth();
    //     const startYear = monthToShow.getFullYear();

    //     if (date.getMonth() !== startMonth || date.getFullYear() !== startYear) {
    //         return true; // Disable dates not in the current month
    //     }

    //     // Disable dates not in the allowed set
    //     if (!isAllowedDate(date)) {
    //         return true; // Disable dates not in the allowed set
    //     }

    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0); // Set time to midnight to compare only dates
    //     if (date < today) {
    //         return true;
    //     }

    //     return false; // Enable all other dates
    // };


    // const tileClassName = ({ date, view }) => {


    //     if (view === 'month') {

    //         const currentDate = new Date();
    //         const day = date.getDay();

    //         // Check if the date has passed
    //         const isPastDate = date < currentDate && date.getFullYear() === currentDate.getFullYear() && date.getMonth() === currentDate.getMonth() && date.getDate() < currentDate.getDate();

    //         if (isPastDate && (isAllowedDate(date))) {
    //             return 'highlight-past';
    //         }

    //         if (isAllowedDate(date)) {
    //             return 'highlight';
    //         }
    //     }
    //     return null;
    // };

    const tileDisabled = ({ date, view }) => {
        const monthToShow = new Date(startDate.getFullYear(), startDate.getMonth() + view, 1);
        const startMonth = monthToShow.getMonth();
        const startYear = monthToShow.getFullYear();

        // if (date.getMonth() !== startMonth || date.getFullYear() !== startYear) {
        //     return true; // Disable dates not in the current month
        // }

        // Disable dates not in the allowed set
        if (!isAllowedDate(date)) {
            return true; // Disable dates not in the allowed set
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight to compare only dates
        if (date < today) {
            return true;
        }

        return false; // Enable all other dates
    };


    const tileClassName = ({ date, view }) => {


        if (view === 'month') {

            const currentDate = new Date();
            const day = date.getDay();

            // Check if the date has passed
            const isPastDate = date < currentDate && date.getFullYear() === currentDate.getFullYear() && date.getMonth() === currentDate.getMonth() && date.getDate() < currentDate.getDate();


            // Create a specific time threshold for comparison
            const pastThresholdTime = new Date(date);
            pastThresholdTime.setHours(6, 45, 0, 0); // Set the time to 6:59 AM

            // Check if the date is today and the time has exceeded 10:50 PM
            const isPastTime = currentDate >= pastThresholdTime;

            // Highlight past Sundays after 10:50 PM
            if (isPastTime && (isAllowedDate(date))) {
                return 'highlight-past';
            }


            if (isPastDate && (isAllowedDate(date))) {
                return 'highlight-past';
            }

            if (isAllowedDate(date)) {
                return 'highlight';
            }
        }
        return null;
    };

    const [showCount, setShowCount] = useState(false)



    const handleDateChange = (selectedDate) => {
        const options = { weekday: "long", day: "2-digit", month: "2-digit", year: "numeric" };
        const formattedDate = selectedDate.toLocaleDateString('en-GB', options);
        setChosenDate(formattedDate);
        setShowCount(true);
    };

    // const calendars = [];
    // for (let i = 0; i < 3; i++) {
    //     const monthToShow = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);
    //     calendars.push(
    //         <SwiperSlide key={i}>
    //             {/* <div> */}
    //             <h5>{monthToShow.toLocaleString('default', { month: 'long', year: 'numeric' })}</h5>
    //             <Calendar
    //                 key={i}
    //                 value={startDate.getMonth() === monthToShow.getMonth() ? startDate : monthToShow}
    //                 tileDisabled={({ date }) => tileDisabled({ date, view: i })}
    //                 onChange={handleDateChange}
    //                 tileClassName={tileClassName}
    //                 defaultView="month"
    //                 maxDetail="month"
    //                 minDetail="month"
    //                 showNavigation={false}
    //             />
    //             {/* </div> */}
    //         </SwiperSlide>
    //     );
    // }

    const today = new Date();

    const calendars = [];
    let monthsDisplayed = 0;
    let currentMonthOffset = 0;

    // Loop to find three valid months
    while (monthsDisplayed < 3) {
        const monthToShow = new Date(startDate.getFullYear(), startDate.getMonth() + currentMonthOffset, 1);

        // Filter allowed dates for the current month
        const allowedForMonth = allowedDates.filter(date => date.getFullYear() === monthToShow.getFullYear() && date.getMonth() === monthToShow.getMonth());

        // Check if all allowed dates for this month are past today
        const allPast = allowedForMonth.every(date => date < today);

        // If not all dates are past, display the month
        if (!allPast) {
            calendars.push(
                <SwiperSlide key={monthsDisplayed}>
                    <h5>{monthToShow.toLocaleString('default', { month: 'long', year: 'numeric' })}</h5>
                    <Calendar
                        key={monthsDisplayed}
                        value={startDate.getMonth() === monthToShow.getMonth() ? startDate : monthToShow}
                        tileDisabled={({ date }) => tileDisabled({ date, view: currentMonthOffset })}
                        onChange={handleDateChange}
                        tileClassName={tileClassName}
                        defaultView="month"
                        maxDetail="month"
                        minDetail="month"
                        showNavigation={false}
                        showNeighboringMonth={false}
                    />
                </SwiperSlide>
            );
            monthsDisplayed++; // Increment the count of displayed months
        }

        currentMonthOffset++; // Move to the next month
    }



    return (
        <div className='homam-container'>
            <SlidingHeader />
            <Top20px />
            <div className='homam-body'>
                <div className='homam-title'>
                    <b>Ganapathi Homam</b>
                    {/* <NavLink to='/booking-history'>Booking History</NavLink> */}
                </div>
                <br />

                <div className='homam-options'>
                    <b style={{ textAlign: "start", width: "100%" }}>Service Name</b>
                    <div className='homam-options2'>
                        <Box sx={{ width: 500 }}>
                            <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="service-native">
                                    Choose other services
                                </InputLabel>
                                <NativeSelect
                                    value={30}
                                    onChange={(e) => navtoHomam(e.target.value)} // Update serviceName state on change
                                    inputProps={{
                                        name: 'service',
                                        id: 'service-native',
                                    }}
                                >
                                    <option value={10}>Avahanti Homam</option>
                                    {/* <option value={20}>Navagraha Homam</option> */}
                                    <option value={30}>Ganapathi Homam</option>
                                    <option value={40}>Surya Namaskaram</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>

                    </div>
                </div>
                <br />


                <Box sx={{ maxWidth: '100%', p: 2, }}>
                    <Typography variant="h6" sx={{ fontWeight: 700, px: 2 }}>
                        Slot
                    </Typography>

                    <Typography variant="body2" sx={{ px: 3, pb: 2 }}>
                        (Note: Select the <Box component="span" sx={{ color: 'error.main', fontWeight: 500 }}>date</Box> from the calendar below.)
                    </Typography>

                    <Box className='bookings-calender-slider'>
                        <Splide
                            options={{
                                type: 'slide',
                                perPage: isMobile ? 1 : isTablet ? 2 : 2,
                                gap: '1rem',
                                pagination: true,
                                arrows: true,
                                drag: true,
                                keyboard: 'global',
                            }}
                        >
                            {calendars.map((calendar, index) => (
                                <SplideSlide key={index}>
                                    <Box
                                        sx={{
                                            p: 2,
                                            borderRadius: 2,
                                            boxShadow: 1,
                                            mb: 2,
                                            bgcolor: "aliceblue"
                                        }}
                                    >
                                        {calendar}
                                    </Box>
                                </SplideSlide>
                            ))}
                        </Splide>
                    </Box>

                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent="space-evenly"
                        spacing={2}
                        sx={{ mt: 3, px: 2 }}
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Box sx={{ width: 12, height: 12, bgcolor: 'success.main', borderRadius: '50%' }} />
                            <Typography variant="body2">Slot is available</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Box sx={{ width: 12, height: 12, bgcolor: 'error.main', borderRadius: '50%' }} />
                            <Typography variant="body2">Quota is full</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Box sx={{ width: 12, height: 12, bgcolor: 'grey.500', borderRadius: '50%' }} />
                            <Typography variant="body2">Not available</Typography>
                        </Stack>
                    </Stack>
                </Box>

                <br />

                {
                    showCount &&

                    <>


                        <div className='homam-ticket-count'>
                            <p style={{ fontWeight: '200', color: '#454545cc' }}>
                                ( Note :{" "}👑 <span style={{ fontWeight: 'bold', color: '#1014ba' }}>Premium </span>customers , buy more than 1 slot to get 1   <span style={{ fontWeight: 'bold', color: '#e70000' }}>{" "}free </span>slot.) *
                            </p>
                            <br />
                            <div>
                                <label style={{ textAlign: "center", placeContent: "center", paddingRight: "50px" }}>Number of Slots :</label>
                                <FormControl variant="standard" sx={{ minWidth: 100, }}>
                                    {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={count}
                                        onChange={handleCount}
                                        label="Age"
                                    >

                                        {
                                            [1, 2, 3, 4, 5].map((count, index) => {
                                                return <MenuItem key={index} value={count}>{count}</MenuItem>
                                            })

                                        }

                                    </Select>
                                </FormControl>
                            </div>
                        </div>


                        <div className='homam-slot-cards'>
                            <HomamslotCard selectedOption={selectedOption} handleOptionChange={handleOptionChange} ticketCount={count} serviceName={serviceName} onEventDate={handleEventDate} choosedDate={chosenDate} onAmountChange={handleAmountChange} />

                        </div>

                    </>

                }
                <br />

                <div className='homam-note'>
                    <span>Note :</span>
                    <ol>
                        <li>  <p style={{ fontWeight: '200' }}>
                            <span style={{ fontWeight: 'bold', color: '#1014ba' }}>Premium </span>customers , buy more than 1 slot to get 1   <span style={{ fontWeight: 'bold', color: '#e70000' }}>{" "}free </span>slot.
                        </p> </li>
                        <li><p style={{ fontWeight: '200' }}>
                            <span style={{ fontWeight: 'bold', color: '#1014ba' }}>Premium </span>customers , cost of 1 free slot  <span style={{ fontWeight: 'bold', color: '#e70000' }}>{" "} Rs.{(serviceName === "Surya-Namaskaram" ? 125 : 250)}  </span>/- will be reduced from total cost.
                        </p>   </li>
                    </ol>
                    <div className='homam-continue'>
                        <Button className='homam-continue-btns' onClick={() => handleBack()}>Back</Button>
                        {
                            (chosenDate && !isNaN(new Date(chosenDate))) ? (
                                <Button className="homam-continue-btns homam-ctn-btn" onClick={handleContinueClick}>
                                    Continue
                                </Button>
                            ) : (
                                <Button className="homam-continue-btns" disabled>
                                    Continue
                                </Button>
                            )
                        }
                        {/* {
                            isContinueDisabled ?
                                <Button className='homam-continue-btns' disabled  >Continue</Button> */}
                        {/* : */}
                        <Button className="homam-continue-btns homam-ctn-btn" onClick={handleContinueClick}>Continue</Button>
                        {/* } */}

                    </div>
                </div>
                <br />
            </div>
            <FooterComponent />
        </div >
    );
};

export default GanapathiHomam;
