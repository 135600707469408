import { Box, Typography } from '@mui/material'
import React from 'react'

const PageSlider = ({ bgImage, pageTitle, subTitle }) => {
    return (
        <>
            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    height: { xs: "30vh", md: "40vh", xl: "30vh" },
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: '100% 100%',
                    backgroundPosition: { xs: "bottom", md: "center" },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    color: '#fff',
                    textAlign: 'center',

                }}
            >
                <Box sx={{ p: { xs: 3, md: 4 }, borderRadius: '10px', }}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography variant="h4" sx={{ mt: 5 }} color="primary" fontWeight="bold">
                            {pageTitle}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mt={2}>
                            {subTitle}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default PageSlider