import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import Button from '@mui/material/Button';
import { Box, Slide, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CheckCircle, HelpOutline } from "@mui/icons-material";

const steps = [
    {
        label: "Registered",
        description: "(Login Anytime, Book Seva, Watch TV, Gift Shoppe).",
        status: "completed", // First step completed
    },
    {
        label: "Janmadinam Reminders to plan your celebration",
        description: "(Email, Sms*, Whastapp*).",
        status: "pending", // Second step pending
    },
    {
        label: "Add family and friends",
        description: "(Unlimited Entries, Ads Free).",
        status: "pending", // Third step pending
    },
];

const PremiumPurchaseAlert = ({ handlePremiumButton, isProfileCompleted }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen is smaller than "sm"


    const Navigate = useNavigate();
    const [userType, setuserType] = useState("");
    const modalRef = useRef(null);
    useEffect(() => {
        const localId3 = localStorage.getItem("SD_Id");

        if (localId3) {
            const url = `https://backend.shubadinam.com/check/ispremium?localId=${localId3}`;
            axios
                .get(url)
                .then((response) => {
                    if (response.data.message === "non-premium") {
                        setuserType("non-premium");
                        handlePremiumButton("non-premium");

                        const isNonPremium = sessionStorage.getItem("nonpre");
                        if (!isNonPremium) {
                            sessionStorage.setItem('nonpre', 'true');
                            return;
                        }
                    } else {
                        setuserType("premium");
                        handlePremiumButton("premium");
                        sessionStorage.removeItem("nonpre");
                    }

                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, []);

    const [open, setOpen] = useState(false);
    const [isGreater768, setGreater768] = useState(false);

    // resize
    useEffect(() => {
        const handleResize = () => {
            setGreater768(window.innerWidth > 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Prevent background scroll when the alert is open
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.body.style.overflow = 'auto';
            document.removeEventListener("mousedown", handleOutsideClick);
        }
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [open]);

    // profile slide
    useEffect(() => {
        // If the profile is complete, don't show the alert
        if (Number(isProfileCompleted) === 100) {
            return; // Exit the component if profile is complete
        }

        const lastAlertDate = localStorage.getItem("notifyCompletion");
        const currentDate = new Date();
        const lastDate = new Date(lastAlertDate);

        // Check if it's been 24 hours since the last alert
        const timeDifference = currentDate - lastDate; // Difference in milliseconds
        const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

        // Show the alert only if 24 hours have passed or if it's the first time
        if (!lastAlertDate || timeDifference >= twentyFourHoursInMilliseconds) {
            setOpen(true);
            localStorage.setItem("notifyCompletion", currentDate.toISOString()); // Store the current date
        }
    }, [isProfileCompleted]);

    // Close slide when clicking outside the modal
    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProfileRouting = () => {
        Navigate("/profile")
    }


    return (
        <>
            {/* Dim background */}
            {open && (
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimming effect
                        zIndex: 999, // Ensure it is above other content
                    }}
                />
            )}

            {/* Slide in effect */}
            <Slide direction="up" ref={modalRef} in={open} mountOnEnter unmountOnExit>
                <Box sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'aliceblue',
                    boxShadow: 3,
                    p: { xs: 2, md: 4 },
                    height: { xs: "80%", md: "auto" },
                    overflow: "auto",
                    borderRadius: '8px 8px 0 0',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    zIndex: 1000, // Ensure it's above other content
                }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",  // Center the content
                            width: "100%",
                            flexDirection: "column",
                            gap: 2,  // Add space between elements
                            position: "relative",
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: "black",
                                cursor: "pointer",
                                position: "absolute",  // Position it absolutely
                                top: 10,  // Space from the top
                                right: 10,  // Space from the right
                            }}
                            onClick={handleClose}
                        />

                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: "center",
                                fontWeight: "600",
                                color: "#319fff",
                                fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                                flex: 1,
                                fontSize: {
                                    xs: "1.2rem",  // For small screens
                                    sm: "1.5rem",  // For medium screens
                                    md: "1.8rem",  // For large screens
                                },
                            }}
                        >
                            {isGreater768
                                ? "Unlock Exclusive Benefits by Completing Your Profile"
                                : "Complete your profile to access premium benefits"}
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: "center",
                                marginBottom: 2,
                                color: "#000",
                                fontSize: {
                                    xs: "0.85rem",  // Smaller font size for small screens
                                    sm: "1rem",     // Medium font size for medium screens
                                    md: "1.1rem",   // Larger font size for larger screens
                                },
                            }}
                        >
                            Completing your profile is the key to unlocking all the amazing benefits we have to offer. By adding just a few details, you’ll gain access to exclusive content, special offers, and personalized recommendations tailored to your interests.
                        </Typography>

                        <Button
                            variant="contained"
                            onClick={handleProfileRouting}
                            sx={{
                                backgroundColor: "#319fff",
                                color: "#fff",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                borderRadius: "25px",
                                textTransform: "none",
                                display: "block",
                                margin: "0 auto",
                                fontSize: {
                                    xs: "0.9rem",  // For small screens
                                    sm: "1rem",    // For medium screens
                                    md: "1.2rem",  // For larger screens
                                },
                            }}
                        >
                            Complete Profile
                        </Button>

                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: "center",
                                color: "#000",
                                fontSize: {
                                    xs: "0.85rem",  // For smaller screens
                                    sm: "1rem",     // For medium screens
                                    md: "1.1rem",   // For larger screens
                                },
                                marginBottom: 4,  // Increased margin for spacing below
                                lineHeight: 1.6,  // Improved line height for readability
                            }}
                        >
                            <Box sx={{ textAlign: "center", paddingLeft: "1rem", marginTop: 1 }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        textAlign: "center",
                                        color: "#000",
                                        fontSize: {
                                            xs: "0.85rem",  // For smaller screens
                                            sm: "1rem",     // For medium screens
                                            md: "1.1rem",   // For larger screens
                                        },
                                        marginBottom: 4,
                                        lineHeight: 1.6,
                                    }}
                                >
                                    <Box sx={{ marginBottom: 2 }}>
                                        <strong style={{ fontSize: "1.1rem", color: "#319fff" }}>
                                            Why complete your profile?
                                        </strong>
                                    </Box>
                                    <Box
                                        sx={{
                                            textAlign: "left",
                                            padding: "1rem",
                                            marginTop: 1,
                                            width: "100%",
                                        }}
                                    >
                                        <Stepper
                                            activeStep={0}
                                            orientation={isMobile ? "vertical" : "horizontal"} // Dynamically set orientation
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            {steps.map((step, index) => (
                                                <Step key={index}>
                                                    <StepLabel
                                                        StepIconComponent={() =>
                                                            step.status === "completed" ? (
                                                                <CheckCircle sx={{ color: "green" }} />
                                                            ) : (
                                                                <HelpOutline sx={{ color: "gray" }} />
                                                            )
                                                        }
                                                        sx={{
                                                            color: step.status === "completed" ? "#319fff" : "gray", // Completed step color
                                                            fontWeight: step.status === "completed" ? "bold" : "normal",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "flex-start",
                                                            marginBottom: isMobile ? "1rem" : "0", // Add spacing for mobile
                                                        }}
                                                    >
                                                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                            {step.label}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ fontSize: "0.9rem", color: "gray" }}>
                                                            {step.description}
                                                        </Typography>
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Box>
                                </Typography>
                            </Box>
                        </Typography>

                    </Box>

                </Box>
            </Slide>
        </>
    );
};

export default PremiumPurchaseAlert;
