// BlogData.js
export const nakshatraData = [
    {
        title: "Nakshatra Food",
        description: "In Hindu astrology, a person's birth star, also known as Nakshatra, plays a significant role in their life and is believed to influence various aspects, including food preferences.",
        image: "https://mail.google.com/mail/u/0/?ui=2&ik=9c573414e2&attid=0.2&permmsgid=msg-f:1819572115124177971&th=19406b32bd2ecc33&view=fimg&realattid=f_m56cbhye2&disp=thd&attbid=ANGjdJ_KcUYlSxPYZdb5OmNya5qILo_uuH4H7etpWNDUE26EaNhLZ_E6tl8EYvf89BOd8ZhejLHerknowUijFGfKa3jHvK_jVHx5mkQFlXferfCu7Zre87127RqDL6c&ats=2524608000000&sz=w1920-h877",
        link: "/nakshatra-foods"
    },
    {
        title: "Nakshatra Characteristics",
        description: "This is a general overview of the characteristics associated with each Janma Nakshatra or Birth Star in Vedic astrology.",
        image: "https://mail.google.com/mail/u/0/?ui=2&ik=9c573414e2&attid=0.4&permmsgid=msg-f:1819572115124177971&th=19406b32bd2ecc33&view=fimg&realattid=f_m56cbhyl3&disp=thd&attbid=ANGjdJ-E9kMNEcxolTQip5rTd8Fty9poO1NFqegojZNWkit6bhnl8mr2hJXSw8JdBcIlZMWZO0a_1hK-0MhJUPY2NIPdx8Pc_a_4_QZXTOt1C6l2FHa95DZAYsqgfTg&ats=2524608000000&sz=w1920-h877",
        link: "/nakshatra-characteristics"
    },
    {
        title: "Nakshatra Deity",
        description: "In Hindu way of life, Nakshatras are lunar mansions or constellations that play a significant role in determining one's personality, behaviour, and destiny.",
        image: "https://mail.google.com/mail/u/0/?ui=2&ik=9c573414e2&attid=0.3&permmsgid=msg-f:1819572115124177971&th=19406b32bd2ecc33&view=fimg&realattid=f_m56cbhy81&disp=thd&attbid=ANGjdJ8-L2NM7TjMaE3tHIY3i28HrvmuzMK_Rf_1xwcs8O1qrQVaK3rqVGl8Ff4phyyehSysBToSHNThPshpb3HAhiq9dJUjlcyw581Q9ZXD0_-mVZOYIcblOv62G30&ats=2524608000000&sz=w1920-h877",
        link: "/nakshatra-deities"
    },
    {
        title: "Nakshatra Likes and Dislikes",
        description: "This is a general overview of some tendencies and preferences associated with each Nakshatra.",
        image: "https://mail-attachment.googleusercontent.com/attachment/u/0/?ui=2&ik=9c573414e2&attid=0.1&permmsgid=msg-f:1819572115124177971&th=19406b32bd2ecc33&view=att&disp=inline&realattid=f_m56cb86a0&zw&saddbat=ANGjdJ_CBW8kCZtqn1gOQi3eq5u8fmOLD3TtUnBhscZdNkjxBjbSc0lqPBTfePEjQ0OLqhfAlJyOza61qom_dMBUUiJF3EPmnTL9xTgb4ZoUxrnitQ2nbBo8U63MxoAtJ7mn1VGm5Iww4YyvVFfh1pXihvbpkM3mQtSECKqhJ3jUSkUTz1Vc7K_kqTd9noo6Qoc7snceiUx6frquBFNatbUqdSVg2FX2Xteujr8r0iIYPL-MYaRJJc7zUC8J0AMDii6BU_Ky-kn6-qxNN1r114JzAuYk3YBAaLTyQOOvUpXTvKG3prQ86_DDaQm_Kf8cgNiePOCpYSqQ_aCiYYpc2gGrKL4uMD2HwyGpgPEArYoXz3ZgVd5YF6JCb786ZrJU-4BBMAxjabBsJ5tlIami6IFM08vMEKF79yoxuDLIG7NyKKZ8hECm1Wz5unyVBM-TXE7KF2zotg-eBsuUJOZ9jeS9imyZvEy49pWotRXCBhnC_acl34fGfl2HFyxMgXIKCPW2YJnMT1GT25aqR4GLMtn2LzyL-2go27tvEo-8--xx8icb_Dlt1xYTNKgLAmoMUg7J4R23XphxX3Hs_kE5Sl20U4nIcU9kSSV9YkrCCjDe53owUsqIMSIvpRR0leUyGD-gev3qm-5IVFY8r6MdxhIi7JWlv6BtwM0uT3_wSw542LGxJxP5Mh_HFBmodt5GqaK-ZUvQfuS64Q1dz8pVpGkU3SMWT4mCOT0tTZdI04JZbX_ON14yJlcbD-Ryl6Bl--uwqbctH5dUnNemtZ1J3kcVXL2KWnfiOEG4RS2E5II--VkjUXwNRalHKD12eWqtUkdQIF0tdL42r7ucK3RkOG22jTlQ_KKA3uYejoRzAMPkFfoIDhY_o8avmtcFyWWUojvOGv7_OE4I7OIAwQj2nfSrlLdSJk0NbqEzno9xcZ-sV-Z9Z8DRTWYE0Hj87qNdkSlpHRa36x1s0u9q4nT-tnPEcnzCDaO5tETUoANtD_nSnsBRQecm3sen_5etB6zKg-RbD_DHfriv4f28PtUq",
        link: "/nakshatra-likes-dislikes"
    }
];
