import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, TextField, InputAdornment, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SearchIcon from '@mui/icons-material/Search';// Assuming FooterComponent is your custom component
import ScrollToTop from 'react-scroll-to-top'; // Assuming ScrollToTop is your custom component
import { nakshatraData } from './utils/nakshatraData '; // Importing nakshatra data
import { tithiData } from './utils/tithiData'; // Importing tithi data
import PageSlider from './utils/PageSlider';
import FooterComponent from '../components/footer';
import SlidingHeader from '../components/SlidingHeader';
import { NavigateNext } from '@mui/icons-material';

const BlogScreen = () => {
    const [searchQuery, setSearchQuery] = useState('');

    // Merging the imported data from nakshatra and tithi
    const cardData = [...nakshatraData, ...tithiData];

    const filteredCards = cardData.filter(card =>
        card.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <Box sx={{ backgroundColor: 'aliceblue' }}>
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>Blogs</title>
                    <meta name="description" content="Blogs - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle={`Blogs`}
            />

            <Box display="flex" justifyContent="end" p={3}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#38a1f7' }} />
                            </InputAdornment>
                        ),
                    }}
                    label="Search" // Added label for better accessibility
                    variant="outlined"
                    placeholder='Search title...'
                    size="medium" // Updated to medium size
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{
                        width: "300px",
                        borderRadius: "6px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'primary', // Default border color
                            },
                            '&:hover fieldset': {
                                borderColor: '#007bff', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#007bff', // Border color when focused
                            },
                        },
                    }}
                />
            </Box>

            <Grid container spacing={4} sx={{ p: 3 }} justifyContent="center">
                {filteredCards.map((blog, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <a href={blog.link} style={{ textDecoration: 'none' }}>
                            <Card elevation={3} sx={{ height: '100%', borderRadius: "24px", bgcolor: "aliceblue" }}>
                                <CardMedia
                                    component="img"
                                    height="160"
                                    image={blog.image}
                                    alt={blog.title}
                                />
                                <CardContent >
                                    <Typography gutterBottom variant="h5">
                                        {blog.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {blog.description}
                                    </Typography>
                                </CardContent>
                                <Button endIcon={<NavigateNext />} size="medium" variant="contained" color="primary" sx={{ margin: 2, textTransform: "none" }}>
                                    Read more
                                </Button>
                            </Card>
                        </a>
                    </Grid>
                ))}
            </Grid>

            <FooterComponent />
        </Box>
    );
};

export default BlogScreen;
