import React from "react";
import { useEffect } from "react";
import FooterComponent from "../components/footer";
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import ScrollToTop from "react-scroll-to-top";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Container, Card, CardContent, Grid, Paper, Typography, List, ListItem, ListItemText } from "@mui/material";
import SlidingHeader from "../components/SlidingHeader";

const BoxPage3 = () => {

    useEffect(() => {
        AOS.refresh();
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (


        <HelmetProvider>
            <Helmet>
                <title>Nakshatra Deities</title>
                <meta name="description" content="Nakshatra Food - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <SlidingHeader />
            <Container maxWidth="lg">
                <ScrollToTop smooth />

                <Box
                    mt={15}
                    sx={{
                        width: '100%',
                        height: '200px',
                        backgroundImage: `url(${require("../images/boxesImages/deity1.png")})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '8px'
                    }}
                    onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                />


                <Grid container spacing={2} sx={{ mt: 2 }}>

                    <Grid item xs={12} md={8} >
                        <Box sx={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", }} >

                            <Typography variant="h4" gutterBottom>Nakshatra Deities</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                In Hindu way of life, Nakshatras are lunar mansions or constellations that play a significant role in determining one's personality, behaviour, and destiny. There are 27 Nakshatras, each associated with a ruling deity.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                The importance of Nakshatra deities lies in their spiritual and astrological significance. The ruling deity is believed to govern the qualities, characteristics, and influences of individuals born under that Nakshatra.
                            </Typography>

                            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Divine Connection:</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                Nakshatra deities are revered as celestial beings with distinct attributes and powers. Associating Nakshatras with these deities establishes a profound spiritual connection between individuals and these divine entities.
                            </Typography>

                            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Personality Traits:</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                The qualities and characteristics of the ruling deities are believed to shape the personality traits and behaviors of individuals born under a specific Nakshatra. For instance, individuals born under a nurturing and protective deity may exhibit caring and nurturing tendencies in their lives.
                            </Typography>


                            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Life Path and Guidance:</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                Nakshatra deities are associated with distinct life paths and purposes. Recognizing the attributes of the ruling deity can offer valuable guidance and direction to individuals as they navigate their life's journey.
                            </Typography>

                            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Astrological Interpretation:</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                Astrological readings consider the ruling deity of a Nakshatra to provide insights into an individual's potential strengths, challenges, and significant life experiences, enhancing the depth of astrological understanding.
                            </Typography>

                            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Rituals and Offerings:</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                Devotees may engage in rituals, prayers, and offerings to honor the ruling deity of their Nakshatra. These practices are a way to seek blessings, protection, and guidance, fostering a deeper connection with the divine in various aspects of life.
                            </Typography>

                            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Cultural and Spiritual Significance:</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                Nakshatra deities hold immense cultural and spiritual importance within Hindu traditions. Regarded as celestial beings embodying divine attributes, they are integral to Vedic practices and astrology, enriching the spiritual fabric of life.
                            </Typography>

                            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Connection with Cosmic Forces:</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                Nakshatra deities are believed to encompass specific cosmic forces and energies that influence human existence. Acknowledging and honoring these deities aids individuals in aligning themselves with the broader cosmic order and its intricate workings.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>
                                While the significance of Nakshatra deities is subjective and may vary based on individual viewpoints and cultural backgrounds, the decision to connect with and honor these divine entities remains deeply personal.
                            </Typography>


                            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>Here are the Nakshatras and their presiding deities:
                                Certainly, here is a list of the 27 Nakshatras along with their presiding deities:</Typography>

                            {[
                                {
                                    name: "Ashwini (Asvini)",
                                    description: "The Horse Twins",
                                    presidingDeity: "Ashvins (Nasatya and Dasra), divine twin horsemen associated with health and medicine."
                                },
                                {
                                    name: "Bharani",
                                    description: "The Bearing Star",
                                    presidingDeity: "Yama, the god of death and justice."
                                },
                                {
                                    name: "Krittika",
                                    description: "The Cutter",
                                    presidingDeity: "Agni, the god of fire."
                                },
                                {
                                    name: "Rohini",
                                    description: "The Reddish One",
                                    presidingDeity: "Brahma, the creator god."
                                },
                                {
                                    name: "Mrigashira (Mrigasira)",
                                    description: "The Deer's Head",
                                    presidingDeity: "Chandra, the moon god."
                                },
                                {
                                    name: "Ardra",
                                    description: "The Moist One",
                                    presidingDeity: "Rudra (Shiva), the god of destruction and transformation."
                                },
                                {
                                    name: "Punarvasu",
                                    description: "The Return of the Light",
                                    presidingDeity: "Aditi, the mother of all gods."
                                },
                                {
                                    name: "Pushya",
                                    description: "The Nourisher",
                                    presidingDeity: "Brihaspati, the teacher of the gods."
                                },
                                {
                                    name: "Ashlesha",
                                    description: "The Embrace",
                                    presidingDeity: "Naga, the serpent deity."
                                },
                                {
                                    name: "Magha",
                                    description: "The Mighty One",
                                    presidingDeity: "Pitris, the ancestral spirits."
                                },
                                {
                                    name: "Purva Phalguni",
                                    description: "The Former Reddish One",
                                    presidingDeity: "Bhaga, the god of marital bliss and fortune."
                                },
                                {
                                    name: "Uttara Phalguni",
                                    description: "The Latter Reddish One",
                                    presidingDeity: "Aryaman, the god of contracts and unions."
                                },
                                {
                                    name: "Hasta",
                                    description: "The Hand",
                                    presidingDeity: "Savitar, the god of creativity and craftsmanship."
                                },
                                {
                                    name: "Chitra",
                                    description: "The Bright One",
                                    presidingDeity: "Twashtar, the celestial architect."
                                },
                                {
                                    name: "Swati",
                                    description: "The Independent One",
                                    presidingDeity: "Vayu, the wind god."
                                },
                                {
                                    name: "Vishakha",
                                    description: "The Forked Branch",
                                    presidingDeity: "Indra and Agni, representing strength and transformation."
                                },
                                {
                                    name: "Anuradha",
                                    description: "The Disciple of the Divine Spark",
                                    presidingDeity: "Mitra, the god of friendship and partnership."
                                },
                                {
                                    name: "Jyeshta (Jyeshtha)",
                                    description: "The Eldest",
                                    presidingDeity: "Indra, the king of gods."
                                },
                                {
                                    name: "Mula",
                                    description: "The Root",
                                    presidingDeity: "Nirriti, the goddess of destruction and chaos."
                                },
                                {
                                    name: "Purva Ashadha",
                                    description: "The Former Invincible One",
                                    presidingDeity: "Apas, the water deity."
                                },
                                {
                                    name: "Uttara Ashadha",
                                    description: "The Latter Invincible One",
                                    presidingDeity: "Vishvadevas, the universal gods."
                                },
                                {
                                    name: "Shravana",
                                    description: "The Hearing",
                                    presidingDeity: "Vishnu, the preserver god."
                                },
                                {
                                    name: "Dhanishta (Shatabhisha)",
                                    description: "The Drummer",
                                    presidingDeity: "Vasus, the elemental deities."
                                },
                                {
                                    name: "Shatabhisha (Dhanishta)",
                                    description: "The Hundred Stars",
                                    presidingDeity: "Varuna, the god of cosmic order and water."
                                },
                                {
                                    name: "Purva Bhadrapada",
                                    description: "The Former Beautiful Foot",
                                    presidingDeity: "Aja Ekapada, the one-footed goat, symbolizing divine sacrifice."
                                },
                                {
                                    name: "Uttara Bhadrapada",
                                    description: "The Latter Beautiful Foot",
                                    presidingDeity: "Ahirbudhnya, the serpent or dragon of the deep."
                                },
                                {
                                    name: "Revati",
                                    description: "The Wealthy",
                                    presidingDeity: "Pushan, the god of nourishment and journeys."
                                }
                            ].map(({ name, description, presidingDeity }) => (
                                <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, backgroundColor: '#f5f5f5', mb: 2 }}>
                                    <Box>
                                        <Typography variant="h6" color="primary">
                                            {name} - <Typography variant="h6" color="primary" component="span">{description}:</Typography>
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                                            {presidingDeity}
                                        </Typography>
                                    </Box>
                                </Paper>
                            ))}
                        </Box>

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                margin: '20px 0',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                transition: '0.3s',
                                '&:hover': {
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                },

                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Related Searches
                                </Typography>
                                <List>
                                    {[
                                        { path: '/nakshatra-foods', label: 'Nakshatra Foods' },
                                        { path: '/nakshatra-characteristics', label: 'Nakshatra Characteristics' },
                                        { path: '/nakshatra-deities', label: 'Nakshatra Deities' },
                                        { path: '/nakshatra-likes-dislikes', label: 'Nakshatra Likes and Dislikes' },
                                        { path: '/tithi-foods', label: 'Tithi Foods' },
                                        { path: '/tithi-characteristics', label: 'Tithi Characteristics' },
                                        { path: '/tithi-deities', label: 'Tithi Deities' },
                                        { path: '/tithi-likes-dislikes', label: 'Tithi Likes and Dislikes' },
                                    ].map((item) => (
                                        <ListItem
                                            key={item.path}
                                            component={NavLink} // Make the ListItem act like a NavLink
                                            to={item.path} // Set the path for navigation
                                            sx={{
                                                padding: '10px 0',
                                                textDecoration: 'none', // Removes the underline from NavLink
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                    cursor: 'pointer', // Changes the cursor on hover
                                                },
                                            }}
                                        >
                                            <ListItemText sx={{}} primary={item.label} />
                                        </ListItem>
                                    ))}
                                </List>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Container>
            <FooterComponent />
        </HelmetProvider>
    )
}
export default BoxPage3;