import React, { useState } from "react";
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Box,
    Typography,
    Container,
} from "@mui/material";
import { useParams, } from "react-router-dom";
import SlidingHeader from "../../components/SlidingHeader";
import PageSlider from "./PageSlider";

const getFreesteps = (stepIndex) => {
    return require(`../../images/tutorial/Free/step_${stepIndex}.webp`);
};

const getPremiumsteps = (stepIndex) => {
    return require(`../../images/tutorial/premium/step_${stepIndex}.webp`);
};

const stepsData = {
    "Free_Sign_Up": [
        {
            label: "Click Sign Up",
            description: "Begin by clicking the 'Sign Up' or 'Premium' button to start your registration process.",
            image: getFreesteps(1), // Dynamic image handling
        },
        {
            label: "Select Free Sign Up",
            description: "Choose the 'Free Sign Up' option to create your account.",
            image: getFreesteps(2),
        },
        {
            label: "Complete Details",
            description: "Fill out the necessary information, such as your name, email, and other personal details.",
            image: getFreesteps(3),
        },
        {
            label: "Account Verification",
            description: "Verify your email address or phone number to confirm your identity and complete the registration.",
            image: getFreesteps(4),
        },
        {
            label: "Thank You and Redirection",
            description: "Once verified, you'll be redirected to a 'Thank You' page as a confirmation of your successful registration.",
            image: getFreesteps(5),
        },
        {
            label: "Access Dashboard",
            description: "Log in to your account to access the dashboard, where you can manage your profile.",
            image: getFreesteps(6),
        },
        {
            label: "Complete Your Profile",
            description: "Enter your birth details to know your JanmaDinam.",
            image: getFreesteps(7),
        },
        {
            label: "View Your JanmaDinam on Dashboard",
            description: "Once you've completed your profile, your JanmaDinam will be displayed on the dashboard for easy access.",
            image: getFreesteps(8),
        },
    ],
    "Premium_Sign_Up": [
        {
            label: "Click Premium",
            description: "Begin by selecting the 'Premium / Sign Up' option to access exclusive features and benefits.",
            image: getPremiumsteps(1), // Dynamic image handling
        },
        {
            label: "Select Premium Sign Up",
            description: "Choose the 'Premium Sign Up' option to get started with the premium account registration.",
            image: getPremiumsteps(2),
        },
        {
            label: "Complete Details",
            description: "Provide your personal details and ensure all required fields are filled to complete your premium registration.",
            image: getPremiumsteps(3),
        },
        {
            label: "Account Verification and Payment",
            description: "Verify your account and complete the payment process for premium membership.",
            image: getPremiumsteps(4),
        },
        {
            label: "Thank You and Redirection",
            description: "Once payment is processed, you will be redirected to a confirmation page as a thank you for joining premium.",
            image: getPremiumsteps(5),
        },
        {
            label: "Access Dashboard",
            description: "Log in to your newly created premium account and access your personalized dashboard.",
            image: getPremiumsteps(6),
        },
        {
            label: "Complete Your Profile",
            description: "Fill out your profile with your birth details to know your JanmaDinam.",
            image: getPremiumsteps(7),
        },
        {
            label: "View Your JanmaDinam on Dashboard",
            description: "After profile completion, you will be able to see your JanmaDinam on the dashboard, providing a personalized touch.",
            image: getPremiumsteps(8),
        },
    ],


    "Shubadinam Shop": [
        {
            label: "Browse",
            description: "Browse our exclusive products.",
        },
        {
            label: "Purchase",
            description: "Make a purchase with ease.",
        },
    ],
    "Virtual Seva": [
        {
            label: "Overview",
            description: "Learn about Virtual Seva.",
        },
        {
            label: "Participate",
            description: "Join a Virtual Seva.",
        },
    ],
    "Sign Up": [
        {
            label: "Create Account",
            description: "Sign up for an account.",
        },
        {
            label: "Get Started",
            description: "Start exploring today.",
        },
    ],
    "Wish Wall": [
        {
            label: "Write Wish",
            description: "Write your heartfelt wishes.",
        },
        {
            label: "Share",
            description: "Share wishes with others.",
        },
    ],
};



const TutorialSteps = () => {
    const { tutorialName } = useParams();
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => setActiveStep((prev) => prev + 1);
    const handleBack = () => setActiveStep((prev) => prev - 1);

    return (
        <>
            <SlidingHeader />
            {/* Hero Section */}
            <PageSlider
                bgImage={require("../../images/banners/all_hero_banner.jpg")}
                pageTitle={`${tutorialName} Tutorial`}
            />
            <Container maxWidth="md" >
                <Typography variant="h4" sx={{ marginTop: 3 }}>

                </Typography>

                <Stepper activeStep={activeStep} orientation="vertical" sx={{ marginTop: 3 }}>
                    {stepsData[tutorialName]?.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>{step.label}</StepLabel>
                            <StepContent>
                                <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                                    <img
                                        src={step.image}
                                        alt={step.label}
                                        style={{
                                            width: "100%",
                                            maxWidth: { xs: "300px", md: "500px" },
                                            height: { xs: "auto", md: "500px" },
                                            objectFit: "cover",
                                            marginBottom: "16px",
                                            borderRadius: "8px",
                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                        }}
                                    />
                                    <Typography variant="body1" textAlign="center" gutterBottom>
                                        {step.description}
                                    </Typography>
                                </Box>
                                <Box mt={2} display="flex" justifyContent="space-between">
                                    {index > 0 && (
                                        <Button
                                            variant="contained"
                                            onClick={handleBack}
                                            color="primary"
                                            sx={{
                                                textTransform: "none"
                                            }}
                                        >
                                            Previous
                                        </Button>
                                    )}
                                    {index < stepsData[tutorialName].length - 1 ? (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleNext}
                                            color="primary"
                                            sx={{
                                                textTransform: "none"
                                            }}
                                        >
                                            Next
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => alert("Tutorial Completed!")}
                                        >
                                            Finish
                                        </Button>
                                    )}
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Container>

        </>
    );
};

export default TutorialSteps;
