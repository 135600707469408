import React, { useState, useEffect } from 'react';
import { Card, TextField, Button, Grid, Typography, Box, IconButton, Divider, Slider, Container } from '@mui/material';
import SlidingHeader from '../../components/SlidingHeader';
import { ArrowForward } from '@mui/icons-material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; // Import the Splide CSS for styling
import FooterComponent from '../../components/footer';


const templates = [
    {
        id: 1,
        backgroundImage: 'url("https://img.freepik.com/premium-photo/birthday-patterns-images-background-copy-space_1179130-771164.jpg?w=900")',
    },
    {
        id: 2,
        backgroundImage: 'url("https://img.freepik.com/premium-photo/festive-gift-boxes-with-red-ribbons-pine-cones_639785-139555.jpg?w=1060")',
    },
    {
        id: 3,
        backgroundImage: 'url("https://img.freepik.com/premium-photo/brand-loyalty-concept-3d-illustration-customer-retention-tactics-loyalty-programs-copy-space_912524-33517.jpg?w=1060")',
    },
    {
        id: 4,
        backgroundImage: 'url("https://img.freepik.com/premium-photo/modern-background-with-copy-space-text-logo-photo-backdrop-product-display-presentation-website-web_941600-207515.jpg?w=900")',
    },
    // Add more templates here for testing (e.g., 10 or more)
];

const RoughOne = () => {

    const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);
    const [name, setName] = useState();
    const [wish, setWish] = useState();

    const [showButton, setShowButton] = useState(false); // State to control the button visibility

    useEffect(() => {
        // Check if the user has already clicked the "Get Started" button before
        const hasVisited = localStorage.getItem('card_page');
        if (hasVisited) {
            setShowButton(true); // Hide the button if it's already clicked
        }
    }, []);

    const handleGetStartedClick = () => {
        // Set the flag in localStorage to remember the user's action
        localStorage.setItem('card_page', 'true');
        setShowButton(true); // Hide the button
    };


    const handleTemplateChange = (template) => {
        console.log(template);
        setSelectedTemplate(template);
        setName(name);
        setWish(wish);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log({ name, wish });
    };

    return (

        <Box>
            <SlidingHeader />

            {
                !showButton ?

                    <Box sx={{ height: { xs: "70vh", md: "100vh" } }}>

                        {/* Sliders Section */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                        >
                            {/* Sliders Section */}
                            {!showButton && (
                                <Container
                                    sx={{
                                        position: 'absolute',
                                        top: { xs: "100px", sm: '20%' },
                                        zIndex: 1,
                                        textAlign: 'center',
                                        maxWidth: '100%',
                                        padding: { xs: 2, sm: 4 }, // Add responsive padding
                                        width: { xs: '100%', sm: '80%' }, // Adjust width based on screen size
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#fff',
                                            mb: 2,
                                            fontSize: { xs: '1.5rem', sm: '2rem' }, // Adjust font size for smaller screens
                                        }}
                                    >
                                        Customize Your Wishes
                                    </Typography>

                                    {/* Splide Slider */}
                                    <Splide options={{ type: 'loop', perPage: 1, arrows: true, pagination: true, autoplay: true, pauseOnHover: true }}>
                                        <SplideSlide>
                                            <Box sx={{ textAlign: 'center', color: '#fff' }}>
                                                <img
                                                    src="https://img.freepik.com/free-vector/welcome-landing-page-template-with-landscape_52683-18384.jpg?t=st=1734336393~exp=1734339993~hmac=96e2ecb5383cd490b56c0e8b06b0c6e722af677ac69d287df726c3401c1b07ac&w=900"
                                                    alt="Slider 1"
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        borderRadius: '8px',
                                                        objectFit: 'cover', // Ensures the image covers the container
                                                        maxHeight: '400px', // Limit height for better visuals
                                                    }}
                                                />
                                                <Typography variant="h6" sx={{ mt: 2 }}>
                                                    Slider 1
                                                </Typography>
                                                <Typography variant="body1">Some content here...</Typography>
                                            </Box>
                                        </SplideSlide>
                                        <SplideSlide>
                                            <Box sx={{ textAlign: 'center', color: '#fff' }}>
                                                <img
                                                    src="https://img.freepik.com/free-vector/colorful-landing-page-with-gradient-landscape_52683-21600.jpg?t=st=1734336420~exp=1734340020~hmac=bec1dc7877f1ba9ed3d7e40a1bffb6cb451472b9f87d385415150216da81fa34&w=900"
                                                    alt="Slider 2"
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        borderRadius: '8px',
                                                        objectFit: 'cover',
                                                        maxHeight: '400px',
                                                    }}
                                                />
                                                <Typography variant="h6" sx={{ mt: 2 }}>
                                                    Slider 2
                                                </Typography>
                                                <Typography variant="body1">Some content here...</Typography>
                                            </Box>
                                        </SplideSlide>
                                    </Splide>

                                    {/* Get Started Button */}
                                    <Button
                                        variant="contained"
                                        sx={{
                                            position: 'absolute',
                                            bottom: '20px',
                                            right: "5%",
                                            padding: '12px 30px',
                                            borderRadius: '25px',
                                            backgroundColor: '#1976d2',
                                            color: '#fff',
                                            fontSize: { xs: '1rem', sm: '1.2rem' }, // Adjust font size for mobile
                                            '&:hover': {
                                                backgroundColor: '#1565c0',
                                            },
                                        }}
                                        onClick={handleGetStartedClick}
                                    >
                                        Get Started
                                    </Button>
                                </Container>
                            )}
                        </Box>


                    </Box>
                    :
                    <Box sx={{ display: 'flex', gap: 3, padding: 3, mt: 10, flexDirection: { xs: 'column', sm: 'row' }, bgcolor: "#f0f8ff70" }}>
                        {/* Left Sidebar for Template Selection */}
                        <Box
                            sx={{
                                width: { xs: '100%', sm: 240 },
                                display: 'flex',
                                flexDirection: { xs: 'row', sm: 'column' }, // Row for mobile, column for desktop
                                gap: 2,
                                maxHeight: '500px', // Limit height to prevent overflow
                                overflowX: { xs: 'auto', sm: 'hidden' }, // Horizontal scroll for mobile, hidden for desktop
                                overflowY: { xs: 'hidden', sm: 'auto' },  // Vertical scroll for desktop, hidden for mobile
                                paddingRight: 2,

                            }}
                        >
                            {/* Select Template with arrow icon */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="h6" sx={{ fontWeight: '500' }}>Select Template</Typography>
                                <IconButton size='small' sx={{ bgcolor: "#dddd" }} >
                                    <ArrowForward />
                                </IconButton>
                            </Box>

                            {/* Stack templates horizontally for mobile, vertically for desktop */}
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, gap: 2 }}>
                                {templates.map((template) => (
                                    <Card
                                        key={template.id}
                                        onClick={() => handleTemplateChange(template)}
                                        sx={{
                                            cursor: 'pointer',
                                            // padding: 2,
                                            textAlign: 'center',
                                            borderRadius: 2,
                                            boxShadow: 2,
                                            backgroundImage: template.backgroundImage,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: 180,
                                            border: selectedTemplate.id === template.id ? '3px solid #79a4f2' : 'none', // Apply blue border for selected template
                                            width: { xs: 180, sm: '100%' }, // Ensure the cards have fixed width on mobile
                                            '&:hover': {
                                                backgroundColor: '#e0e0e0',
                                                boxShadow: 4,
                                            },
                                        }}
                                    >
                                        {
                                            selectedTemplate.id === template.id ?

                                                <Box sx={{ position: 'relative', backdropFilter: 'blur(1px)', width: '100%', height: '100%', textAlign: 'center', top: '0%' }}>
                                                    {/* Dim background overlay */}
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Black with 40% opacity
                                                            zIndex: 0, // Make sure the overlay stays behind the text
                                                            borderRadius: 2, // Optional: add border radius if you want rounded corners
                                                        }}
                                                    />

                                                    {/* Text content */}
                                                    <Typography variant='subtitle1' sx={{ fontWeight: '500', zIndex: 1, position: 'relative', color: "white", top: "45%" }}>
                                                        Selected
                                                    </Typography>
                                                </Box>

                                                :
                                                <></>
                                        }
                                    </Card>
                                ))}
                            </Box>
                        </Box>

                        <Divider sx={{ display: { xs: "block", sm: "none" }, width: "100%", height: "1px", bgcolor: "#cccc" }} />

                        {/* Right Section for Input Fields and Preview */}
                        <Box sx={{ flex: 1, padding: { xs: 0, md: 4 } }}>
                            {/* Heading */}
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{
                                    fontWeight: '500',
                                    color: '#333',
                                    fontSize: { xs: '1.25rem', md: '1.5rem' },
                                    textAlign: 'start', // Centering on larger screens
                                }}
                            >
                                Customize Your Card
                            </Typography>

                            {/* Form Section */}
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3} mt={{ xs: 1, md: 2 }}>
                                    {/* Name Field */}
                                    <Grid item xs={12} xl={6}>
                                        <TextField
                                            label="Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            fullWidth
                                            variant="outlined"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 3,
                                                },
                                                backgroundColor: '#fff',
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                    borderRadius: 3,
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* Wish Field */}
                                    <Grid item xs={12} xl={6}>
                                        <TextField
                                            label="Wish"
                                            value={wish}
                                            onChange={(e) => setWish(e.target.value)}
                                            fullWidth
                                            multiline
                                            rows={5}
                                            variant="outlined"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 3,
                                                },
                                                backgroundColor: '#fff',
                                                '& .MuiInputLabel-root': {
                                                    color: '#333',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* Submit Button */}
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{
                                                borderRadius: 3,
                                                padding: '14px',
                                                fontSize: '1.1rem',
                                                textTransform: 'none',
                                                fontWeight: '600',
                                                '&:hover': {
                                                    backgroundColor: '#1976d2',
                                                    boxShadow: 4,
                                                },
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>

                            {/* Spacer */}
                            <Box sx={{ marginTop: 4 }} />

                            {/* Preview Section */}
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{
                                    fontWeight: '500',
                                    color: '#333',
                                    fontSize: { xs: '1.25rem', md: '1.5rem' },
                                    textAlign: 'start', // Center the preview heading
                                }}
                            >
                                Preview
                            </Typography>

                            {/* Card Preview */}
                            <Card
                                sx={{
                                    backgroundImage: selectedTemplate.backgroundImage,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: { xs: 300, md: 400 }, // Adjust height for larger screens
                                    position: 'relative',
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    color: '#fff',
                                    borderRadius: 3,
                                    boxShadow: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {/* Dim Overlay */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Slightly stronger dimming effect for larger screens
                                        borderRadius: 3,
                                        zIndex: 0,
                                    }}
                                />

                                {/* Text Content */}
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        zIndex: 1,
                                        position: 'relative',
                                        padding: { xs: '20px', md: '70px' },
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 'bold',
                                            fontFamily: 'serif',
                                            fontSize: { xs: '1.5rem', md: '2rem' },
                                            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.6)', // Stronger text shadow for better contrast
                                        }}
                                    >
                                        {name}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontSize: { xs: '1rem', md: '1.25rem' },
                                            fontStyle: 'italic',
                                            textShadow: '1px 1px 6px rgba(0, 0, 0, 0.5)', // Added subtle shadow to the wish
                                        }}
                                    >
                                        {wish}
                                    </Typography>
                                </Box>
                            </Card>
                        </Box>

                    </Box>
            }
            <FooterComponent />
        </Box >
    );
};

export default RoughOne;
