import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Typography, Box, Grid, Paper } from "@mui/material";
import ScrollToTop from "react-scroll-to-top";
import FooterComponent from "../components/footer";
import LandingHeader from "../components/LandingHeader";
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";

const RefundPolicyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Refund Policy</title>
                    <meta name="description" content="Refund Policy - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>

            {/* Landing Header */}
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle="Refund Policy"
            />
            <ScrollToTop smooth />


            {/* Main Content */}
            <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4, textAlign: "center" }}>

                <Typography variant="body1" color="textSecondary" paragraph>
                    Thank you for shopping with Shubadinam.
                </Typography>

                <Typography variant="body1" paragraph>
                    As of now, we do not accept any returns or process refunds.
                </Typography>

                <Typography variant="body1" paragraph>
                    Additionally, we do not offer any cancellation services.
                </Typography>
            </Container>

            {/* Footer */}
            <FooterComponent />
        </div>
    );
};

export default RefundPolicyPage;
