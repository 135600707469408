import React, { useState, useEffect } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Box,
    Avatar,
    Collapse,
    Menu,
    MenuItem,
    Divider,
    useMediaQuery,
    Slide,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import HistoryIcon from "@mui/icons-material/History";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Logout from "@mui/icons-material/Logout";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { PersonRounded } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

const SlidingHeader = () => {

    const location = useLocation();

    const [path, setPath] = useState('');
    const [isMobileOpen, setMobileOpen] = useState(false);
    const [dashsignOut, setSignOut] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showHeader, setShowHeader] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isScrolledUp, setIsScrolledUp] = useState(false); // Track scroll direction for background
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);


    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg")); // Mobile view breakpoint

    useEffect(() => {
        const updatedPath = location.pathname.slice(1);
        setPath(updatedPath);
        const auth = localStorage.getItem("isAuthenticated") || localStorage.getItem("homeauth");
        if (auth) {
            setSignOut(true);
        }
    }, []);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY && currentScrollY > 0) {
            setShowHeader(false); // Hide header on scroll down
        } else {
            setIsScrolledUp(currentScrollY > 10); // Add background when scrolling up
            setShowHeader(true); // Show header on scroll up
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    const toggleDrawer = () => {
        setMobileOpen(!isMobileOpen);
    };

    const handleMenuClick = (page) => {
        navigate(`/${page}`);
        setMobileOpen(false);
    };


    const handleToggle = () => {
        setOpen(!open);
    };

    const handleToggle2 = () => {
        setOpen2(!open2);
    };

    const signOut = () => {
        sessionStorage.clear();
        localStorage.clear();
        setSignOut(false);
        navigate("/");
        window.location.reload();
    };

    const renderMenu = () => (


        <List
            sx={{
                display: isMobile ? "block" : "flex", // Stack items vertically for mobile, horizontally for larger screens
                width: isMobile ? "250px" : "auto",  // Fixed width for mobile, auto for larger screens
                padding: isMobile ? "10px" : "0",    // Padding for mobile view
                backgroundColor: isMobile ? "background.paper" : "transparent", // Background color for mobile view
            }}
        >
            {
                isMobile && (
                    <img
                        onClick={() => handleMenuClick("")}
                        src={require("../images/New Shubadinam Logo/Shubadinam Blue.png")}
                        style={{ height: "50px", width: "150px", objectFit: "contain", marginBottom: "10px" }}
                        alt="Shubadinam Logo"
                    />
                )
            }
            {/* Home Button */}
            <ListItem button onClick={() => handleMenuClick(dashsignOut ? "Dashboard" : "")} sx={{ width: "fit-content", padding: isMobile ? "10px" : "auto" }}>
                {dashsignOut ? "Dashboard" : "Home"}
            </ListItem>

            {/* Shubadinam TV Button */}
            <ListItem button onClick={() => handleMenuClick("shubadinam-tv")} sx={{ width: "fit-content", padding: isMobile ? "10px" : "auto" }}>
                Shubadinam TV<YouTubeIcon color="error" sx={{ ml: 1 }} />
            </ListItem>

            {/* Premium Button */}
            <ListItem button onClick={() => handleMenuClick("pricing")} sx={{ width: "fit-content", padding: isMobile ? "10px" : "auto" }}>
                Premium
            </ListItem>

            {/* Virtual Puja Button */}
            <ListItem button onClick={() => handleMenuClick("virtual-puja-seva")} sx={{ width: "fit-content", padding: isMobile ? "10px" : "auto" }}>
                Virtual Puja
            </ListItem>

            {/* Gift Shoppe Link */}
            <ListItem
                button
                component="a"
                href="https://shop.shubadinam.com/"
                target="_blank"
                // rel="noopener noreferrer"
                sx={{ width: "fit-content", padding: isMobile ? "10px" : "auto", }}
            >
                Gift Shoppe
            </ListItem>

            {
                isMobile ? (
                    <>
                        {/* Gallery */}
                        <ListItem button onClick={() => handleMenuClick("gallery")} sx={{ width: "fit-content", padding: isMobile ? "10px" : "auto" }}>
                            Gallery
                        </ListItem>

                        {/* Toggle button to show or hide the dropdown */}
                        <ListItem button onClick={handleToggle} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto" }}>
                            Info
                            <IconButton size="small" sx={{ bgcolor: "#fff", position: "absolute", right: "10%" }}>
                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </ListItem>

                        {/* Collapse component for the dropdown */}
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List disablePadding sx={{ bgcolor: "#eeee", }} >
                                <ListItem button onClick={() => handleMenuClick("about-us")} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto", borderBottom: "1px solid #dddd", width: "100%", color: "GrayText" }}>
                                    <ListItemText primary="About Us" />
                                </ListItem>
                                <ListItem button onClick={() => handleMenuClick("faq")} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto", borderBottom: "1px solid #dddd", width: "100%", color: "GrayText" }}>
                                    <ListItemText primary="FAQs" />
                                </ListItem>
                                <ListItem button onClick={() => handleMenuClick("Blogs")} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto", borderBottom: "1px solid #dddd", width: "100%", color: "GrayText" }}>
                                    <ListItemText primary="Blogs" />
                                </ListItem>
                                <ListItem button onClick={() => handleMenuClick("why-us")} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto", borderBottom: "1px solid #dddd", width: "100%", color: "GrayText" }}>
                                    <ListItemText primary="Why Us" />
                                </ListItem>
                                <ListItem button onClick={() => handleMenuClick("contact-us")} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto", borderBottom: "1px solid #dddd", width: "100%", color: "GrayText" }}>
                                    <ListItemText primary="Contact Us" />
                                </ListItem>
                            </List>
                        </Collapse>

                        {/* Toggle button to show or hide the dropdown */}
                        <ListItem button onClick={handleToggle2} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto" }}>
                            Tutorial
                            <IconButton size="small" sx={{ bgcolor: "#fff", position: "absolute", right: "10%" }}>
                                {open2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </ListItem>

                        {/* Collapse component for the dropdown */}
                        <Collapse in={open2} timeout="auto" unmountOnExit>
                            <List disablePadding sx={{ bgcolor: "#eeee", }} >
                                <ListItem button onClick={() => handleMenuClick("tutorial")} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto", borderBottom: "1px solid #dddd", width: "100%", color: "GrayText", }}>
                                    <ListItemText primary="Video" />
                                </ListItem>
                                <ListItem button onClick={() => handleMenuClick("tutorial")} sx={{ width: isMobile ? "100%" : "fit-content", padding: isMobile ? "10px" : "auto", borderBottom: "1px solid #dddd", width: "100%", color: "GrayText" }}>
                                    <ListItemText primary="Slides" />
                                </ListItem>
                            </List>
                        </Collapse>
                    </>
                )
                    :
                    (
                        <>

                            <ListItem button sx={{ width: "fit-content", }} id="unique-dropdown">
                                <span className={`${isScrolledUp || path === "pricing" ? "info-white" : ""}`}>Info <ArrowDropDown /> </span>
                                <div id="unique-dropdown-content">
                                    <NavLink to="/about-us">About Us</NavLink>
                                    <NavLink to="/gallery">Gallery</NavLink>
                                    <NavLink to="/faq">FAQs</NavLink>
                                    <NavLink to="/Blogs">Blogs</NavLink>
                                    <NavLink to="/why-us">Why Us</NavLink>
                                    <NavLink to="/contact-us">Contact Us</NavLink>
                                </div>
                            </ListItem>
                            <ListItem button sx={{ width: "fit-content", }} id="unique-dropdown">
                                <span className={`${isScrolledUp || path === "pricing" ? "info-white" : ""}`}>Tutorial <ArrowDropDown /></span>
                                <div id="unique-dropdown-content">
                                    <NavLink to="/tutorial">Video</NavLink>
                                    <NavLink to="/tutorial">Slides</NavLink>
                                </div>
                            </ListItem>
                        </>
                    )
            }





            {/* Logged-in User Links */}

            {
                dashsignOut ? (

                    isMobile ? (
                        <List>
                            <ListItem
                                button
                                onClick={() => handleMenuClick("homam-bookings")}
                                sx={{
                                    width: isMobile ? "100%" : "fit-content",
                                    padding: isMobile ? "10px" : "auto",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <Typography variant="body1">My Bookings</Typography>
                                <ListItemIcon>
                                    <EventAvailableRoundedIcon sx={{ ml: 1 }} />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem
                                button
                                onClick={() => handleMenuClick("homam-bookings-history")}
                                sx={{
                                    width: isMobile ? "100%" : "fit-content",
                                    padding: isMobile ? "10px" : "auto",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <Typography variant="body1">Payment History</Typography>
                                <ListItemIcon>
                                    <HistoryIcon sx={{ ml: 1 }} />
                                </ListItemIcon>
                            </ListItem>

                            <ListItem
                                button
                                onClick={() => signOut()}
                                sx={{
                                    width: isMobile ? "100%" : "fit-content",
                                    padding: isMobile ? "10px" : "auto",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <Typography variant="body1">Log Out</Typography>
                                <ListItemIcon>
                                    <Logout sx={{ ml: 1 }} />
                                </ListItemIcon>
                            </ListItem>
                        </List>

                    ) : (
                        <ListItem button sx={{ width: "fit-content", padding: isMobile ? "10px" : "auto" }} id="unique-dropdown">
                            <IconButton size="small" sx={{ p: 0, backgroundColor: "white !important" }}>

                                <PersonRounded sx={{ color: "darkblue !important", borderRadius: 50, }} />

                            </IconButton>
                            <div id="unique-dropdown-content">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        backgroundColor: '#f9f9f9',
                                        borderRadius: 2,
                                        width: '100%',
                                    }}
                                >
                                    <ListItem
                                        button
                                        onClick={() => handleMenuClick("homam-bookings")}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: isMobile ? '8px' : '12px',
                                            width: '100%',
                                            borderRadius: 1,
                                            color: 'rgb(126, 126, 126)',
                                            transition: "transform 0.2s ease",
                                            '&:hover': {
                                                backgroundColor: 'aliceblue',
                                                transform: "scale(0.95)",
                                                color: "black"
                                            },
                                        }}
                                    >
                                        {/* <ListItemIcon sx={{ minWidth: '40px' }}>
                                            <EventAvailableRoundedIcon sx={{ color: 'rgb(126, 126, 126)' }} />
                                        </ListItemIcon> */}
                                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                            My Bookings
                                        </Typography>
                                    </ListItem>

                                    <ListItem
                                        button
                                        onClick={() => handleMenuClick("homam-bookings-history")}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: isMobile ? '8px' : '12px',
                                            width: '100%',
                                            borderRadius: 1,
                                            color: 'rgb(126, 126, 126)',
                                            transition: "transform 0.2s ease",
                                            '&:hover': {
                                                backgroundColor: 'aliceblue',
                                                transform: "scale(0.95)",
                                                color: "black"
                                            },
                                        }}
                                    >
                                        {/* <ListItemIcon sx={{ minWidth: '40px' }}>
                                            <HistoryIcon sx={{ color: 'rgb(126, 126, 126)' }} />
                                        </ListItemIcon> */}
                                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                            Payment History
                                        </Typography>
                                    </ListItem>
                                    <Divider sx={{ height: "1px", bgcolor: "#ccc", width: "100%" }} />
                                    <ListItem
                                        button
                                        onClick={() => signOut()}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: isMobile ? '8px' : '12px',
                                            width: '100%',
                                            borderRadius: 1,
                                            color: 'rgb(126, 126, 126)',
                                            transition: "transform 0.2s ease",
                                            '&:hover': {
                                                backgroundColor: 'aliceblue',
                                                transform: "scale(0.95)",
                                                color: "black"
                                            },
                                        }}
                                    >
                                        <ListItemIcon sx={{ minWidth: '40px' }}>
                                            <Logout sx={{ color: 'lightgrey' }} />
                                        </ListItemIcon>
                                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                            Log Out
                                        </Typography>
                                    </ListItem>
                                </Box>
                            </div>

                        </ListItem>
                    )



                )
                    :
                    <ListItem button onClick={() => handleMenuClick("signin")} sx={{ width: "fit-content", padding: isMobile ? "10px" : "auto" }}>
                        Sign In
                    </ListItem>

            }
        </List >
    );



    return (
        <>
            <Slide direction="down" in={showHeader}>
                <AppBar
                    position="fixed"
                    sx={{
                        p: 1,
                        color: isScrolledUp || path === "pricing" ? "white" : "black",
                        backgroundColor: isScrolledUp ? "#020332" : "transparent",
                        boxShadow: isScrolledUp ? 2 : "none",
                        transition: "background-color 0.3s ease-in-out",
                    }}
                >
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>

                        <Box sx={{ width: "200px", cursor: "pointer" }} onClick={() => handleMenuClick("")}>
                            <img
                                src={require(`../images/New Shubadinam Logo/${isScrolledUp || path === "pricing" ? "Shubadinam White" : "Shubadinam Blue"}.png`)}
                                style={{ width: "100%", objectFit: "contain" }}
                                alt="Shubadinam Logo"
                            />
                        </Box>

                        {!isMobile && renderMenu()}
                        {isMobile && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Toolbar>
                </AppBar>
            </Slide>

            {isMobile && (
                <Drawer anchor="left" open={isMobileOpen} onClose={toggleDrawer}
                    sx={{
                        '& .MuiDrawer-paper': {
                            maxHeight: '100vh',  // Ensure the drawer height doesn't exceed the viewport
                            overflowY: 'auto',   // Enable vertical scrolling when content overflows
                        },
                    }}>
                    {renderMenu()}
                </Drawer>
            )}


        </>
    );
};

export default SlidingHeader;
