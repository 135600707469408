import React, { useEffect } from "react";
import FooterComponent from "../components/footer";
import ScrollToTop from "react-scroll-to-top";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SlidingHeader from "../components/SlidingHeader";
import { Container, Typography, Box, } from "@mui/material";
import PageSlider from "./utils/PageSlider";

const TermsAndConditionsScreen = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Terms Of Service</title>
                    <meta name="description" content="Terms and Conditions - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>


            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle={`Terms Of Service`}

            />

            {/* Main Content */}
            <ScrollToTop smooth />
            <Box mt={5}>
                <SlidingHeader />

                <Container maxWidth="lg">

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>Introduction:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            Welcome to Shubadinam.com! These Terms and Conditions ("Terms") govern your use of our website ("Site") and all services provided by Shubadinam.com which is part of the parent body Techcent Innovations. By accessing and using our Site, you agree to comply with these Terms.
                        </Typography>
                    </Box>

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>Use of the Site:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            You must be at least 18 years old to use our Site. You agree to use the Site for personal and non-commercial purposes only. Any commercial use, reproduction, or distribution of the Site's content without our explicit permission is strictly prohibited.
                        </Typography>
                    </Box>

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>Intellectual Property:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            All content and materials on the Site, including but not limited to text, images, graphics, logos, and software, are the intellectual property of Techcent Innovations and protected by applicable copyright and trademark laws. You may not use, copy, or distribute any of our intellectual property without our prior written consent.
                        </Typography>
                    </Box>

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>User Conduct:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            You agree to use the Site responsibly and refrain from engaging in any activities that may harm, interfere with, or disrupt the functioning of the Site. This includes but is not limited to hacking, spamming, or transmitting harmful content.
                        </Typography>
                    </Box>

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>Accuracy of Information:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            While we strive to provide accurate and up-to-date information, we make no warranties or representations about the accuracy, completeness, or reliability of any content on the Site. Use the information on the Site at your own risk.
                        </Typography>
                    </Box>

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>Third-Party Links:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            The Site may contain links to third-party websites or services that are not controlled or endorsed by us. We are not responsible for the content, privacy policies, or practices of any third-party websites or services. Use them at your own risk.
                        </Typography>
                    </Box>

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>Changes to the Terms:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            We reserve the right to modify or update these Terms at any time without notice. It is your responsibility to review these Terms periodically for changes. By continuing to use the Site after any modifications, you agree to be bound by the updated Terms.
                        </Typography>
                    </Box>

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>Disclaimer of Liability:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from your use of the Site or any content therein.
                        </Typography>
                    </Box>

                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>Governing Law and Jurisdiction:</Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising out of these Terms shall be subject to the exclusive jurisdiction of the courts in Chennai / India.
                        </Typography>
                    </Box>
                </Container>

                <FooterComponent />
            </Box>
        </div>
    )
}

export default TermsAndConditionsScreen;
