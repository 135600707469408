import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import Swal from "sweetalert2";
import FooterComponent from "../components/footer";
import SignInComponent from "../components/signin-pop";
import EditComponent from "../components/EditComponent";
import UserEditComponent from "../components/UserEditComponent";
import ReactPaginate from "react-paginate";
import ScrollToTop from "react-scroll-to-top";
import DashboardHeader from "../components/DashboardHeader";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from "@mui/material/TextField";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Edit, Delete, Pending } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PremiumPurchaseAlert from "./utils/PremiumPurchaseAlert";
import DashboardEvents from "../components/DashboardEvents";
import SearchIcon from '@mui/icons-material/Search';
import CardGiftcard from "@mui/icons-material/CardGiftcard";
import SlidingHeader from "../components/SlidingHeader";


const HomeScreen = ({ userData }) => {

  const Navigate = useNavigate();
  const [dataTwo, setDataTwo] = useState([]);
  const [data, setData] = useState([]);

  const [userId, setUserId] = useState({
    uniqueId: '',
    userPhone: '',
    profile_pending: ""
  });


  useEffect(() => {

    fetchData(); // Call the async function
  }, [userData]); // Only re-run if userData changes

  const fetchData = async () => {

    if (userData?.uniqueId) {

      // Set userId and scroll to top
      const { uniqueId, userPhone, profile_percent } = userData;
      setUserId({ uniqueId, userPhone, profile_percent });
      window.scrollTo(0, 0); // Scroll to the top when userData changes

      // Fetch profile completion
      try {
        const url = `https://backend.shubadinam.com/profile-completed?uniqueId=${uniqueId}`;
        const response = await axios.get(url);
        const relativesTable = response.data.data1;
        const userTable = response.data.data2;

        setDataTwo(userTable);
        setData(relativesTable);
      } catch (error) {
        console.error("Error fetching profile completion:", error);
        // Optionally handle navigation or other error logic here
      }

      // Fetch user date

      try {
        const url = `https://backend.shubadinam.com/fetch/userdate?uniqueId=${userData.uniqueId}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchTables = async () => {
    if (userData?.uniqueId) {

      // Set userId and scroll to top
      const { uniqueId, userPhone, profile_percent } = userData;
      setUserId({ uniqueId, userPhone, profile_percent });
      window.scrollTo(0, 0); // Scroll to the top when userData changes

      try {
        const url = `https://backend.shubadinam.com/profile-completed?uniqueId=${uniqueId}`;
        const response = await axios.get(url);
        const relativesTable = response.data.data1;
        const userTable = response.data.data2;

        // Set the data to state
        setDataTwo(userTable);
        setData(relativesTable);
      } catch (error) {
        console.error("Error fetching profile completion:", error);
        // Optionally handle navigation or other error logic here
      }
    }
  };


  const [search, setSearch] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [sortOption, setSortOption] = useState(null);


  // edit seciton
  const [isEdit, SetOpenEdit] = useState(false);
  const [isUserEdit, SetOpenUserEdit] = useState(false);

  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedseUserObject, setSelectedUserObject] = useState(null);

  const clickEdit = (object) => {
    // if (object.userRelation === "Myself") {
    //     alert("lfhdhfdlfhlh")
    //     SetOpenUserEdit(true)
    //     SetOpenEdit(false);
    //     setSelectedUserObject(object);
    // } else {
    SetOpenEdit(true);
    SetOpenUserEdit(false);
    setSelectedObject(object);
    // }
  };

  const clickEditUser = (object) => {
    // console.log(object);

    if (object.userRelation == "Myself") {
      SetOpenUserEdit(true);
      SetOpenEdit(false);
      setSelectedUserObject(object);
    }
  };

  const onSave = (editedData) => {
    // console.log(editedData);

    const url = "https://backend.shubadinam.com/edit/reldetails/" + editedData.profileid;

    axios
      .put(url, editedData)
      .then((response) => {
        const result = response.data.message;
        // window.location.reload()

        Swal.fire({
          title: "Edit Profile",
          text: result,
          icon: "success",
          confirmButtonText: "Ok",
        })
          .then(() => {
            fetchTables();
            SetOpenEdit(false);
          })
          .catch(() => {
            fetchTables();
          });
        // alert(result)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSaveUser = (editedData) => {
    console.log(editedData);

    const url =
      "https://backend.shubadinam.com/edit/userdetails/" + editedData.firstuserid;

    axios
      .put(url, editedData)
      .then((response) => {
        const result = response.data.message;
        // window.location.reload()

        Swal.fire({
          title: "Edit Profile",
          text: result,
          icon: "success",
          confirmButtonText: "Ok",
        })
          .then(() => {
            fetchTables();
            SetOpenUserEdit(false);
          })
          .catch(() => {
            fetchTables();
          });
        // alert(result)
      })
      .catch((error) => {
        console.log(error);
      });

    SetOpenUserEdit(false);
  };

  const deleteUserDetails = (object) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to Delete this profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      confirmButtonColor: "#060856",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the deletion
        const url =
          "https://backend.shubadinam.com/delete/userdetails/" + object.profileid;

        axios
          .delete(url)
          .then((response) => {
            const result = response.data;
            window.location.reload();
            // })
          })
          .catch((error) => {
          });
      }
    });
  };
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 8; // Number of rows per page
  const tbodyRef = useRef(); // Create a ref for the tbody element


  const CompleteProfileButton = () => (
    <Button
      size="small"
      variant="contained"
      // color="warning"
      onClick={() => window.location.href = "/profile"}
      sx={{
        borderRadius: 3, fontSize: "0.8rem !important", textTransform: "capitalize", bgcolor: "red", '&:hover': {
          bgcolor: "red",
        }
      }}
      startIcon={<Pending />}
    >
      Pending
      {/* {userData && userData.profile_percent
        ? (parseInt(userData.profile_percent) - 100)
        : 0}% */}
    </Button>
  );


  const SendGiftButton = () => {
    return (
      greater1200 ? (
        <Button
          size="small"
          variant="contained"
          startIcon={<CardGiftcardIcon />}
          onClick={() => window.open("https://shop.shubadinam.com/", "_blank")}
          style={{
            width: 'fit-content',
            padding: "5px 10px",
            color: "#ffffff",
            fontWeight: "bold",
            background: "linear-gradient(135deg, #3A8DFF, #1E6FBD)", // Blue gradient
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)", // Soft shadow for a professional feel
            transition: "transform 0.2s ease, box-shadow 0.2s ease",
            borderRadius: "6px",
            overflow: "hidden",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
            e.currentTarget.style.boxShadow = "0 4px 15px rgba(0, 0, 0, 0.25)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.15)";
          }}
        >
          Buy Gift
        </Button>
      )
        :

        <Tooltip title="Buy Gift" arrow>
          <CardGiftcard
            sx={{
              fontSize: '2rem', // Adjust size of the icon
              color: '#3A8DFF', // Blue color for the icon
              transition: 'transform 0.3s ease, color 0.3s ease', // Smooth transition for hover effects
              '&:hover': {
                transform: 'scale(1.1)', // Slightly scale up on hover
                color: '#1E6FBD', // Change color on hover
              },
              cursor: 'pointer', // Change the cursor to pointer to indicate interactivity
              borderRadius: '50%', // Optional: Round the edges for a more modern feel
              padding: '5px', // Padding around the icon
              backgroundColor: 'rgba(58, 141, 255, 0.1)', // Light background with slight transparency
            }}
            onClick={() => window.open("https://shop.shubadinam.com/", "_blank")}

          />
        </Tooltip>
    );
  };


  useEffect(() => {
    // Check if data is not null before filtering
    if (data) {
      // Filter data based on search term
      const filtered = data.filter(item => {
        const searchableFields = ['relName', 'relGender', 'relRelation', 'relCalender', 'relDate', 'relMonth', 'relNakshathra', 'relPaksham', 'relTithi']; // Fields to search
        return searchableFields.some(field =>
          item[field]?.toString().toLowerCase().includes(search.toLowerCase())
        );
      });

      if (sortOption === 'A-Z') {
        filtered.sort((a, b) => a.relName.localeCompare(b.relName));
      }
      else if (sortOption === 'Date') {
        filtered.sort((a, b) => {
          // Convert strings to Date objects for comparison
          const dateA = a.relDate;
          const dateB = b.relDate;

          // Compare dates
          if (dateA < dateB) {
            return -1; // dateA comes before dateB
          } else if (dateA > dateB) {
            return 1; // dateA comes after dateB
          } else {
            return 0; // dates are equal
          }
        })
      }

      setFilteredData(filtered);
    }
  }, [search, data, sortOption]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);

    if (tbodyRef.current) {
      const tableBodyTop = tbodyRef.current.offsetTop;

      // Scroll to the top of the tbody
      window.scrollTo({
        top: tableBodyTop,
        behavior: "smooth", // You can use 'auto' for instant scrolling
      });
    }
  };

  const handleSortOptionChange = (option) => {
    setSortOption(option);
  };

  const offset = currentPage * rowsPerPage;
  const currentData = filteredData.slice(offset, offset + rowsPerPage);

  const [expandedRows, setExpandedRows] = useState([]);

  // Toggle the row's visibility
  const toggleRow = (id) => {
    if (id && userData.profile_percent === "100" && id.firstuserid) { //&& id.userDate !== "-- Not Found --"
      setExpandedRows((prevExpandedRows) =>
        prevExpandedRows.includes(id.firstuserid)
          ? prevExpandedRows.filter((rowId) => rowId !== id.firstuserid)
          : [...prevExpandedRows, id.firstuserid]
      );
    }
  };

  const [expandedRows2, setExpandedRows2] = useState([]);

  const toggleRow2 = (id) => {
    // console.log(id);
    if (id && id.profileid && id.relDate) {
      setExpandedRows2((prevExpandedRows) =>
        prevExpandedRows.includes(id.profileid)
          ? prevExpandedRows.filter((rowId) => rowId !== id.profileid)
          : [...prevExpandedRows, id.profileid]
      );
    }
  };


  const [greater1200, setGreater1200] = useState(false);
  const [greater768, setGreater768] = useState(false);
  const [lesser768, setlesser768] = useState(false);
  const [isNonPremium, setisNonPremium] = useState(false);


  useEffect(() => {

    const checkPremium = () => {
      const isNotPremium = sessionStorage.getItem("nonpre");
      if (isNotPremium) {
        setisNonPremium(true)
      }
    };

    const handleResize = () => {
      setGreater1200(window.innerWidth > 1300);
      setGreater768(window.innerWidth > 768);
      setlesser768(window.innerWidth < 768);
    };

    // Initial setup
    handleResize();
    checkPremium();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePremiumButton = (userType) => {
    if (userType) {
      if (userType === "non-premium") {
        setisNonPremium(true)
      } else {
        setisNonPremium(false)
      }

    }
  };

  const [age, setAge] = useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleButtonClick = (page) => {
    Navigate(`/${page}`);
  };

  const newFilteredData = (object) => {
    return (
      (object.userName && object.userName.toLowerCase().includes(search.toLowerCase())) ||
      (object.userCalender && object.userCalender.toLowerCase().includes(search.toLowerCase())) ||
      (object.userRelation && object.userRelation.toLowerCase().includes(search.toLowerCase())) ||
      (object.userMonth && object.userMonth.toLowerCase().includes(search.toLowerCase())) ||
      (object.nakshatratithi && object.nakshatratithi.toLowerCase().includes(search.toLowerCase())) ||
      (object.userPaksham && object.userPaksham.toLowerCase().includes(search.toLowerCase())) ||
      (object.userTithi && object.userTithi.toLowerCase().includes(search.toLowerCase())) ||
      (object.userNakshatra && object.userNakshatra.toLowerCase().includes(search.toLowerCase())) ||
      (object.userDate && object.userDate.toLowerCase().includes(search.toLowerCase())) ||
      (object.relName && object.relName.toLowerCase().includes(search.toLowerCase())) ||
      (object.relRelation && object.relRelation.toLowerCase().includes(search.toLowerCase())) ||
      (object.relCalender && object.relCalender.toLowerCase().includes(search.toLowerCase())) ||
      (object.relMonth && object.relMonth.toLowerCase().includes(search.toLowerCase())) ||
      (object.relPaksham && object.relPaksham.toLowerCase().includes(search.toLowerCase())) ||
      (object.relTithi && object.relTithi.toLowerCase().includes(search.toLowerCase())) ||
      (object.relNakshathra && object.relNakshathra.toLowerCase().includes(search.toLowerCase())) ||
      (object.relDate && object.relDate.toLowerCase().includes(search.toLowerCase()))
    );
  };



  return (


    <Box >

      <PremiumPurchaseAlert handlePremiumButton={handlePremiumButton} isProfileCompleted={userData.profile_percent} />


      <HelmetProvider>
        <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Dashboard - Janma Nakshatra/Tithi Birthday Reminder Service." />
        </Helmet>
      </HelmetProvider>
      {/* <DashboardHeader /> */}
      <SlidingHeader />

      <ScrollToTop smooth />
      <Container sx={{ mt: { xs: 10, md: 10 } }}>

        <Box sx={{ width: "100%", textAlign: "end", p: { xs: 2, lg: 5 } }}>
          <NavLink to="/homam-bookings">My Bookings</NavLink>
        </Box>


        <DashboardEvents />

        {/* search and premium */}
        <Stack spacing={2} padding={2} alignItems="stretch">
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search anything from the table..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <SearchIcon sx={{ marginRight: 1 }} />
                ),
              }}
              sx={{ maxWidth: 300 }}
            // fullWidth
            />

            <FormControl size="small" sx={{ minWidth: 80, ml: 2 }} title="Filter">
              <InputLabel id="filter-select-label">
                <FilterAltIcon />
              </InputLabel>
              <Select
                labelId="filter-select-label"
                id="filter-select"
                value={age}
                onChange={handleChange}
                autoWidth
                label="Filter"
              >
                <MenuItem onClick={() => handleSortOptionChange('A-Z')} value={10}>
                  Sort by A-Z
                </MenuItem>
                <MenuItem onClick={() => handleSortOptionChange('Date')} value={21}>
                  Sort by Date
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Stack spacing={0} alignItems="center" direction="row" justifyContent={{ xs: "center", md: "flex-end" }}>

            {/* Button for larger screens */}
            {isNonPremium && (
              <Button
                variant="contained"
                size="medium"
                color="info"
                onClick={() => handleButtonClick("premium-checkout")}
                style={{
                  width: 'fit-content',
                  marginRight: "16px",
                  padding: "5px 10px",
                  color: "#ffffff",
                  fontWeight: "bold",
                  background: "linear-gradient(45deg, #8A2BE2, #FF69B4)", // Purple to rose gradient
                  boxShadow: "0 4px 12px rgba(138, 43, 226, 0.4)", // Subtle purple shadow
                  transition: "transform 0.2s ease, box-shadow 0.2s ease",
                  borderRadius: "8px",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow = "0 4px 15px rgba(0, 0, 0, 0.25)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.15)";
                }}

              >
                👑 Get Premium
              </Button>
            )}

            <SignInComponent userData={userData} />

          </Stack>
        </Stack>

        <Box>

          {
            userData ?
              <>
                <div className="table-container">

                  {greater1200 ?

                    <TableContainer component={Paper} sx={{ marginTop: '20px', boxShadow: 3 }}>
                      <Table sx={{ minWidth: 650 }} aria-label="responsive table">
                        {/* Table Header */}
                        <TableHead >
                          <TableRow >
                            {['NAME', 'RELATION', 'PANCHANG', 'MONTH', 'JANMA NAKSHATRA / TITHI', 'JANMA DINAM', 'BUY GIFT', 'ACTIONS'].map((header) => (
                              <TableCell
                                key={header}
                                align="center"
                                sx={{ fontWeight: 'bold', color: 'white', fontSize: '14px', backgroundColor: '#1b3e69 !important' }} // Styling for header
                              >
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody>
                          {dataTwo && dataTwo.filter(newFilteredData).map((object, index) => (
                            <TableRow hover key={index}>
                              <TableCell align="center">
                                <Tooltip title={object.userName}>
                                  <span>{object.userName}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="center">{object.userRelation || "-"}</TableCell>
                              <TableCell align="center">{object.userCalender || "-"}</TableCell>
                              <TableCell align="center">{object.userMonth || "-"}</TableCell>
                              <TableCell align="center">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <span>{object.userPaksham}</span>
                                  <span>{object.userTithi}</span>
                                  <span>{object.userNakshathra}</span>
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <Box display="flex" flexDirection="column">
                                  {userData.profile_percent !== "100" && <Typography variant="caption" color="red">Profile</Typography>}
                                  {userData.profile_percent === "100" ? object.userDate : <CompleteProfileButton />}
                                </Box>
                              </TableCell>
                              <TableCell align="center">{<SendGiftButton />}</TableCell>

                              <TableCell align="center">
                                <Tooltip title="Edit">
                                  <IconButton onClick={() => clickEditUser(object)} size="small" color="primary">
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}

                          {/* Second dataset */}
                          {currentData.map((object, index) => (
                            <TableRow hover key={index}>
                              <TableCell align="center">
                                <Tooltip title={object.relName}>
                                  <span>{object.relName}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="center">{object.relRelation}</TableCell>
                              <TableCell align="center">{object.relCalender}</TableCell>
                              <TableCell align="center">{object.relMonth}</TableCell>
                              <TableCell align="center">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <span>{object.relPaksham}</span>
                                  <span>{object.relTithi}</span>
                                  <span>{object.relNakshathra}</span>
                                </div>
                              </TableCell>
                              <TableCell align="center">{object.relDate}</TableCell>
                              <TableCell align="center">{<SendGiftButton />}</TableCell>
                              <TableCell align="center">
                                <Tooltip title="Edit">
                                  <IconButton onClick={() => clickEdit(object)} size="small" color="primary">
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton onClick={() => deleteUserDetails(object)} size="small" color="primary">
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    :
                    // <>
                    // )}</>
                    <table id="customers" className="table-header">
                      <thead className="table-header">
                        <tr colSpan={2}>

                          <th className="centered-cell" colSpan={2}>NAME</th>
                          <th className="centered-cell" colSpan={3}>JANMA DINAM</th>
                          <th className="centered-cell" colSpan={2}>
                            BUY GIFT
                          </th>
                          <th colSpan={1}></th>
                        </tr>
                      </thead>
                      <tbody className="table-body" ref={tbodyRef}>
                        {
                          dataTwo &&
                          dataTwo
                            .filter(
                              (object) =>
                                (object.userName &&
                                  object.userName
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.userCalender &&
                                  object.userCalender
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.userRelation &&
                                  object.userRelation
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.userMonth &&
                                  object.userMonth
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.nakshatratithi &&
                                  object.nakshatratithi
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.userPaksham &&
                                  object.userPaksham
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.userTithi &&
                                  object.userTithi
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.userNakshatra &&
                                  object.userNakshatra
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.userDate &&
                                  object.userDate
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.relName &&
                                  object.relName
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.relRelation &&
                                  object.relRelation
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.relCalender &&
                                  object.relCalender
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.relMonth &&
                                  object.relMonth
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.relPaksham &&
                                  object.relPaksham
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.relTithi &&
                                  object.relTithi
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.relNakshathra &&
                                  object.relNakshathra
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) ||
                                (object.relDate &&
                                  object.relDate
                                    .toLowerCase()
                                    .includes(search.toLowerCase()))
                            )

                            .map((object, index) => (
                              <React.Fragment key={index}>
                                <tr className="all-row-size" onClick={() => toggleRow(object)} >


                                  <td className="centered-cell" colSpan={2}>
                                    <Tooltip title={object.userName} placement="top">
                                      <span>{object.userName}</span>
                                    </Tooltip>

                                  </td>
                                  <td
                                    className="centered-cell dateSize"
                                    id="unique-cell"
                                    colSpan={3}
                                  >
                                    <Box display="flex" flexDirection="column">
                                      {userData.profile_percent !== "100" && <Typography variant="caption" color="red">Profile</Typography>}

                                      <span >{userData.profile_percent === "100" ? object.userDate : <CompleteProfileButton />}</span>
                                    </Box>
                                  </td>
                                  <td
                                    className="centered-cell dateSize"
                                    id="unique-cell"
                                    colSpan={2}
                                  >
                                    <span >{<SendGiftButton />}</span>
                                    {/* <span >{userData.profile_percent === "100" ? <SendGiftButton /> : <CompleteProfileButton />}</span> */}
                                  </td>
                                  <td style={{ backgroundColor: "white", textAlign: "center" }}>
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      style={{ border: "none" }}
                                    >
                                      {expandedRows.includes(object.firstuserid) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                  </td>
                                </tr>


                                {expandedRows.includes(object.firstuserid) && (

                                  <tr className="open-transition">

                                    <td id="home-additional" colSpan={7} style={{ borderRight: "none", background: "linear-graderien" }}>
                                      <p style={{ marginTop: "10px", textIndent: "20px", }}>Additional Details :</p>
                                      <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                          <p >Panchang:</p>
                                          <p>Relation:</p>
                                          <p>Month:</p>
                                          <p>{object.nakshatratithi === "Nakshatra" ? "Janma Nakshatra" : "Janma Tithi"}</p>
                                          <p>Actions:</p>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                          <p>{object.userCalender}</p>
                                          <p>{object.userRelation}</p>
                                          <p>{object.userMonth}</p>

                                          <p>{object.nakshatratithi === "Nakshatra" ? (
                                            <span className="nakshatra-table">
                                              {object.userNakshathra}
                                            </span>
                                          ) : (
                                            <p>
                                              <span className="paksham-table">
                                                {object.userPaksham}
                                              </span>
                                              <span className="tithi-table">
                                                {object.userTithi}
                                              </span>
                                            </p>
                                          )}</p>


                                          <Tooltip title="Edit" arrow>
                                            <IconButton
                                              size="small"
                                              onClick={() => clickEditUser(object)}
                                              sx={{
                                                color: 'primary', // Inherit color from parent or define a custom color
                                              }}
                                            >
                                              <Edit />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </td>
                                    <td style={{ backgroundColor: "white", textAlign: "center", borderLeft: "none" }}>

                                    </td>
                                  </tr>
                                )}

                              </React.Fragment>
                            ))
                        }





                        {currentData.map((object, index) => (
                          <React.Fragment key={index}>
                            <tr className="all-row-size" onClick={() => {
                              toggleRow2(object);
                              // console.log(object);
                            }}>
                              <td className="centered-cell" colSpan={2}>
                                <Tooltip title={object.relName} placement="top">
                                  {object.relName}
                                </Tooltip>

                              </td>
                              <td
                                className="centered-cell dateSize"
                                id="unique-cell"
                                colSpan={3}
                              >
                                <span style={{}}>{object.relDate}</span>
                              </td>
                              <td
                                className="centered-cell dateSize"
                                id="unique-cell"
                                colSpan={2}
                              >

                                <span >{<SendGiftButton />}</span>
                              </td>
                              <td style={{ backgroundColor: "white", textAlign: "center" }}>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  style={{ border: "none" }}
                                >
                                  {expandedRows2.includes(object.profileid) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                              </td>
                            </tr>
                            {expandedRows2.includes(object.profileid) && (

                              <tr className="additional-row">

                                <td id="home-additional" colSpan={7} style={{ borderRight: "none" }}>
                                  <p style={{ marginTop: "10px", textIndent: "20px", }}>Additional Details :</p>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                      <p>Panchang:</p>
                                      <p>Relation:</p>
                                      <p>Month:</p>
                                      <p>{object.nakshatratithi === "Nakshatra" ? "Janma Nakshatra" : "Janma Tithi"}</p>
                                      <p>Actions:</p>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <p>{object.relCalender}</p>
                                      <p>{object.relRelation}</p>
                                      <p>{object.relMonth}</p>
                                      <p>
                                        {object.nakshatratithi === "Nakshatra" ? (
                                          <span className="nakshatra-table">
                                            {object.relNakshathra}
                                          </span>
                                        ) : (
                                          <p>
                                            <span className="paksham-table">
                                              {object.relPaksham}
                                            </span>
                                            <span className="tithi-table">
                                              {object.relTithi}
                                            </span>
                                          </p>
                                        )}
                                      </p>
                                      <p>


                                        <Tooltip title="Edit" arrow>
                                          <IconButton
                                            size="small"
                                            onClick={() => clickEdit(object)}
                                            sx={{
                                              color: 'primary', // Inherit color from parent or define a custom color
                                            }}
                                          >
                                            <Edit />
                                          </IconButton>
                                        </Tooltip>


                                        <label style={{ width: '10px' }} />

                                        <Tooltip title="Delete" arrow>
                                          <IconButton
                                            size="small"
                                            onClick={() => deleteUserDetails(object)}
                                            sx={{
                                              color: 'primary', // Inherit color from parent or define a custom color
                                            }}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </Tooltip>

                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ backgroundColor: "white", textAlign: "center", borderLeft: "none" }}>

                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}



                      </tbody>
                    </table>

                  }

                </div>


                <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
                  <ReactPaginate
                    previousLabel={<IconButton
                      size="small"
                      sx={{
                        backgroundColor: '#4b769f',
                        color: '#fff',
                        '&:hover': { backgroundColor: '#356689' },
                        borderRadius: '50%',
                        p: 1,
                      }}
                    >
                      <ArrowBackIosNewSharpIcon />
                    </IconButton>}
                    nextLabel={<IconButton
                      size="small"
                      sx={{
                        backgroundColor: '#4b769f',
                        color: '#fff',
                        '&:hover': { backgroundColor: '#356689' },
                        borderRadius: '50%',
                        p: 1,
                        display: { xs: 'inline-flex', sm: 'inline-flex' }, // Hide on small screens
                      }}
                    >
                      <ArrowForwardIosSharpIcon />
                    </IconButton>}
                    breakLabel={"..."}
                    pageCount={Math.ceil(filteredData.length / rowsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    // subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </Box>
              </>
              :
              <Typography variant="h5" gutterBottom align="center">Loading ...</Typography>
          }

          {/* relatives edit dialog */}
          <Dialog open={isEdit} onClose={() => SetOpenEdit(false)} PaperProps={{
            sx: {
              margin: 0,  // Remove margin around the dialog
              padding: 0, // Remove padding from the dialog
              borderRadius: 0, // Optional: remove rounded corners
              maxWidth: 500
            },
          }} >
            <DialogTitle>Edit Details</DialogTitle>
            <DialogContent>
              {selectedObject && (
                <EditComponent
                  object={selectedObject}
                  onSave={onSave}
                  onClose={() => SetOpenEdit(false)}
                />
              )}
            </DialogContent>
          </Dialog>


          {/* users edit dialog */}
          <Dialog open={isUserEdit} onClose={() => SetOpenUserEdit(false)} PaperProps={{
            sx: {
              margin: 0,  // Remove margin around the dialog
              padding: 0, // Remove padding from the dialog
              borderRadius: 0, // Optional: remove rounded corners
              maxWidth: 500
            },
          }} >
            <DialogTitle>Edit Details</DialogTitle>
            <DialogContent>
              {selectedseUserObject && (
                <UserEditComponent
                  object={selectedseUserObject}
                  onSaveUser={onSaveUser}
                  onClose={() => SetOpenUserEdit(false)}
                />
              )}
            </DialogContent>
          </Dialog>


        </Box>

        {/* bottom add person */}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: '16px',
            backgroundColor: '#f4f4f4',
            borderRadius: '8px',
            flexDirection: { xs: 'row', sm: 'row', md: 'row' }, // Responsive row direction
            justifyContent: "space-evenly"
          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                marginRight: { xs: '10px', sm: '30px' }, // Adjust margin for smaller screens
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#4b769f', // Custom text color
              }}
            >
              Add your beloved ones
            </Typography>
          </Grid>
          <Grid item>
            <SignInComponent userData={userData} />
          </Grid>
        </Grid>

      </Container>
      <FooterComponent />

    </Box >


  );
};

export default HomeScreen;
