import React, { useState } from "react";
import {
    Grid,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Card,
    CardContent,
    CardActions,
    Box,
    TextField,
    Tab,
    Tabs,
} from "@mui/material";
import html2canvas from "html2canvas";
import { styled } from "@mui/material/styles";
import SDadminHeader from "../admin/SDadminHeader";
import { ArrowForward, DownloadForOffline, PhotoSharp, ViewAgenda } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';

const BackgroundContainer = styled(Box)(({ theme }) => ({
    fontFamily: "Arial, sans-serif",
    backgroundImage: "url('https://placehold.co/800x800?text=Background+Image')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const DailypostGenerator = () => {
    const [formData, setFormData] = useState({
        nakshatra: "",
        tithi: "",
        paksham: "",
        tamilMonth: "",
        hindiMonth: "",
        posterDate: ''
    });

    const [textColor, setTextColor] = useState("#ffffff");



    const imageFetcher1 = require.context('../images/admin_postGenerator/template_1', false, /\.(png|jpe?g|svg|webp)$/);

    // Map the image paths from require.context
    const template1_images = imageFetcher1.keys().map((fileName, index) => {
        const imagePath = imageFetcher1(fileName); // Get the image path
        return {
            id: index, // Assign a unique id to each image
            templateId: 1,
            url: imagePath, // Store the image path as url
        };
    });

    const imageFetcher2 = require.context('../images/admin_postGenerator/template_2', false, /\.(png|jpe?g|svg|webp)$/);

    // Map the image paths from require.context
    const template2_images = imageFetcher2.keys().map((fileName, index) => {
        const imagePath = imageFetcher2(fileName); // Get the image path
        return {
            id: index, // Assign a unique id to each image
            templateId: 2,
            url: imagePath, // Store the image path as url
        };
    });

    const imageFetcher3 = require.context('../images/admin_postGenerator/template_3', false, /\.(png|jpe?g|svg|webp)$/);

    // Map the image paths from require.context
    const template3_images = imageFetcher3.keys().map((fileName, index) => {
        const imagePath = imageFetcher3(fileName); // Get the image path
        return {
            id: index, // Assign a unique id to each image
            templateId: 3,
            url: imagePath, // Store the image path as url
        };
    });



    // Example template array
    const templates = [
        ...template1_images, // Merge dynamically loaded images into the templates array
        ...template2_images, // Merge dynamically loaded images into the templates array
        ...template3_images, // Merge dynamically loaded images into the templates array
    ];


    const [selectedTab, setSelectedTab] = useState(0);  // State to manage which tab is selected

    // Handle Tab Change
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);  // Change the selected tab
    };

    // Filter templates based on the selected tab (if needed)
    const filteredTemplates = templates.filter((template) => template.templateId === selectedTab + 1);


    const options = {
        nakshatra: [
            "Ashwini", "Bharani", "Krttika", "Rohini", "Mrghasira", "Ardra",
            "Punarvasu", "Pushya", "Ashlesha", "Magha", "Purva Phalguni", "Uttara Phalguni",
            "Hasta", "Chitra", "Swati", "Vishaka", "Anuradha", "Jyestha", "Moola",
            "Purvashada", "Uttarashada", "Sharavan", "Dhanishta", "Shatabisha",
            "Purvabhadra", "Uttarabhadra", "Revati"
        ],
        tithi: [
            "Prathama", "Dvitiya", "Tritiya", "Charturthi", "Panchami", "Shashti",
            "Saptami", "Ashtami", "Navami", "Dasami", "Ekadashi", "Dvadasi",
            "Trayodasi", "Chaturdasi", "Purnima", "Amavasya"
        ],
        paksham: ["Shukla Paksha", "Krishna Paksha"],
        tamilMonth: [
            "Chithirai", "Vaigaasi", "Aani", "Aadi", "Aavani", "Purattasi",
            "Ayppasi", "Karthikai", "Margazhi", "Thai", "Maasi", "Panguni"
        ],
        hindiMonth: [
            "Chaitra", "Vaisakha", "Jyestha", "Ashadha", "Shravana", "Bhadrapada",
            "Ashvin", "Katika", "Margasirsa", "Pausha", "Magh", "Phalguna"
        ],
    };

    const [backgroundImage, setBackgroundImage] = useState(null);
    const [template_Id, setTemplateId] = useState(null);


    const handleChange = (field) => (event) => {
        setFormData({ ...formData, [field]: event.target.value });
    };

    const SelectField = ({ label, value, onChange, options }) => (
        <FormControl sx={{ width: 400 }}>
            <InputLabel>{label}</InputLabel>
            <Select value={value} onChange={onChange}>
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };



    const isFormIncomplete = Object.values(formData).some(value => value === "");


    const downloadImage = () => {
        const imageElement = document.getElementById("image-container");

        html2canvas(imageElement, { useCORS: true }).then((canvas) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    // Create a temporary image element to load and compress the image
                    const img = new Image();
                    const url = URL.createObjectURL(blob);
                    img.src = url;

                    img.onload = () => {
                        // Create a canvas to resize and adjust quality
                        const resizedCanvas = document.createElement("canvas");
                        const ctx = resizedCanvas.getContext("2d");

                        // Set desired width and height for resizing the image
                        const maxWidth = 1024; // Adjust as per your requirement
                        const maxHeight = 1024; // Adjust as per your requirement
                        const scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height);

                        resizedCanvas.width = img.width * scaleFactor;
                        resizedCanvas.height = img.height * scaleFactor;

                        // Draw the image on the resized canvas
                        ctx.drawImage(img, 0, 0, resizedCanvas.width, resizedCanvas.height);

                        // Function to check the file size and adjust quality until it reaches the target
                        const targetSize = 512 * 1024; // 512 KB in bytes

                        const compressAndDownload = (quality) => {
                            resizedCanvas.toBlob((compressedBlob) => {
                                if (compressedBlob.size <= targetSize) {
                                    const link = document.createElement("a");
                                    link.download = "panchangam.jpg";
                                    link.href = URL.createObjectURL(compressedBlob);
                                    link.click();
                                } else if (quality > 0.1) { // Ensure that the quality doesn't go below a threshold
                                    // Try compressing further with a lower quality
                                    compressAndDownload(quality - 0.1);
                                } else {
                                    alert("Image size could not be reduced to target size");
                                }
                            }, "image/jpeg", quality);
                        };

                        // Start compression with an initial quality value (0.7)
                        compressAndDownload(0.7);
                    };
                }
            }, "image/jpeg");
        });
    };

    return (

        <Box>
            <SDadminHeader />

            <Box sx={{ mt: 10 }}>
                <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Generate Panchangam Image
                    </Typography>

                    <Card style={{ padding: "20px", margin: "20px 0" }}>
                        <CardContent>
                            <Grid container spacing={3}>
                                {/* Left Column for Select Fields */}
                                <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", justifyContent: "space-around", flexDirection: "column", width: "100%", alignItems: "center", bgcolor: "ghostwhite" }}>
                                    <Typography variant="h6" align="start" gutterBottom>
                                        Fill out the fields
                                    </Typography>
                                    <Grid container spacing={3}>
                                        {Object.entries(options).map(([key, values]) => (
                                            <Grid item key={key} xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <SelectField
                                                    label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                                                    value={formData[key]}
                                                    onChange={handleChange(key)}
                                                    options={values}
                                                />
                                            </Grid>
                                        ))}
                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <input
                                                type="date"
                                                value={formData.posterDate}
                                                onChange={(event) => setFormData({ ...formData, 'posterDate': event.target.value })}
                                                style={{
                                                    padding: "10px",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ccc",
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    width: "400px"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Right Column for Background Image and Color Selector */}
                                <Grid item xs={12} sm={6} md={6}>
                                    {/* Template Selection */}
                                    <Card sx={{ boxShadow: "0px 4px 10px rgba(0,0,0,0.1)", bgcolor: "ghostwhite" }}>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom align="start">
                                                Select a Background Template:
                                            </Typography>
                                            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                                                {templates.slice(0, 5).map((template) => (
                                                    <Grid item xs={6} sm={4} md={2} key={template.id}>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                padding: "5px",
                                                                borderRadius: "8px",
                                                                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                                                                border: backgroundImage === template.url ? "3px solid #4caf50" : "1px solid #ddd",
                                                                cursor: "pointer",
                                                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                                            }}
                                                            onClick={() => { setBackgroundImage(template.url); setTemplateId(template.templateId); }}
                                                        >
                                                            <img
                                                                src={template.url}
                                                                alt={`template-${template.id}`}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "auto",
                                                                    objectFit: "contain",
                                                                    borderRadius: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                ))}

                                                <Button
                                                    size="small"
                                                    sx={{
                                                        m: "auto",
                                                        p: 1,
                                                        width: "fit-content",
                                                        textTransform: "none"
                                                    }}
                                                    endIcon={<ArrowForward />}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleDialogOpen}
                                                >
                                                    View All
                                                </Button>
                                            </Grid>

                                            <Box display="flex" justifyContent="center" mt={2}>
                                                <input
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    id="file-input"
                                                    type="file"
                                                    onChange={(e) => {
                                                        if (e.target.files && e.target.files[0]) {
                                                            const fileURL = URL.createObjectURL(e.target.files[0]);
                                                            setBackgroundImage(fileURL); // Set the selected image as the background
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="file-input">
                                                    <Button startIcon={<PhotoSharp />} variant="outlined" component="span">
                                                        Browse Templates
                                                    </Button>
                                                </label>
                                            </Box>
                                            <Box display="flex" justifyContent="center" mt={2}>
                                            </Box>
                                        </CardContent>
                                    </Card>

                                    <br />
                                    {/* Dialog to show all templates */}
                                    <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="xl">
                                        <DialogTitle>All Templates</DialogTitle>
                                        <DialogContent>
                                            {/* Tabs for templates */}
                                            <Tabs value={selectedTab} onChange={handleTabChange} centered>
                                                <Tab label="Template 1" />
                                                <Tab label="Template 2" />
                                                <Tab label="Template 3" />
                                                {/* Add more tabs as needed */}
                                            </Tabs>

                                            <Grid container spacing={2}>
                                                {/* Render the templates based on selected tab */}
                                                {filteredTemplates.map((template) => (
                                                    <Grid item xs={6} sm={4} md={2} key={template.id}>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: "auto",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                border: template.url === backgroundImage ? "3px solid #4caf50" : "1px solid #ddd",
                                                                borderRadius: "8px",
                                                                padding: "10px",
                                                                transition: "transform 0.3s ease",
                                                            }}
                                                            onClick={() => {
                                                                setBackgroundImage(template.url);  // Set the background image
                                                                setTemplateId(template.templateId);
                                                                handleDialogClose();  // Close the dialog
                                                            }}>
                                                            <img
                                                                src={template.url}
                                                                alt={`template-${template.id}`}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "auto",
                                                                    objectFit: "contain",
                                                                    borderRadius: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button variant="contained" onClick={handleDialogClose} color="primary">
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    {/* Color Selector */}
                                    <Card sx={{ boxShadow: "0px 4px 10px rgba(0,0,0,0.1)", bgcolor: "ghostwhite" }}>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom align="start">
                                                Select Text Color:
                                            </Typography>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                flexDirection="column"
                                                gap={2}
                                                mt={2}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: 2,
                                                        border: "1px solid #ccc",
                                                        borderRadius: "8px",
                                                        padding: "10px",
                                                        maxWidth: "300px",
                                                        backgroundColor: "#f9f9f9",
                                                        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                >
                                                    <TextField
                                                        type="color"
                                                        value={textColor}
                                                        onChange={(e) => setTextColor(e.target.value)}
                                                        variant="standard"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            sx: { cursor: "pointer", width: "50px", height: "50px", padding: 0 },
                                                        }}
                                                    />
                                                    <Typography variant="body1" style={{ color: textColor }}>
                                                        {textColor}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="caption" color="textSecondary" align="center">
                                                    Pick a color to apply to the main content text.
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>
                        </CardContent>


                        {/* Preview Section */}
                        <CardActions>
                            <Grid item xs={12} sx={{ width: "100%" }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                {
                                    isFormIncomplete &&
                                    <Typography variant="caption" color="GrayText" align="center">
                                        (Fill all the above fields to Generate Image.* )
                                    </Typography>
                                }

                                <Button
                                    endIcon={<DownloadForOffline />}
                                    variant="contained"
                                    color="primary"
                                    sx={{ maxWidth: 400, mt: 5 }}
                                    onClick={downloadImage}
                                    disabled={isFormIncomplete}
                                >
                                    Download Image
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>

                    {/* Image Container with Main Content */}
                    <div
                        id="image-container"
                        style={{
                            position: "relative",
                            width: "1080px",
                            height: "1080px",
                            margin: "20px auto",
                            backgroundImage: `url('${backgroundImage}')`,
                            backgroundSize: "contain",
                            // backgroundPosition: "center",
                            color: "white",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "50px",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >
                        <Typography
                            variant="h5"
                            color={textColor}
                            fontWeight="700"
                            style={{
                                position: "absolute", // Absolute positioning over the background image
                                top: template_Id === 3 ? "16%" : "12%", // Adjust as needed
                                left: "35%", // Adjust as needed
                                zIndex: 2, // Ensure text stays above the background image
                            }}
                        >
                            {new Date(formData.posterDate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) || "Not Selected"}
                        </Typography>

                        <div style={{ position: "relative", zIndex: 2, textAlign: "start", top: template_Id === 1 ? "13.5%" : template_Id === 2 ? "16%" : "22.5%", left: "15%", color: textColor }}>
                            <Typography variant="h4" fontWeight="700" style={{ marginTop: "-10px", marginBottom: "30px" }}>
                                {`${formData.nakshatra || "Not Selected"}`}
                            </Typography>
                            <Typography variant="h4" fontWeight="700" style={{ marginBottom: "30px" }}>
                                {`${formData.paksham}, ${formData.tithi}`}
                            </Typography>
                            <Typography variant="h4" fontWeight="700" style={{ marginBottom: "20px" }}>
                                {`${formData.tamilMonth}/ ${formData.hindiMonth}`}
                            </Typography>
                        </div>
                    </div>
                </div>
            </Box>
        </Box >

    );
};

export default DailypostGenerator;
