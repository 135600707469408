import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Container, Typography, Box, Button, Grid } from "@mui/material";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import Top20px from "./Top20px";
import ScrollToTop from "react-scroll-to-top";

import { Helmet, HelmetProvider } from "react-helmet-async";
import LandingHeader from "../components/LandingHeader";
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";

const PricingPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Pricing Policy</title>
                    <meta name="description" content="Pricing Policy - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle={`Pricing Policy`}

            />

            <div >
                <ScrollToTop smooth />
                <SlidingHeader />

                <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4 }}>
                    <Box textAlign="center" mb={4}>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            At Shubadinam.com, we are introducing an upcoming premium package.
                        </Typography>
                    </Box>

                    <Box mb={4}>
                        <Typography variant="h5" gutterBottom>
                            Pricing Details
                        </Typography>
                        <Typography variant="body1" color="textPrimary" paragraph>
                            The pricing for the premium package will range from 99 INR to 999 INR. Additional details will be provided shortly.
                        </Typography>
                    </Box>
                </Container>

                <Top20px />
                <FooterComponent />
            </div>
        </div>
    );
};

export default PricingPolicy;
