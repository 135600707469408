import React, { useState } from 'react';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Pagination,
    TextField,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Modal,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const dummyProperties = [
    {
        id: 1,
        name: 'Cozy Cottage',
        location: 'Lake Tahoe',
        price: 150,
        rating: 4,
        image: 'https://placehold.co/600x400',
    },
    {
        id: 2,
        name: 'Modern Apartment',
        location: 'New York',
        price: 200,
        rating: 5,
        image: 'https://placehold.co/600x400',
    },
    {
        id: 3,
        name: 'Beach House',
        location: 'Miami',
        price: 250,
        rating: 4,
        image: 'https://placehold.co/600x400',
    },
    {
        id: 4,
        name: 'Mountain Retreat',
        location: 'Aspen',
        price: 300,
        rating: 5,
        image: 'https://placehold.co/600x400',
    },
    {
        id: 5,
        name: 'Urban Loft',
        location: 'San Francisco',
        price: 180,
        rating: 4,
        image: 'https://placehold.co/600x400',
    },
    {
        id: 6,
        name: 'Charming Bungalow',
        location: 'Nashville',
        price: 120,
        rating: 3,
        image: 'https://placehold.co/600x400',
    },
];

const Gallery = () => {
    const [properties] = useState(dummyProperties);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState('price');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentProperty, setCurrentProperty] = useState(null);

    // Filter and sort properties
    const filteredProperties = properties
        .filter(property => property.name.toLowerCase().includes(filter.toLowerCase()))
        .sort((a, b) => (sort === 'price' ? a.price - b.price : b.rating - a.rating));

    const indexOfLastProperty = currentPage * itemsPerPage;
    const indexOfFirstProperty = indexOfLastProperty - itemsPerPage;
    const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

    const handleOpenModal = (property) => {
        setCurrentProperty(property);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setCurrentProperty(null);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel>Sort By</InputLabel>
                    <Select
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                        label="Sort By"
                    >
                        <MenuItem value="price">Price</MenuItem>
                        <MenuItem value="rating">Rating</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Grid container spacing={2}>
                {currentProperties.map((property) => (
                    <Grid item xs={12} sm={6} md={4} key={property.id}>
                        <Card>
                            <CardMedia
                                component="img"
                                height="140"
                                image={property.image}
                                alt={property.name}
                                onClick={() => handleOpenModal(property)}
                            />
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {property.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Location: {property.location}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Price: ${property.price}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Rating: {'★'.repeat(property.rating)}{'☆'.repeat(5 - property.rating)}
                                </Typography>
                                <Button size="small" variant="contained" color="primary">
                                    View Details
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Pagination
                count={Math.ceil(filteredProperties.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                sx={{ marginTop: 2 }}
            />
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4
                }}>
                    {currentProperty && (
                        <>
                            <IconButton
                                onClick={handleCloseModal}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <CardMedia
                                component="img"
                                height="200"
                                image={currentProperty.image}
                                alt={currentProperty.name}
                            />
                            <Typography id="modal-title" variant="h6" component="h2">
                                {currentProperty.name}
                            </Typography>
                            <Typography id="modal-description" sx={{ mt: 2 }}>
                                Location: {currentProperty.location}
                            </Typography>
                            <Typography>
                                Price: ${currentProperty.price}
                            </Typography>
                            <Typography>
                                Rating: {'★'.repeat(currentProperty.rating)}{'☆'.repeat(5 - currentProperty.rating)}
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default Gallery;