import React from "react";
import FooterComponent from "../components/footer";
import { NavLink, useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import BottomSpace from "./BottomSpace";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container,
    Grid,
    Typography,
    Button,
    Box,
} from '@mui/material';
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";

const AboutScreen = () => {
    const navigate = useNavigate();

    const getStarted = () => {
        navigate("/");
    };

    return (
        <div style={{ backgroundColor: "aliceblue" }}>
            <HelmetProvider>
                <Helmet>
                    <title>About</title>
                    <meta name="description" content="About - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle={`About Us`}
                subTitle="Celebrating Cultural Identity Through Birth Stars"

            />

            <Box sx={{ mt: 5 }}>
                <Container maxWidth="lg">
                    {/* Heading Section */}


                    {/* Content Section */}
                    <Grid container spacing={6} alignItems="center">
                        {/* Left Column */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" color="primary" fontWeight="bold" mb={3}>
                                Welcome to Shubadinam:
                            </Typography>
                            <Typography variant="body1" color="text.primary" paragraph>
                                Shubadinam stands as a humble offering toward the noble cause of retaining and reclaiming the profound cultural legacy of India.
                                Our mission is to illuminate the importance of celebrating Birth Stars, known as Janma Nakshatra / Janma Tithi.
                            </Typography>
                            <Typography variant="body1" color="text.primary">
                                We firmly believe that comprehending and rejoicing in one's Birth Star is a potent means to connect with our identity and pay homage to the timeless wisdom passed down by our ancestors.
                            </Typography>
                        </Grid>

                        {/* Right Column */}
                        <Grid item xs={12} md={6}>
                            <img
                                src={require("../images/about-us.png")}
                                alt="About Us"
                                style={{ width: '100%', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' }}
                                onContextMenu={(e) => e.preventDefault()}
                            />
                        </Grid>
                    </Grid>

                    {/* Journey Section */}
                    <Box mt={8}>
                        <Grid container spacing={6} alignItems="center">
                            {/* Left Column */}
                            <Grid item xs={12} md={6}>
                                <Typography variant="h4" color="primary" fontWeight="bold" mb={3}>
                                    Our Journey of Rediscovery
                                </Typography>
                                <Typography variant="body1" color="text.primary" paragraph>
                                    As students, we were once influenced by a colonial hangover like many others. It was our exposure to Sanskrit Scholars and Astikas in our family that ignited a deep desire to preserve and celebrate the ancient wisdom of our traditions.
                                </Typography>
                                <Typography variant="body1" color="text.primary">
                                    Shubadinam was born from this longing, a passion to embrace and share our cultural identity with the world.
                                </Typography>

                            </Grid>

                            {/* Right Column */}
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        background: 'linear-gradient(135deg, #4b79a1, #283e51)',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        p: 4,
                                        boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                                    }}
                                >
                                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                                        Action, Not Just Gyaan (Knowledge)
                                    </Typography>
                                    <Typography variant="body2">
                                        During brainstorming sessions, we stumbled upon the topic of why people celebrate &nbsp;“English Birthday vs Janma Dinam”. The biggest challenge was the lack of knowledge and ease of following the Indian Calendar (Panchang).
                                        Our solution: <strong>Register Once and Receive Reminders for Life.</strong>
                                    </Typography>
                                    <Typography variant="body2" mt={2}>
                                        (<NavLink to="/why-us" style={{ color: '#ffdd59', textDecoration: 'none' }}>
                                            Click here to read more about “Why Shubadinam?”
                                        </NavLink>)
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={getStarted}
                        >
                            Get Started
                        </Button>
                    </Box>
                </Container>
            </Box>
            <BottomSpace />
            <FooterComponent />
        </div>
    );
};

export default AboutScreen;
