/* global ShopifyBuy */ // Inform ESLint that ShopifyBuy is a global variable


import React, { useState, useEffect } from 'react';
import { Button, Container, Grid, Typography, Card, CardContent, CardMedia, Paper, Box, CardActions } from '@mui/material';
import SlidingHeader from '../../components/SlidingHeader';
import PageSlider from '../utils/PageSlider';
import { Tabs, Tab, } from '@mui/material';
import FooterComponent from '../../components/footer';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Skeleton,
    useMediaQuery
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BookSharp, CardGiftcard, NavigateNext } from '@mui/icons-material';
import { Splide, SplideSlide } from '@splidejs/react-splide';


const bookData = {
    newArrival: [
        { title: 'Caele Bipin', author: 'Floyd Miles', price: '$20.00 USD', imgSrc: 'https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg' },
        { title: 'A Novel Designer', author: 'Robert Fox', price: '$14.00 USD', imgSrc: 'https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg' },
    ],
    bestSeller: [
        { title: 'Desi | Magazine', author: 'D. Simmons', price: '$22.00 USD', imgSrc: 'https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg' },
        { title: 'Better Reading', author: 'Floyd Miles', price: '$12.00 USD', imgSrc: 'https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg' },
    ],
    featured: [
        { title: 'Atrilcion', author: 'Robert Fox', price: '$7.00 USD', imgSrc: 'https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg' },
        { title: 'Cuentos Infantiles', author: 'John Doe', price: '$10.00 USD', imgSrc: 'https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg' },
    ],
};

const faqs = [
    {
        question: "What is Book Haven?",
        answer:
            "Book Haven is your one-stop destination for a wide selection of books, from timeless classics to modern bestsellers.",
    },
    {
        question: "How can I place an order?",
        answer:
            "Browse our collection, add your favorite books to the cart, and proceed to checkout. We accept various payment methods for your convenience.",
    },
    {
        question: "Do you offer international shipping?",
        answer:
            "Yes, we ship worldwide! Shipping costs and delivery times may vary based on your location.",
    },
    {
        question: "Can I return or exchange a book?",
        answer:
            "Absolutely! Returns and exchanges are accepted within 30 days of purchase. Please ensure the book is in its original condition.",
    },
];


const categories = [
    {
        name: 'Inspirational',
        img: require("../../images/books/inspiration_books.webp"),
        link: "https://shop.shubadinam.com/collections/inspirational",
        description: "Uplifting reads designed to spark creativity, hope, and motivation in everyday life."
    },
    {
        name: 'Spiritual',
        img: require("../../images/books/spiritual_books.webp"),
        link: "https://shop.shubadinam.com/collections/spiritual",
        description: "Books that delve into the spiritual realm, offering insights into faith, meditation, and inner peace."
    },
    {
        name: 'Self-Help',
        img: require("../../images/books/self books.webp"),
        link: "https://shop.shubadinam.com/collections/self-help",
        description: "Empowering guides to personal growth, emotional wellness, and achieving your goals."
    },
    {
        name: 'Traditional',
        img: require("../../images/books/traditional_books.jpg"),
        link: "https://shop.shubadinam.com/collections/traditional",
        description: "Timeless classics and cultural treasures that celebrate tradition and heritage."
    },
    {
        name: 'Motivational',
        img: require("../../images/books/motivational_books.webp"),
        link: "https://shop.shubadinam.com/collections/motivational",
        description: "Inspirational works that ignite passion, drive, and determination to succeed."
    },
];



const RoughSix = () => {

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const renderBooks = (books) => (
        <Grid container spacing={2}>
            {books.map((book, index) => (
                <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                    <Card
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            boxShadow: 3,
                            padding: 2,
                            display: "flex",
                            flexDirection: 'column',
                            justifyContent: "center"
                        }}
                    >
                        <CardMedia
                            component="img"
                            alt={`Book cover of '${book.title}'`}
                            image={book.imgSrc}
                            sx={{
                                height: { xs: '120px', md: '200px' },
                                width: 'auto',
                                objectFit: 'cover',
                            }}
                        />
                        <CardContent>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: '#6b7280',
                                    fontSize: '0.875rem',
                                }}
                            >
                                By {book.author}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: 'bold',
                                    marginBottom: 1,
                                }}
                            >
                                {book.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: '#10b981',
                                    fontSize: '1rem',
                                }}
                            >
                                {book.price}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size='small' variant="contained" sx={{ mt: 'auto', textTransform: "none", bgcolor: "#245d5a" }}>
                                Buy Now
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:900px)');
    const [products, setProducts] = useState([]); // State to hold fetched products
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        // Load the Shopify Buy Button script
        const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

        const loadScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = scriptURL;
            script.onload = () => {
                fetchProducts(); // Fetch products after script is loaded
            };
            (document.head || document.body).appendChild(script);
        };

        const fetchProducts = async () => {
            const client = ShopifyBuy.buildClient({
                domain: '67cc47-90.myshopify.com',
                storefrontAccessToken: '184085a79bdc9a41b82921e03a10e1b0',
            });

            // Replace 'your-collection-id' with your actual collection ID
            const collectionId = 'gid://shopify/Collection/297316384820';

            try {
                const collection = await client.collection.fetchWithProducts(collectionId);
                const formattedProducts = collection.products.map((product) => ({
                    name: product.title,
                    img: product.images[0]?.src || 'https://via.placeholder.com/150',
                    link: product.onlineStoreUrl,
                    price: product.variants[0].price.amount
                }));
                setProducts(formattedProducts);
            } catch (error) {
                console.error("Error fetching products from collection:", error);
            }
        };

        // Load the script
        if (window.ShopifyBuy && window.ShopifyBuy.UI) {
            fetchProducts();
        } else {
            loadScript();
        }

        // Cleanup function
        return () => {
            const existingScript = document.querySelector(`script[src="${scriptURL}"]`);
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);

    const [visibleProducts, setVisibleProducts] = useState(8);
    const maxVisibleItems = 7; // Limit the number of products displayed
    const productsToShow = products.length > maxVisibleItems ? products.slice(0, maxVisibleItems) : products;

    // Handle load more button click
    const handleLoadMore = () => {
        setVisibleProducts((prev) => prev + 8); // Increase visible products by 10
    };



    return (
        <div style={{ backgroundColor: 'aliceblue', }}>

            <SlidingHeader />
            {/* Hero Section */}
            <Box
                sx={{
                    backgroundImage: 'url(https://img.freepik.com/free-photo/old-books-arrangement-with-copy-space_23-2148898331.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid)',
                    backgroundSize: { xs: "cover", md: "contain" },
                    backgroundPosition: 'center',
                    height: { xs: "45vh", md: "90vh" },
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    textAlign: 'center',
                    position: 'relative',
                }}
            >

                <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1, top: { xs: "10%", md: '0px' } }}>
                    <Typography
                        fontWeight="bold"
                        gutterBottom
                        sx={{
                            color: "#054176",
                            fontSize: {
                                xs: "1.7rem", // equivalent to h4
                                md: "2.5rem", // equivalent to h3
                            },
                        }}
                        data-aos='fade-up'
                    >
                        Shubadinam Books
                    </Typography>

                    <Typography sx={{
                        marginBottom: 4, color: "grey", fontSize: {
                            xs: "1rem", // equivalent to h4
                            md: "1.5rem", // equivalent to h3
                        },
                    }} data-aos='fade-up' data-aos-delay={1000}>
                        Discover your next great read from our extensive collection of books.
                    </Typography>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => window.open("https://shop.shubadinam.com/collections/books", "_blank")}
                                variant="contained"
                                size="large"
                                sx={{ paddingX: '24px', bgcolor: "royalblue" }}
                            >
                                Explore Collection
                            </Button>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            {/* categories */}
            <Container maxWidth="xl" sx={{ py: 4, backgroundColor: 'aliceblue' }}>
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: 'center',
                        marginBottom: 2,
                        fontWeight: 'bold',
                    }}
                >
                    DEALS OF THE WEEK
                </Typography>
                <Typography variant="body1" align="center" sx={{ mb: 4 }}>
                    Explore our handpicked selections ranging from timeless literature to contemporary bestsellers across diverse genres.
                </Typography>

                <Splide
                    options={{
                        type: 'slide',
                        perPage: isSmallScreen ? 2 : isMediumScreen ? 2 : 4,
                        gap: '1rem',
                        arrows: false,
                        pagination: true,
                        autoplay: false,
                        interval: 3000,
                        perMove: 1,
                    }}
                >
                    {categories.length === 0 ? (
                        // Skeleton loading if products are empty
                        Array.from({ length: 7 }).map((_, index) => (
                            <SplideSlide key={index}>
                                <Card sx={{ width: 250, borderRadius: 2 }}>
                                    <Skeleton variant="rectangular" width="100%" height={150} />
                                    <CardContent sx={{ paddingTop: 2 }}>
                                        <Skeleton variant="text" width="80%" height={24} sx={{ marginBottom: 1 }} />
                                        <Skeleton variant="rectangular" width="80%" height={36} />
                                    </CardContent>
                                </Card>
                            </SplideSlide>
                        ))
                    ) : (
                        categories.map((product, index) => (
                            <SplideSlide key={index}>
                                <Card onClick={() => window.open(product.link, "_blank")} sx={{ borderRadius: 2, cursor: "pointer", }}>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image={product.img}
                                        alt={product.name}
                                        sx={{

                                            borderTopLeftRadius: 2,
                                            borderTopRightRadius: 2,
                                            objectFit: 'contain',
                                        }}
                                    />
                                    <CardContent
                                        sx={{
                                            paddingTop: 2,
                                            borderBottom: '1px solid grey',
                                            borderBottomLeftRadius: 2,
                                            borderBottomRightRadius: 2,
                                            bgcolor: '#eeeeee21',
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                textAlign: 'start',
                                                color: '#333',
                                                fontWeight: 500,
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 2,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontSize: "1rem",
                                            }}
                                        >
                                            {product.name}
                                        </Typography>
                                        <Typography
                                            variant="body5"
                                            component="div"
                                            color="textSecondary"
                                            sx={{
                                                textDecoration: "underline",
                                                height: {
                                                    xs: 'auto',
                                                    sm: '3em',
                                                },
                                                fontSize: {
                                                    xs: '0.775rem',
                                                    sm: '1rem',
                                                },
                                                // fontSize: '1em',
                                            }}
                                        >
                                            {product.description}
                                        </Typography>

                                        {/* <Button
                                            size="small"
                                            startIcon={
                                                <BookSharp
                                                    sx={{
                                                        color: 'white',
                                                    }}
                                                />
                                            }
                                            href={product.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            variant="contained"
                                            sx={{
                                                px: 3,
                                                py: 1.5,
                                                color: '#fff',
                                                borderRadius: 3,
                                                textTransform: 'none',
                                                backgroundColor: 'royalblue',
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    textDecoration: 'none',
                                                    color: 'white',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                Buy Now
                                            </Typography>
                                        </Button> */}

                                        {/* <Button
                                                size="small"
                                                startIcon={<CardGiftcard />}
                                                href={product.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                variant="contained"
                                                sx={{
                                                    p: 1,
                                                    color: '#fff',
                                                    borderRadius: 2,
                                                    textTransform: 'none', // Keeps the text case as is
                                                }}
                                            >
                                                <Typography variant="body2" sx={{ textDecoration: 'none', color: 'white' }}>
                                                    Buy Now
                                                </Typography>
                                            </Button> */}
                                    </CardContent>
                                </Card>
                            </SplideSlide>
                        ))
                    )}

                    {/* Add the "View All" card after 7 products */}
                    {products.length > maxVisibleItems && (
                        <SplideSlide>
                            <Card sx={{
                                width: '100%', height: '100%', borderRadius: 2, display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 150,  // Same height as product cards
                                        backgroundColor: '#eeeeee21',
                                        borderRadius: 2,
                                    }}
                                >
                                    <Button
                                        size='large'
                                        variant="contained"
                                        startIcon={<NavigateNext sx={{ color: 'white' }} />}  // Adding the icon as startIcon
                                        sx={{
                                            p: 2,
                                            color: 'white',
                                            backgroundColor: 'royalblue',
                                            '&:hover': {
                                                backgroundColor: '#0056b3',
                                            },
                                            textTransform: 'none', // Keeps the text case as is
                                            borderRadius: 2, // Ensures button has rounded corners
                                        }}
                                        onClick={() => window.open("https://shop.shubadinam.com/collections/books", "_blank")}// Directing to the "View All" URL
                                    >
                                        View All
                                    </Button>

                                </CardContent>
                            </Card>

                        </SplideSlide>
                    )}
                </Splide>


            </Container>

            {/* bannner */}
            <Grid container spacing={4} sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={4}
                        sx={{
                            padding: '24px',
                            backgroundColor: '#004d40',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                            }}
                        >
                            Bookshop TNC's Top 5 Most Memorable Moments
                        </Typography>
                        <Typography
                            variant="body1"
                            paragraph
                            sx={{
                                fontSize: '1rem',
                                lineHeight: 1.8,
                            }}
                        >
                            Dive into our unforgettable highlights, featuring exclusive author events, book launches, and exciting community celebrations.
                        </Typography>
                        <Button
                            onClick={() => window.open("https://shop.shubadinam.com/collections/books", "_blank")}
                            variant="contained"
                            sx={{
                                maxWidth: 400,
                                textAlign: "center",
                                backgroundColor: '#0089af',
                                color: 'white',
                                fontWeight: 'bold',
                                '&:hover': { backgroundColor: '#14a37f' },
                            }}
                        >
                            Explore Now
                        </Button>
                        <Box
                            component="img"
                            src="https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid"
                            alt="Stack of books with glasses"
                            sx={{
                                width: '100%',
                                borderRadius: '12px',
                                marginTop: '16px',
                                objectFit: 'cover',
                                height: '220px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={4}
                        sx={{
                            padding: '24px',
                            backgroundColor: '#212121',
                            color: 'white',
                            display: { xs: "none", md: "flex" },
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                            }}
                        >
                            Big Discount
                        </Typography>
                        <Typography
                            variant="body1"
                            paragraph
                            sx={{
                                fontSize: '1rem',
                                lineHeight: 1.8,
                            }}
                        >
                            Enjoy a massive 50% discount exclusively for our new users. Don’t miss this chance to grab your favorite books at unbeatable prices!
                        </Typography>
                        <Button
                            onClick={() => window.open("https://shop.shubadinam.com/collections/books", "_blank")}
                            variant="contained"
                            sx={{
                                maxWidth: 400,
                                textAlign: "center",
                                bgcolor: "#1b6952",
                                color: 'white',
                                fontWeight: 'bold',
                                '&:hover': { backgroundColor: '#14a37f' },
                            }}
                        >
                            Shop Now
                        </Button>
                        <Box
                            component="img"
                            src="https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid"
                            alt="Books with discount tags"
                            sx={{
                                width: '100%',
                                borderRadius: '12px',
                                marginTop: '16px',
                                objectFit: 'cover',
                                height: '220px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>

            {/* books */}
            <Container maxWidth="xl" sx={{ py: 4, backgroundColor: 'aliceblue' }}>
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: 'center',
                        marginBottom: 2,
                        fontWeight: 'bold',
                    }}
                >
                    BOOK COLLECTIONS
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'center',
                        marginBottom: 4,
                    }}
                >
                    Discover our top picks of the week, featuring timeless classics and modern favorites:
                </Typography>

                <div>
                    <Grid container spacing={2}>
                        {/* If products are loading or empty, show skeleton loaders */}
                        {(products.length === 0 ? Array(10).fill(null) : products.slice(0, visibleProducts)).map((product, index) => (
                            <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                                {product ? (
                                    <Card
                                        onClick={() => window.open(product.link, "_blank")}
                                        sx={{
                                            cursor: 'pointer',
                                            borderRadius: 2,
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                            '&:hover': {
                                                transform: 'scale(1.01)',
                                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                                            },
                                            height: '100%',
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="180"
                                            image={product.img}
                                            alt={product.name}
                                            sx={{
                                                borderTopLeftRadius: 2,
                                                borderTopRightRadius: 2,
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <CardContent
                                            sx={{
                                                padding: 2,
                                                borderBottom: '1px solid grey',
                                                bgcolor: '#eeeeee73',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                sx={{
                                                    textAlign: 'start',
                                                    color: '#333',
                                                    marginBottom: 1,
                                                    fontWeight: 500,
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 2,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    height: '3em',
                                                    fontSize: {
                                                        xs: '0.875rem',
                                                        sm: '1rem',
                                                    },
                                                    lineHeight: 1.5,
                                                }}
                                            >
                                                {product.name}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                component="div"
                                                align="center"
                                                sx={{
                                                    mb: 1,
                                                    fontSize: '1.2rem',
                                                    color: '#5675d1',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                ₹{product.price} /-
                                            </Typography>
                                            <Button
                                                size="small"
                                                startIcon={
                                                    <BookSharp
                                                        sx={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                }
                                                href={product.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                variant="contained"
                                                sx={{
                                                    px: 2,
                                                    py: 1,
                                                    color: '#fff',
                                                    borderRadius: 2,
                                                    textTransform: 'none',
                                                    backgroundColor: 'royalblue',
                                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        textDecoration: 'none',
                                                        color: 'white',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    Buy Now
                                                </Typography>
                                            </Button>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    // If no product, show skeleton
                                    <Card
                                        sx={{
                                            cursor: 'not-allowed',
                                            borderRadius: 2,
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                            height: '100%',
                                        }}
                                    >
                                        <Skeleton variant="rectangular" width="100%" height={180} sx={{ borderTopLeftRadius: 2, borderTopRightRadius: 2 }} />
                                        <CardContent
                                            sx={{
                                                padding: 2,
                                                borderBottom: '1px solid grey',
                                                bgcolor: '#eeeeee73',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Skeleton variant="text" sx={{ marginBottom: 1 }} />
                                            <Skeleton variant="text" width="60%" sx={{ marginBottom: 1 }} />
                                            <Skeleton variant="rectangular" width="100%" height={36} />
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        ))}
                    </Grid>

                    {/* Load More Button */}
                    {visibleProducts < products.length && (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleLoadMore}
                                sx={{
                                    mt: 2,
                                    color: 'white',
                                    backgroundColor: 'royalblue',
                                    '&:hover': {
                                        backgroundColor: '#0056b3',
                                    },
                                }}
                            >
                                Load More
                            </Button>
                        </Box>
                    )}
                </div>
            </Container>

            {/* banner */}
            <Grid container spacing={4} sx={{ display: { xs: "flex", md: "none" }, justifyContent: 'center', p: 2 }}>

                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={4}
                        sx={{
                            padding: '24px',
                            backgroundColor: '#004d40',
                            color: 'white',
                            display: { xs: "none", md: "none" },
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                            }}
                        >
                            Bookshop TNC's Top 5 Most Memorable Moments
                        </Typography>
                        <Typography
                            variant="body1"
                            paragraph
                            sx={{
                                fontSize: '1rem',
                                lineHeight: 1.8,
                            }}
                        >
                            Dive into our unforgettable highlights, featuring exclusive author events, book launches, and exciting community celebrations.
                        </Typography>
                        <Button
                            onClick={() => window.open("https://shop.shubadinam.com/collections/books", "_blank")}
                            variant="contained"
                            sx={{
                                maxWidth: 400,
                                textAlign: "center",
                                backgroundColor: '#0089af',
                                color: 'white',
                                fontWeight: 'bold',
                                '&:hover': { backgroundColor: '#14a37f' },
                            }}
                        >
                            Explore Now
                        </Button>
                        <Box
                            component="img"
                            src="https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid"
                            alt="Stack of books with glasses"
                            sx={{
                                width: '100%',
                                borderRadius: '12px',
                                marginTop: '16px',
                                objectFit: 'cover',
                                height: '220px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={4}
                        sx={{
                            padding: '24px',
                            backgroundColor: '#212121',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                            }}
                        >
                            Big Discount
                        </Typography>
                        <Typography
                            variant="body1"
                            paragraph
                            sx={{
                                fontSize: '1rem',
                                lineHeight: 1.8,
                            }}
                        >
                            Enjoy a massive 50% discount exclusively for our new users. Don’t miss this chance to grab your favorite books at unbeatable prices!
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                maxWidth: 400,
                                textAlign: "center",
                                bgcolor: "#1b6952",
                                color: 'white',
                                fontWeight: 'bold',
                                '&:hover': { backgroundColor: '#14a37f' },
                            }}
                        >
                            Shop Now
                        </Button>
                        <Box
                            component="img"
                            src="https://img.freepik.com/free-vector/bike-guy-wattpad-book-cover_23-2149452163.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid"
                            alt="Books with discount tags"
                            sx={{
                                width: '100%',
                                borderRadius: '12px',
                                marginTop: '16px',
                                objectFit: 'cover',
                                height: '220px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>


            {/* < Container sx={{ mt: 3, mb: 3, p: 2 }}>
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: 'center',
                        marginBottom: 2,
                        fontWeight: 'bold',
                    }}
                >
                    BOOK COLLECTIONS
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'center',
                        marginBottom: 4,
                    }}
                >
                    Explore our handpicked selections ranging from timeless literature to contemporary bestsellers across diverse genres.
                </Typography>

                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    centered
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{ marginBottom: 4 }}
                >
                    <Tab label="New Arrival" />
                    <Tab label="Best Seller" />
                    <Tab label="Featured" />
                </Tabs>

                {activeTab === 0 && renderBooks(bookData.newArrival)}
                {activeTab === 1 && renderBooks(bookData.bestSeller)}
                {activeTab === 2 && renderBooks(bookData.featured)}

                <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                    <Button
                        variant="contained"
                        style={{
                            padding: '16px 48px',
                            borderRadius: '9999px',
                            backgroundColor: '#10b981',
                        }}
                    >
                        Explore All
                    </Button>
                </div>
            </Container > */}

            {/* <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    sx={{
                        textAlign: 'center',
                        marginBottom: 2,
                        fontWeight: 'bold',
                    }}
                >
                    Frequently Asked Questions
                </Typography>
                {faqs.map((faq, index) => (
                    <Accordion key={index} sx={{ mb: 2 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`faq-content-${index}`}
                            id={`faq-header-${index}`}
                        >
                            <Typography variant="h6">{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container> */}

            <FooterComponent />
        </div >
    );
};

export default RoughSix;
