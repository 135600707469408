import { React, useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TryIcon from '@mui/icons-material/Try';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import LogoutIcon from '@mui/icons-material/Logout';
import LockResetIcon from '@mui/icons-material/LockReset';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AdminSettings from './AdminSettings';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useRef } from 'react';
import { ControlledMenu, MenuItemsxhsin, useHover, useMenuState, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/zoom.css';
import { Avatar, Badge, Chip, Container, IconButton, Menu, Typography } from '@mui/material';
import { BookOnlineOutlined, CalendarMonth, CalendarMonthOutlined, DateRangeOutlined, DateRangeTwoTone, Download, ImageSharp, LiveTvOutlined, Mail, Notifications, Person, ReportOutlined, Star, StarBorderOutlined, YouTube, YoutubeSearchedForOutlined } from '@mui/icons-material';



const SDadminHeader = ({ clickPass }) => {

    const [showMenu, setShowMenu] = useState(false);
    const [allNotifications, setAllNotifications] = useState([])
    const [showAll, setShowAll] = useState(false);

    const [showPremium_notify, setPremiumNotify] = useState([]);
    const [showUserDateFinder_notify, setUserDateFinder] = useState([]);
    const [showRelDateFinder_notify, setRelDateFinder] = useState([]);

    const allCombinedNotifications = [
        ...showPremium_notify,
        ...showUserDateFinder_notify,
        ...showRelDateFinder_notify
    ];

    console.log(allCombinedNotifications);

    // Determine the notifications to display
    const visibleNotifications = showAll ? allCombinedNotifications : allCombinedNotifications.slice(0, 5);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 992) {
                setShowMenu(true);
            } else {
                setShowMenu(false);
            }
        };
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await fetch('https://backend.shubadinam.com/admin/notifications');
                const data = await response.json();
                if (data.success) {
                    // setAllNotifications(data.data);
                    setPremiumNotify(data.premiumResults);
                    setUserDateFinder(data.userDateFinder);
                    setRelDateFinder(data.relationDateFinder);
                } else {
                    console.error("Failed to fetch notifications:", data.message);
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchNotifications();
    }, []);

    console.log(allNotifications);


    const navigate = useNavigate();

    const handleChange = () => {
        sessionStorage.removeItem("adminId")
        navigate("/admin")
    }

    const checkRouting = (routes) => {
        // console.log(routes);
        navigate(`/${routes}`)
    }


    const [showDropdown, setShowDropdown] = useState(false);
    const [showSettingsDropdown, setshowSettingsDropdown] = useState(false);
    const [showReportDropdown, setshowReportDropdown] = useState(false);

    const [showUsersDropdown, setShowUsersDropdown] = useState(false);
    const [showPanchangamDropdown, setShowPanchangamDropdown] = useState(false);
    const [showTithiDropdown, setShowTithiDropdown] = useState(false);
    const [showVisitorsDropdown, setShowVisitorsDropdown] = useState(false);


    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const toggleUsersDropdown = () => {
        setShowUsersDropdown(!showUsersDropdown);
    };

    const toggleVisitorsDropdown = () => {
        setShowVisitorsDropdown(!showVisitorsDropdown);
    };


    const togglePanchangamDropdown = () => {
        setShowPanchangamDropdown(!showPanchangamDropdown);
    };

    const toggleTithiDropdown = () => {
        setShowTithiDropdown(!showTithiDropdown);
    };

    const toggleSettingsDropdown = () => {
        setshowSettingsDropdown(!showSettingsDropdown);
    };

    const toggleReportDropdown = () => {
        setshowReportDropdown(!showReportDropdown);
    };


    const handlePanchangam = (headerlink) => {
        // console.log(headerlink);
        if (headerlink === "manage-customer") {
            navigate('/manage-customer');
            return;
        }
        else if (headerlink === "inbox") {
            navigate('/contact/inbox');
            return;
        }
        else if (headerlink === "sent-items") {
            navigate('/contact/sent-items');
            return;
        }
        else if (headerlink === "monthly-billings") {
            navigate('/monthly-billings');
            return;
        }
        navigate(`/admin-database/${headerlink}`)
    }

    const handleRouting = (headerlink) => {
        navigate(`/admin/${headerlink}`)
    }


    const [open, setOpen] = useState(false);


    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleManage = (page) => {
        navigate(`/${page}`)
    }
    const handleBillings = () => {
        navigate('/monthly-billings')
    }

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <ListItem onClick={() => checkRouting("adminpage")} >
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>


            </List>
            <Divider />
            <p className='admin-mob-title'>Users</p>
            <List>
                <ListItem onClick={() => handlePanchangam("total-customers")}>
                    <ListItemIcon>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Total Users" />
                </ListItem>

                <ListItem onClick={() => handlePanchangam("premiumusers")}>
                    <ListItemIcon>
                        <WorkspacePremiumIcon />
                    </ListItemIcon>
                    <ListItemText primary="Premium Users" />
                </ListItem>

                <ListItem onClick={() => handlePanchangam("non-premiumusers")}>
                    <ListItemIcon>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Non Premium Users" />
                </ListItem>
            </List>

            <Divider />
            <p className='admin-mob-title'>Visitors</p>
            <List>
                <ListItem onClick={() => handlePanchangam("signup-visitors")}>
                    <ListItemIcon>
                        <TryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Signup Visitors" />
                </ListItem>

                <ListItem onClick={() => handlePanchangam("login-visitors")}>
                    <ListItemIcon>
                        <TryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Login Visitors" />
                </ListItem>

                <ListItem onClick={() => handlePanchangam("premium-visitors")}>
                    <ListItemIcon>
                        <TryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Premium Visitors" />
                </ListItem>

                <ListItem onClick={() => handlePanchangam("homam-visitors")}>
                    <ListItemIcon>
                        <TryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Homam Visitors" />
                </ListItem>
            </List>

            <Divider />
            <p className='admin-mob-title'>Reports</p>
            <List>
                <ListItem button onClick={() => handleManage("manage-customer")}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Customers" />
                </ListItem>
                <ListItem button onClick={() => handleChange()}>
                    <ListItemIcon>
                        <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary="Date Finder" />
                </ListItem>
                {/* <ListItem button onClick={() => handleBillings()}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Billings" />
                </ListItem> */}
            </List>

            <Divider />
            <p className='admin-mob-title'>Contact</p>
            <List>
                <ListItem button onClick={() => handlePanchangam("inbox")}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inbox" />
                </ListItem>
                <ListItem button onClick={() => handlePanchangam("sent-items")}>
                    <ListItemIcon>
                        <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sent Items" />
                </ListItem>
            </List>

            <Divider />
            <p className='admin-mob-title'>Database</p>
            <List>
                <ListItem onClick={() => window.location.href = "/admin-database/upload-panchangam"}>
                    <ListItemIcon>
                        <EditCalendarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upload Panchangam " />
                </ListItem>
                <ListItem onClick={() => window.location.href = "/admin-database/upload-tithi"}>
                    <ListItemIcon>
                        <EditCalendarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upload Tithi " />
                </ListItem>

            </List>




            <Divider />
            <p className='admin-mob-title'>Settings</p>
            <List>
                <ListItem button onClick={clickPass}>
                    <ListItemIcon>
                        <LockResetIcon />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                </ListItem>
                <ListItem button onClick={() => handleChange()}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Log Out" />
                </ListItem>
            </List>
        </Box>
    );

    const ref = useRef(null);
    const [menuState, toggle] = useMenuState({ transition: true });
    const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

    const panchangams = [
        { id: "tamilpanchangam", label: "Tamil Panchangam" },
        { id: "telugupanchangam", label: "Telugu Panchangam" },
        { id: "kannadampanchangam", label: "Kannada Panchangam" },
        { id: "malayalampanchangam", label: "Malayalam Panchangam" },
        { id: "hindipanchangam", label: "Hindi Panchangam" },
        { id: "gujaratipanchangam", label: "Gujarati Panchangam" },
        { id: "bengalipanchangam", label: "Bengali Panchangam" },
        { id: "marathipanchangam", label: "Marathi Panchangam" },
    ];

    const tithis = [
        { id: "tamiltithi", label: "Tamil Tithi" },
        { id: "telugutithi", label: "Telugu Tithi" },
        { id: "kannadamtithi", label: "Kannada Tithi" },
        { id: "malayalamtithi", label: "Malayalam Tithi" },
        { id: "hinditithi", label: "Hindi Tithi" },
        { id: "gujaratitithi", label: "Gujarati Tithi" },
        { id: "bengalitithi", label: "Bengali Tithi" },
        { id: "marathitithi", label: "Marathi Tithi" },
    ];

    const adminOperations = [
        { id: "upload-youtube-videos", label: "Upload Youtube Videos", icon: (<YouTube sx={{ mr: 2 }} />) },
        { id: "create-premium", label: "Create Premium", icon: (<Star sx={{ mr: 2 }} />) },
        { id: "generate-post", label: "Generate Daily Post", icon: (<ImageSharp sx={{ mr: 2 }} />) },
        { id: "compose-email", label: "Compose Email", icon: (<Mail sx={{ mr: 2 }} />) },

    ];


    const [notification_anchorEl, setNotification_anchorEl] = useState(null);


    const handleNotificationClick = (event) => {
        setNotification_anchorEl(event.currentTarget); // Open the menu below the bell icon
    };

    const handleNotificationClose = () => {
        setNotification_anchorEl(null); // Close the menu
    };

    const handleNotification = (notification) => {
        console.log(notification);

        // Define the mapping of conditions to URLs
        const navigationMap = {
            premiumId: "admin-database/premiumusers",
            userDate: "admin-database/date-finder",
            relDate: "admin-database/date-finder",
        };

        // Check conditions and navigate accordingly
        if (notification.premiumId) {
            window.location.href = navigationMap.premiumId;
        } else if (notification.userDate === "Will Update Soon") {
            window.location.href = navigationMap.userDate;  // Since both "userDate" and "relDate" use the same URL

        } else if (notification.relDate === "Will Update Soon") {
            window.location.href = navigationMap.userDate;  // Since both "userDate" and "relDate" use the same URL
        }
    };





    return (
        <header className="sd-header">

            {
                showMenu ?
                    <>
                        <img src={require("../../src/images/New Shubadinam Logo/Shubadinam White.png")} width={150} height={40} />
                        {/* <h3 style={{ color: "#a7a7a7" }}>Admin Panel</h3> */}

                        <div style={{ marginRight: "20px" }}>
                            <MenuIcon onClick={toggleDrawer(true)}></MenuIcon>
                            <Drawer open={open} onClose={toggleDrawer(false)}>
                                {DrawerList}
                            </Drawer>
                        </div>
                    </>
                    :
                    <>

                        <label style={{ color: "#a7a7a7" }}>Admin Panel</label>

                        <button className='sd-header-items' onClick={() => checkRouting("adminpage")}>Dashboard</button>



                        <nav className="sd-header-items">
                            <ol className="sd-header-nav-list">
                                <li className="sd-header-nav-item" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
                                    Reports
                                    < ArrowDropDownIcon />
                                    {showDropdown && (
                                        <div className="sd-header-dropdown">
                                            <div >
                                                <li className="sd-header-dropdown-item" onMouseEnter={toggleUsersDropdown} onMouseLeave={toggleUsersDropdown}>
                                                    Users
                                                    < ArrowRightIcon />
                                                    {showUsersDropdown && (
                                                        <ol className="sd-header-sub-dropdown">
                                                            <li className="sd-header-sub-dropdown-item" onClick={() => handlePanchangam("total-customers")}>Total Users</li>
                                                            <li className="sd-header-sub-dropdown-item" onClick={() => handlePanchangam("premiumusers")}>Premium Users</li>
                                                            <li className="sd-header-sub-dropdown-item" onClick={() => handlePanchangam("non-premiumusers")}>Non Premium Users</li>

                                                        </ol>
                                                    )}
                                                </li>
                                                <li className="sd-header-dropdown-item" onMouseEnter={toggleVisitorsDropdown} onMouseLeave={toggleVisitorsDropdown}>
                                                    Visitors
                                                    < ArrowRightIcon style={{ alignSelf: "end" }} />
                                                    {showVisitorsDropdown && (
                                                        <ol className="sd-header-sub-dropdown">
                                                            <li className="sd-header-sub-dropdown-item" onClick={() => handlePanchangam("signup-visitors")}>Signup Visitors</li>
                                                            <li className="sd-header-sub-dropdown-item" onClick={() => handlePanchangam("login-visitors")}>Login Visitors</li>
                                                            <li className="sd-header-sub-dropdown-item" onClick={() => handlePanchangam("premium-visitors")}>Premium Visitors</li>
                                                            <li className="sd-header-sub-dropdown-item" onClick={() => handlePanchangam("homam-visitors")}>Homam Visitors</li>
                                                        </ol>
                                                    )}
                                                </li>
                                                {/* <li className="sd-header-sub-dropdown-item" onClick={() => handlePanchangam("monthly-billings")}>Billings</li> */}


                                            </div>
                                        </div>

                                    )}
                                </li>
                            </ol>
                        </nav>

                        <button className='sd-header-items' onClick={() => handlePanchangam("manage-customer")}>Manage Customer</button>

                        <>
                            <div ref={ref} {...anchorProps} style={{ cursor: "pointer", }}>
                                Show All Menu  <ArrowDropDownIcon />
                            </div>


                            <ControlledMenu
                                menuClassName="lap-mega-menu"
                                {...hoverProps}
                                {...menuState}
                                anchorRef={ref}
                                onClose={() => toggle(false)}
                            >
                                <Box className='lap-megamnu-container'>

                                    <Box sx={{ backgroundColor: "white", width: "300px", }}>
                                        <Typography variant='h6' sx={{ marginBottom: "10px", marginLeft: "10px", fontFamily: "cursive", bgcolor: "#ebebeb" }}>Database</Typography>
                                        <MenuItem label="Panchangam" onClick={() => window.location.href = "/admin-database/upload-panchangam"}>
                                            <CalendarMonthOutlined sx={{ mr: 2 }} />  Upload Panchangam Database
                                        </MenuItem>
                                        <MenuItem label="Panchangam" onClick={() => window.location.href = "/admin-database/upload-tithi"}>
                                            <CalendarMonthOutlined sx={{ mr: 2 }} />  Upload Tithi Database
                                        </MenuItem>

                                        <br />
                                        <Typography variant='h6' sx={{ marginBottom: "10px", marginLeft: "10px", fontFamily: "cursive", bgcolor: "#ebebeb" }}>Operations</Typography>
                                        <MenuItem onClick={() => handlePanchangam("date-finder")}>Date Finder</MenuItem>
                                    </Box>

                                    <Box sx={{ backgroundColor: "white", width: "300px", }}>
                                        <Typography variant='h6' sx={{ marginBottom: "10px", marginLeft: "10px", fontFamily: "cursive", bgcolor: "#ebebeb" }}>Homam Details</Typography>
                                        <MenuItem onClick={() => handleManage("homam-admin")}>
                                            <LiveTvOutlined sx={{ mr: 2 }} />   Homam live update
                                        </MenuItem>
                                        <MenuItem onClick={() => handleManage("homam-purchased-report")}>
                                            <ReportOutlined sx={{ mr: 2 }} />   Homam Purchased Report
                                        </MenuItem>
                                        <MenuItem onClick={() => handleManage("homam-customer-bookings")}>
                                            <BookOnlineOutlined sx={{ mr: 2 }} />   Homam Monthly Bookings
                                        </MenuItem>

                                        <br />
                                        <Typography variant='h6' sx={{ marginBottom: "10px", marginLeft: "10px", fontFamily: "cursive", bgcolor: "#ebebeb" }}>Database</Typography>
                                        <MenuItem onClick={() => handlePanchangam("date-finder")}>Date Finder</MenuItem>
                                    </Box>

                                    <Box sx={{ backgroundColor: "white", width: "300px", }}>
                                        <Typography variant='h6' sx={{ marginBottom: "10px", marginLeft: "10px", fontFamily: "cursive", bgcolor: "#ebebeb" }}>Operations</Typography>

                                        {adminOperations.map((operations) => (
                                            <MenuItem
                                                key={operations.id}
                                                onClick={() => handleRouting(operations.id)}
                                            >
                                                {operations.icon}    {operations.label}
                                            </MenuItem>
                                        ))}

                                        <br />
                                        <Typography variant='h6' sx={{ marginBottom: "10px", marginLeft: "10px", fontFamily: "cursive", bgcolor: "#ebebeb" }}>Download</Typography>
                                        <MenuItem onClick={() => handleManage("admin/download")}> <Download sx={{ mr: 2 }} /> Download</MenuItem>
                                    </Box>


                                </Box>

                            </ControlledMenu>
                        </>


                        <ol
                            className="sd-header-nav-list"
                            onMouseEnter={() => toggleSettingsDropdown(true)}
                            onMouseLeave={() => toggleSettingsDropdown(false)}
                        >
                            <li className="sd-header-nav-item">
                                Contact
                                < ArrowDropDownIcon />
                                {showSettingsDropdown && (
                                    <div className="sd-header-dropdown-settings">
                                        <div style={{ listStyle: "none", textAlign: "start" }}>
                                            <li className="sd-header-dropdown-item" onClick={() => handlePanchangam("inbox")}> Inbox </li>
                                            <li className="sd-header-dropdown-item" onClick={() => handlePanchangam("sent-items")}> Sent Items </li>
                                        </div>
                                    </div>
                                )}
                            </li>

                        </ol>


                        <ol className="sd-header-nav-list">
                            <li className="sd-header-nav-item" onMouseEnter={toggleSettingsDropdown} onMouseLeave={toggleSettingsDropdown}>
                                Settings
                                <AccountCircleIcon style={{ marginLeft: "5px" }} />
                                {showSettingsDropdown && (
                                    <div className="sd-header-dropdown-settings">
                                        <div style={{ listStyle: "none", textAlign: "start" }}>
                                            <li className="sd-header-dropdown-item" onClick={clickPass}> Change Password </li>
                                            <li className="sd-header-dropdown-item" onClick={() => handleChange()}> Log Out </li>
                                        </div>
                                    </div>
                                )}
                            </li>

                        </ol>

                        <div>
                            {/* Bell Icon */}
                            <IconButton onClick={handleNotificationClick} color="primary">
                                <Badge badgeContent={allCombinedNotifications && allCombinedNotifications.length} color="info">
                                    <Notifications sx={{ color: "white" }} />
                                </Badge>
                            </IconButton>

                            {/* Notification Pop-up Menu */}
                            <Menu
                                anchorEl={notification_anchorEl}
                                open={Boolean(notification_anchorEl)}
                                onClose={handleNotificationClose}
                                PaperProps={{
                                    style: {
                                        width: 250,
                                        maxHeight: 500,
                                        overflow: "auto"
                                    },
                                }}
                            >
                                {allCombinedNotifications.length === 0 ? (
                                    <MenuItem>
                                        <Typography variant="body2">No new notifications</Typography>
                                    </MenuItem>
                                ) : (
                                    <Box>
                                        <Box
                                            sx={{
                                                maxHeight: showAll ? "none" : 300, // Restrict height to 300px if not showing all
                                                overflow: "auto", // Enable scroll for overflow
                                                padding: 1,
                                            }}
                                        >
                                            {visibleNotifications.map((notification) => (
                                                <div key={notification.id}>
                                                    <MenuItem >
                                                        <Box display="flex" alignItems="center" width="100%" onClick={() => handleNotification(notification)}>
                                                            {/* Avatar or Icon */}
                                                            <Avatar
                                                                sizes="small"
                                                                sx={{
                                                                    height: 24,
                                                                    width: 24,
                                                                    bgcolor: "primary.main",
                                                                    mr: 2,
                                                                }}
                                                            >
                                                                {notification.premiumId ? <Star /> : <CalendarMonth />}
                                                            </Avatar>

                                                            {/* Notification Details */}
                                                            <Box flex={1}>
                                                                <Typography variant="subtitle1" fontWeight="bold">
                                                                    {notification.userName} {notification.relName}
                                                                    {
                                                                        notification.premiumId && <Chip label="Premium" size='small' color='info' />
                                                                    }


                                                                </Typography>
                                                                {notification.premiumId ? (
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {notification.userState} - {new Date(notification.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography variant="body2" sx={{ color: "brown" }}>
                                                                        Date Finder Pending...
                                                                    </Typography>
                                                                )}

                                                            </Box>



                                                        </Box>
                                                    </MenuItem>
                                                    <Divider />
                                                </div>
                                            ))}
                                        </Box>

                                        {/* View More Button */}
                                        {allNotifications.length > 5 && (
                                            <Box textAlign="center" mt={1}>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => setShowAll(!showAll)}
                                                >
                                                    {showAll ? "Show Less" : "View More"}
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>

                                )}
                            </Menu>
                        </div>

                    </>
            }


        </header>



    );
};

export default SDadminHeader;