import React from "react";
import { useEffect } from "react";
import FooterComponent from "../components/footer";
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import ScrollToTop from "react-scroll-to-top";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Grid, Typography, List, ListItem, ListItemText } from "@mui/material";
import SlidingHeader from "../components/SlidingHeader";

const tithiDeityData = [
    { tithi: 'Pratipada', deity: 'Agni (the fire deity)' },
    { tithi: 'Dvitiya', deity: 'Brahma (the creator deity)' },
    { tithi: 'Tritiya', deity: 'Gauri (an aspect of Parvati, the goddess of fertility and nourishment)' },
    { tithi: 'Chaturthi', deity: 'Yama (the god of death and justice)' },
    { tithi: 'Panchami', deity: 'Saraswati (the goddess of knowledge and arts)' },
    { tithi: 'Shashthi', deity: 'Kartikeya (the god of war and victory)' },
    { tithi: 'Saptami', deity: 'Surya (the sun god)' },
    { tithi: 'Ashtami', deity: 'Durga (the goddess of power and protection)' },
    { tithi: 'Navami', deity: 'Indra (the king of gods)' },
    { tithi: 'Dashami', deity: 'Dashavatara (the ten avatars of Lord Vishnu)' },
    { tithi: 'Ekadashi', deity: 'Rudra (an aspect of Shiva, the god of destruction)' },
    { tithi: 'Dvadashi', deity: 'Vishnu (the preserver deity)' },
    { tithi: 'Trayodashi', deity: 'Kamadeva (the god of love and desire)' },
    { tithi: 'Chaturdashi', deity: 'Shiva (the god of transformation and destruction)' },
    { tithi: 'Purnima', deity: 'Devi (the divine goddess)' },
];


const BoxPage7 = () => {

    useEffect(() => {
        AOS.refresh();
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);


    return (

        <HelmetProvider>
            <Helmet>
                <title>Tithi Deities</title>
                <meta name="description" content="Tithi Deities - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <SlidingHeader />
            <Container maxWidth="lg">
                <ScrollToTop smooth />

                <Box
                    mt={15}
                    sx={{
                        width: '100%',
                        height: '200px',
                        backgroundImage: `url(${require("../images/boxesImages/char2.jpg")})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '8px'
                    }}
                    onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                />



                <Grid container spacing={2} sx={{ mt: 2 }}>

                    <Grid item xs={12} md={8}>
                        <Box sx={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>

                            <Typography variant="h4" gutterBottom>Tithi Deities</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>In Vedic astrology and Hindu tradition, each Janma Tithi is associated with a presiding deity. These deities are believed to influence the qualities and attributes of individuals born on that particular Tithi.
                            </Typography>
                            <Typography variant="h6" gutterBottom>Here is a list of the Janma Tithis along with their corresponding presiding deities:</Typography>

                            <Box sx={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
                                <Grid container spacing={4}>
                                    {tithiDeityData.map((item, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={4}>
                                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3, borderRadius: 2 }}>
                                                <CardContent>
                                                    <Typography variant="h5" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                        {item.tithi}
                                                    </Typography>
                                                    <Typography variant="body1" color="textSecondary">
                                                        {item.deity}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                margin: '20px 0',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                transition: '0.3s',
                                '&:hover': {
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                },

                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Related Searches
                                </Typography>
                                <List>
                                    {[
                                        { path: '/nakshatra-foods', label: 'Nakshatra Foods' },
                                        { path: '/nakshatra-characteristics', label: 'Nakshatra Characteristics' },
                                        { path: '/nakshatra-deities', label: 'Nakshatra Deities' },
                                        { path: '/nakshatra-likes-dislikes', label: 'Nakshatra Likes and Dislikes' },
                                        { path: '/tithi-foods', label: 'Tithi Foods' },
                                        { path: '/tithi-characteristics', label: 'Tithi Characteristics' },
                                        { path: '/tithi-deities', label: 'Tithi Deities' },
                                        { path: '/tithi-likes-dislikes', label: 'Tithi Likes and Dislikes' },
                                    ].map((item) => (
                                        <ListItem
                                            key={item.path}
                                            component={NavLink} // Make the ListItem act like a NavLink
                                            to={item.path} // Set the path for navigation
                                            sx={{
                                                padding: '10px 0',
                                                textDecoration: 'none', // Removes the underline from NavLink
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                    cursor: 'pointer', // Changes the cursor on hover
                                                },
                                            }}
                                        >
                                            <ListItemText sx={{}} primary={item.label} />
                                        </ListItem>
                                    ))}
                                </List>

                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </Container>



            <FooterComponent />
        </HelmetProvider>

    )
}
export default BoxPage7;