import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ProtectedRoute = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        let token;

        const checkToken = async () => {
            token = localStorage.getItem("token");
            if (!token) {
                await new Promise(resolve => setTimeout(resolve, 100)); // Delay
            }
            return localStorage.getItem("token");
        };

        checkToken().then(token => {
            if (!token) {
                Swal.fire({
                    icon: "warning",
                    title: "Access Denied",
                    text: "Kindly login again."
                });
                navigate("/");
            }
        });

        const fetchProtectedData = async () => {
            try {
                const response = await fetch("https://backend.shubadinam.com/protected-route", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = await response.json();

                if (!response.ok) {

                    // Log specific message from the response
                    Swal.fire({
                        icon: "info",
                        title: "Authorization",
                        text: data.message
                    });
                    localStorage.clear();
                    navigate("/signin")
                }

                setUserData(data.user);
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.message
                });
                console.log("111");
                localStorage.clear();
                navigate("/signin"); // Redirect to login if token validation fails
            }
        };

        fetchProtectedData();
    }, [navigate]);

    return userData ? React.cloneElement(children, { userData }) : null;
};

export default ProtectedRoute;
