import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, IconButton, Slide, Button, Grid } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CancelIcon from "@mui/icons-material/Cancel";
import SlidingHeader from "../../components/SlidingHeader";

const dummyVideos = [
  { id: 1, title: "Short 1", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { id: 2, title: "Short 2", url: "https://www.w3schools.com/html/movie.mp4" },
  { id: 3, title: "Short 3", url: "https://www.w3schools.com/html/mov_bbb.mp4" },
];

const RoughThree = () => {

  const sharePanelRef = useRef();

  const [likedVideos, setLikedVideos] = useState([]);
  const [showSharePanel, setShowSharePanel] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const toggleLike = (id) => {
    setLikedVideos((prev) =>
      prev.includes(id) ? prev.filter((vid) => vid !== id) : [...prev, id]
    );
  };

  const handleShareClick = (url) => {
    setShowSharePanel(true);
    setShareUrl(url);
  };

  const handleCloseSharePanel = () => {
    setShowSharePanel(false);
    setShareUrl("");
  };

  // Detect outside click to close the Slide
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sharePanelRef.current && !sharePanelRef.current.contains(event.target)) {
        setShowSharePanel(false);
        setShareUrl("");
      }
    };

    if (showSharePanel) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showSharePanel]);

  return (
    <Box>
      <SlidingHeader />
      <Box
        sx={{
          mt: { xs: 10, md: 10 },
          bgcolor: { xs: "white", md: "white" },
          width: "100%",
          height: { xs: "80vh", md: "100vh" },
          overflowY: "scroll",
          scrollSnapType: "y mandatory",
        }}
      >
        {dummyVideos.map((video) => (
          <Box
            key={video.id}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              scrollSnapAlign: "start",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "360px",
                position: "relative",
                overflow: "hidden",
                borderRadius: "16px",
                boxShadow: 3,
                "&::before": {
                  content: '""',
                  display: "block",
                  paddingBottom: "177.78%", // 16:9 aspect ratio
                },
              }}
            >
              <video
                src={video.url}
                controls
                autoPlay
                loop
                muted
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Typography
                sx={{
                  position: "absolute",
                  bottom: 16,
                  left: 16,
                  color: "#fff",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {video.title}
              </Typography>

              {/* Share and Like Buttons */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: "100px !important",
                  right: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {/* Like Button */}
                <IconButton
                  sx={{
                    color: likedVideos.includes(video.id) ? "red" : "white",
                  }}
                  onClick={() => toggleLike(video.id)}
                >
                  {likedVideos.includes(video.id) ? (
                    <FavoriteIcon />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>

                {/* Share Button */}
                <IconButton
                  sx={{ color: "white" }}
                  onClick={() => handleShareClick(video.url)}
                >
                  <ShareIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>


      {/* Background Overlay */}
      {showSharePanel && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
            zIndex: 9,
          }}
          onClick={handleCloseSharePanel} // Allow click to close the panel
        />
      )}
      {/* Slide-in Sharing Panel */}
      <Slide direction="up" in={showSharePanel} mountOnEnter unmountOnExit>

        <Box ref={sharePanelRef}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            bgcolor: "white",
            boxShadow: 4,
            p: 2,
            zIndex: 10,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            Share via
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              mb: 2,
            }}
          >
            {/* WhatsApp Icon */}
            <IconButton
              onClick={() =>
                window.open(
                  `https://wa.me/?text=Check this video: ${shareUrl}`,
                  "_blank"
                )
              }
              sx={{ color: "#25D366" }}
            >
              <WhatsAppIcon fontSize="large" />
            </IconButton>

            {/* Email Icon */}
            <IconButton
              onClick={() =>
                window.open(
                  `mailto:?subject=Check this Shubadinam Shorts&body=${shareUrl}`,
                  "_blank"
                )
              }
              sx={{ color: "#D44638" }}
            >
              <EmailIcon fontSize="large" />
            </IconButton>
          </Box>

          {/* Cancel Button */}
          <Grid container item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCloseSharePanel}
              color="inherit"
              sx={{ maxWidth: 400 }}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Slide >
    </Box >
  );
};

export default RoughThree;
