import React, { useEffect } from "react";
import FooterComponent from "../components/footer";
import ScrollToTop from "react-scroll-to-top";
import Top10px from "./Top10px";
import BottomSpace from "./BottomSpace";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LandingHeader from "../components/LandingHeader";
import { Grid, Box, Typography, Button, Card, Chip, CardMedia, CardContent, Container } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import { ArrowCircleRightSharp, Book, BookOnline, CardGiftcard, GifTwoTone } from '@mui/icons-material';
import { Gift, Event, Star, ShoppingCart } from '@mui/icons-material';
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";
import HomamEvents from "../components/HomamEvents";
import Services from "./utils/services";


const AllHomams = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <Box>
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>All Services </title>
                    <meta name="description" content="Services - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>

            <SlidingHeader />

            <Box
                sx={{
                    mt: 10,
                    // background: 'linear-gradient(to bottom, #fdfbfb, #ebedee)',
                    py: 4,
                    minHeight: '100vh',
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            color: '#2c3e50',
                            mb: 2,
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        Exclusive Services
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{
                            color: '#7f8c8d',
                            mb: 4,
                            fontStyle: 'italic',
                        }}
                    >
                        Explore our services and exclusive products to enrich your life
                    </Typography>

                    <Services />
                </Container>
            </Box>



            <FooterComponent />
        </Box>
    );
};

export default AllHomams;
