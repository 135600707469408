import React, { useState, useRef } from 'react';
import {
    Container,
    Box,
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    IconButton,
    CardMedia,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SevalandingTestimonial from '../homamutils/SevalandingTestimonial';
import { Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterComponent from '../components/footer';
import { NavLink, useNavigate } from 'react-router-dom';
import SlidingHeader from '../components/SlidingHeader';


const whySection = [
    {
        id: 1,
        headline: "Hassle-Free Booking:",
        text: "Easily book puja seva slots for yourself, your family, and friends in just a few simple steps.",
        image:
            "https://storage.googleapis.com/a1aa/image/wISGxGtiOb66NRxTHRW0kcuUVIhuqPLtD7gtfNCAAqIfQk2TA.jpg",
        alt: "Icon of a light bulb representing an idea",
    },
    {
        id: 2,
        headline: "Convenient Viewing:",
        text: "Participate in the puja seva live from your home or anywhere, alongside your family and friends.",
        image:
            "https://storage.googleapis.com/a1aa/image/tLfxzsgEccyJHayOsOQ1EYYzrBIMp6DbxXCiTfy5Fok7Qk2TA.jpg",
        alt: "Icon of a gear representing settings",
    },
    {
        id: 3,
        headline: "Sacred Prasads Delivered:",
        text: "Receive the divine prashads as applicable at your doorstep after the completion of the puja seva.",
        image:
            "https://storage.googleapis.com/a1aa/image/K6sYVznh8TJ6L11VsBSwBmgRCyMJeQwFYW1DCVHZbrCeQk2TA.jpg",
        alt: "Icon of a location pin representing a place",
    },
];

const services = [
    {
        title: 'Avahanti Homam',
        description: 'A powerful puja to eliminate barriers and foster success for students, business professionals, and individuals...',
        imgUrl: require("../images/thumbnails/ahavanti homam.jpg"),
        buttonText: 'Avahanti-Homam',
        bgColor: '#007aff17',
    },
    {
        title: 'Ganapathi Homam',
        description: 'A powerful puja to invoke the blessings of Lord Ganesha, the remover of obstacles and the lord of beginnings...',
        imgUrl: require("../images/thumbnails/gnapathi homam.jpg"),
        buttonText: 'Ganapathi-Homam',
        bgColor: '#f3a7693b',

    },
    {
        title: 'Surya Namaskar',
        description: 'A powerful puja to ensure health, wealth, prosperity and Provides relief from sins and fears...',
        imgUrl: require("../images/thumbnails/suryanamasakar.jpg"),
        buttonText: 'Surya-Namaskaram',
        bgColor: '#f1f8e9',
    },
];

const FAQs = [
    {
        question: 'What is Avahanti virtual seva?',
        answer:
            'Avahanti is a virtual seva that involves online prayers and rituals conducted by priests, allowing devotees to participate remotely. It is designed to bring divine blessings through dedicated chanting and prayers.',
    },
    {
        question: 'How can I book Ganapati virtual seva?',
        answer:
            'To book Ganapati virtual seva, you can visit our website and select the desired date and time. Once booked, a dedicated priest will perform the Ganapati puja online, and you will be notified with the details.',
    },
    {
        question: 'What is Surya Namaskar virtual seva?',
        answer:
            'Surya Namaskar virtual seva is an online spiritual service that focuses on chanting and performing Surya Namaskar (sun salutations) while seeking divine blessings. It can be done from the comfort of your home through a live stream.',
    },
    {
        question: 'Can I participate in multiple virtual sevas?',
        answer:
            'Yes, you can participate in multiple virtual sevas, including Avahanti, Ganapati, Surya Namaskar, and more. You just need to select the services and dates from the available options on our website.',
    },
];



const VirtualsevaInstructions = () => {

    const slotRef = useRef(null);

    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        setIsPlaying(true);
    };

    const handleScroll = () => {
        if (slotRef.current) {
            slotRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };


    return (
        <div style={{ backgroundColor: "#fbf7f1" }}>

            {/* Hero Section */}
            <Box
                sx={{
                    height: { xs: "auto", md: "100vh" },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    padding: { xs: '20px', sm: '40px', md: '60px' },
                    background: "linear-gradient(200deg, #f3e6d5, #ffffff)",

                }}
            // background: 'linear-gradient(to right, #38a1f7, #4b769f)',

            >

                <Box>
                    <SlidingHeader />
                </Box>

                {/* Left Content */}
                <Box
                    sx={{
                        width: { xs: '100%', sm: '50%' },
                        padding: '20px',
                        zIndex: 2,
                        mt: { xs: 8, sm: 2, md: 0 },
                        textAlign: 'left',
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            color: "#613c12",
                            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                            fontWeight: 'bold',
                            marginBottom: '15px',
                        }}
                    >
                        Virtual Puja Seva
                    </Typography>
                    <Typography
                        variant="body5"
                        component="div"
                        sx={{
                            marginBottom: '20px',
                            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                        }}
                    >
                        Welcome to our Virtual Seva! Participate in sacred rituals and spiritual practices conducted by Vedic scholars and students at our Veda Patashala. These rituals are streamed live on our <a href="/shubadinam-tv">shubadinam.tv</a>.
                    </Typography>
                    <Button
                        onClick={handleScroll}
                        variant="contained"
                        sx={{
                            // backgroundColor: '#38a1f7',
                            color: '#fff',
                            textTransform: "none",
                            padding: { xs: '5px 20px', sm: '12px 25px', md: '12px 30px' },
                            fontSize: { xs: '1rem', sm: '1.05rem', md: '1.1rem' },
                            borderRadius: 2,
                            '&:hover': {
                                backgroundColor: '#4b769f',
                            },
                        }}
                    >
                        Book Your Seva Now
                    </Button>
                </Box>

                {/* Right YouTube Video */}
                <Box onClick={handlePlayClick}
                    sx={{
                        cursor: "pointer",
                        width: { xs: '100%', sm: '50%' },
                        padding: { xs: '10px', sm: '20px' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        borderRadius: '8px', // Add rounded corners for a modern look
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                        overflow: 'hidden', // Ensures that the image and iframe are clipped to rounded corners
                        backgroundImage: !isPlaying
                            ? `url('${require("../images/thumbnails/virtual pooja thumnail.jpg")}')`
                            : 'none', // Set the background image when not playing
                        backgroundSize: 'cover', // Ensure the image covers the entire container
                        backgroundPosition: { xs: "right", md: 'center' }, // Keep the background centered
                        backgroundRepeat: 'no-repeat', // Prevent image repetition
                        height: '315px', // Set a fixed height for the container
                        transition: 'background 0.3s ease', // Smooth transition for the background change
                    }}
                >
                    {/* Play Button */}
                    {!isPlaying && (
                        <IconButton
                            size='large'
                            sx={{
                                position: 'absolute',
                                zIndex: 3,
                                fontSize: '60px', // Larger play button
                                color: '#fff',
                                backgroundColor: "black",
                                opacity: 0.8,
                                transition: 'opacity 0.3s ease',
                                '&:hover': {
                                    opacity: 1, // On hover, make the play button fully visible
                                },
                            }}
                            onClick={handlePlayClick}
                        >
                            <PlayArrowIcon />
                        </IconButton>
                    )}

                    {/* Video Iframe */}
                    {isPlaying && (
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/sflUxCfy7u4?autoplay=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ borderRadius: '8px' }} // Keep iframe with rounded corners
                        ></iframe>
                    )}
                </Box>
            </Box>

            {/* Services Section */}
            <Container maxWidth="lg" sx={{ mt: 4 }} ref={slotRef}>
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: 'center',
                        fontSize: '2rem',
                        fontWeight: '700',
                        // marginBottom: '10px',
                        color: '#2264ae',
                        lineHeight: 1.3,
                    }}
                >
                    Ritual Offerings
                </Typography>
                <Typography
                    component="div"
                    variant="body1"
                    sx={{
                        color: "GrayText",
                        textAlign: 'center',
                    }}
                >
                    Explore our wide range of spiritual services designed to bring peace, prosperity, and well-being into your life.
                </Typography>


                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '30px', flexWrap: 'wrap', mt: 3 }}>
                    {services.map((service, index) => (
                        <a href={`/virtual-seva/${service.buttonText}`} style={{ textDecoration: "none" }}>
                            <Card
                                key={index}
                                elevation={3}
                                sx={{
                                    maxWidth: '300px',
                                    padding: 2,
                                    textAlign: 'center',
                                    backgroundColor: service.bgColor,
                                    borderRadius: '15px',
                                    cursor: "pointer"
                                }}
                                data-aos="fade-up"
                                data-aos-delay={200 * index}
                            >
                                <img
                                    src={service.imgUrl}
                                    alt={service.title}
                                    style={{
                                        width: '100%',
                                        borderRadius: '8px',
                                        marginBottom: '20px',
                                    }}
                                />
                                <Typography variant="body1" sx={{ fontWeight: '600', }}>
                                    {service.title}
                                </Typography>
                                <Typography variant="subtitle2" sx={{ color: "GrayText" }}>
                                    {service.description}
                                </Typography>

                                <Button
                                    href={`/virtual-seva/${service.buttonText}`} // Dynamic route based on the service name
                                    variant="contained"
                                    sx={{
                                        textTransform: "none",
                                        color: '#fff',
                                        marginTop: '20px',
                                        textDecoration: "none",
                                        '&:hover': {
                                            color: '#fff',
                                        }
                                    }}
                                >
                                    Book {service.buttonText}
                                </Button>

                            </Card>
                        </a>
                    ))}
                </Box>
            </Container>



            {/* how to participate */}
            <Container maxWidth="lg" sx={{ mt: 4, padding: '20px 20px', display: 'flex', justifyContent: 'center' }}>
                <Box
                    sx={{
                        backgroundColor: '#f6fffe',
                        borderRadius: '16px',
                        textAlign: 'center',
                        width: '100%',
                        position: 'relative',
                        overflow: 'hidden',
                        boxShadow: 1,
                        p: 2
                    }}
                >
                    {/* Section Title */}
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: 'center',
                            fontSize: '2rem',
                            fontWeight: '700',
                            // marginBottom: '10px',
                            color: '#2264ae',
                            lineHeight: 1.3,
                        }}
                    >
                        How to participate
                    </Typography>

                    {/* Section Subtitle */}
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: 'center',
                            color: "GrayText"
                        }}
                    >
                        Follow these simple steps to participate in our upcoming rituals and events. Get involved and be part of the sacred traditions.
                    </Typography>

                    {/* Steps */}
                    <Grid container spacing={6} justifyContent="space-evenly" sx={{ mt: 3, }}>
                        {[...Array(4)].map((_, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index} sx={{ pt: "0px !important" }}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: 4 }}>
                                    {/* Step Number */}
                                    <Box
                                        sx={{
                                            width: 'fit-content',
                                            height: '50px',
                                            backgroundColor: '#38a1f7',
                                            color: '#fff',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: { xs: '1.2rem', sm: '1.8rem' }, // Adjust font size for small screens
                                            fontWeight: '700',
                                            marginRight: '20px',
                                            p: 2,
                                            zIndex: 1,
                                        }}
                                    >
                                        {index + 1}
                                    </Box>

                                    {/* Step Content */}
                                    <Box sx={{ textAlign: 'left', zIndex: 1, pt: 0 }}>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontWeight: 600,
                                                color: '#333',
                                                marginBottom: '5px',
                                                fontSize: {
                                                    xs: '0.95rem', // Small screensLarger screens
                                                },
                                            }}
                                        >
                                            {index === 0 && 'Book Instantly or Plan Ahead!'}
                                            {index === 1 && 'Accurate Sankalpa Details: Enter the name and Nakshatra for inclusion in the Sankalpa.'}
                                            {index === 2 && 'Live Streaming Access: Watch the event live on Shubadinam.tv or our YouTube channel. '}
                                            {index === 3 && 'Divine Prasads Delivered: Receive sacred prashads at your doorstep after the event. '}
                                        </Typography>
                                        <Typography
                                            variant='subtitle2'
                                            sx={{
                                                color: '#777',
                                                // lineHeight: '1.8',
                                                maxWidth: '650px',
                                                margin: '0 auto',
                                            }}
                                        >
                                            {index === 0 && 'Premium members enjoy one complimentary puja slot.'}
                                            {index === 1 && 'Ensure details are precise for the ritual.'}
                                            {index === 2 && 'Traditional attire is encouraged for Yajamans during the stream.'}
                                            {index === 3 && 'Timely delivery is ensured.'}
                                        </Typography>

                                        {/* Optional Image for each step */}
                                        {index === 0 && (
                                            <Box sx={{ marginTop: '20px' }}>
                                                <img
                                                    data-aos="fade-up"
                                                    src={require("../images/virtualseva/vs_img3.jpg")}
                                                    alt="Book Instantly"
                                                    style={{
                                                        height: "200px",
                                                        objectFit: "cover",
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                                                        marginTop: '15px',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {index === 1 && (
                                            <Box sx={{ marginTop: '20px' }}>
                                                <img
                                                    data-aos="fade-up"
                                                    src={require("../images/virtualseva/vs_img4.jpeg")}
                                                    alt="Submit Details"
                                                    style={{
                                                        height: "200px",
                                                        objectFit: "cover",
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                                                        marginTop: '15px',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {index === 2 && (
                                            <Box sx={{ marginTop: '20px' }}>
                                                <img
                                                    data-aos="fade-up"
                                                    src={require("../images/virtualseva/vs_img6.jpg")}
                                                    alt="Watch Live"
                                                    style={{
                                                        height: "200px",
                                                        objectFit: "cover",
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                                                        marginTop: '15px',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {index === 3 && (
                                            <Box sx={{ marginTop: '20px' }}>
                                                <img
                                                    data-aos="fade-up"
                                                    src={require("../images/virtualseva/vs_img5.jpeg")}
                                                    alt="Prashad Delivery"
                                                    style={{
                                                        height: "200px",
                                                        objectFit: "cover",
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                                                        marginTop: '15px',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>


                    {/* Call to Action Button */}
                    <Button onClick={handleScroll}
                        sx={{
                            textTransform: "none",
                            backgroundColor: '#38a1f7',
                            color: '#fff',
                            borderRadius: '8px',
                            p: 1,
                        }}
                        variant="contained"
                    >
                        Book Your Slot Now
                    </Button>
                </Box>
            </Container>

            {/* Advantages Section */}
            <Container maxWidth="lg" sx={{ mt: 4 }} >
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: 'center',
                        fontSize: '2rem',
                        fontWeight: '700',
                        marginBottom: '10px',
                        color: '#2264ae',
                        lineHeight: 1.3,
                    }}
                >
                    Advantages of &nbsp;Virtual Puja Seva
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'center',
                        color: '#777',
                    }}
                >
                    Embrace the blessings of sacred rituals from the comfort of your home, performed by expert
                    Vedic scholars to bring well-being and prosperity to you and your loved ones.
                </Typography>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                    {/* Left Side Content */}
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2} direction="column" sx={{ p: 1 }}>
                            {whySection.map((item) => (
                                <Grid item xs={12} sm={6} md={4} key={item.id} >
                                    <Card
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            bgcolor: "#ffff",
                                            padding: 1,
                                            boxShadow: 1,
                                            borderRadius: 2,
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 48, height: 48, marginRight: 2, borderRadius: "50%" }}
                                            image={item.image}
                                            alt={item.alt}
                                        />
                                        <CardContent>
                                            <Typography
                                                variant="body5" component="div"
                                                sx={{ fontWeight: "bold", color: "#2264ae", marginBottom: 1, fontSize: "1.2rem" }}
                                            >
                                                {item.headline}
                                            </Typography>
                                            <Typography variant="body5" sx={{ color: "#4B5563", }}>
                                                {item.text}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>


                    {/* Right Side Image (Side Rail) */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                            {/* Left Grid Item for Image */}
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center', // Ensures proper alignment
                                }}
                            >
                                <Box
                                    component="img"
                                    src={require("../images/virtualseva/vs_img2.jpeg")} // Replace with your actual image
                                    alt="Meditating Person"
                                    sx={{
                                        width: { xs: "100%", sm: "80%", md: "100%" }, // Adjusts width based on screen size
                                        height: { xs: "200px", sm: "auto", md: "auto" }, // Ensures proper height for mobile
                                        borderRadius: '12px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        objectFit: 'cover', // Keeps the image aspect ratio
                                    }}
                                />
                            </Grid>


                            {/* Right Grid Item for Content */}
                            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        backgroundImage: `url('${require("../images/virtualseva/virtualseva_image1.jpg")}')`,
                                        backgroundColor: '#ffff', // Light background color for contrast
                                        padding: 5,
                                        backgroundPosition: "contain",
                                        borderRadius: '12px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                                        width: '100%', // Ensures the Box occupies full width inside Grid
                                        maxWidth: '600px', // Maximum width for large screens
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 700,
                                            marginBottom: 2,
                                            color: '#2264ae', // Blue color for the heading
                                        }}
                                    >
                                        Join Us!
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: '#555',
                                            fontSize: '1.1rem',
                                            marginBottom: 3,
                                            lineHeight: 1.5,
                                        }}
                                    >
                                        Become a part of the Shubadinam family, reconnect with your traditions, and make your
                                        celebrations deeply meaningful and truly special.
                                    </Typography>

                                    {/* Register Now Button */}
                                    <Button
                                        variant="contained"
                                        href="https://www.shubadinam.com" // Link to the website
                                        target="_blank" // Opens the link in a new tab
                                        rel="noopener noreferrer" // Security measure for links opening in a new tab
                                        sx={{
                                            backgroundColor: '#38a1f7',
                                            color: '#fff',
                                            padding: '10px 24px',
                                            borderRadius: '30px',
                                            '&:hover': {
                                                color: '#fff',
                                            },
                                        }}
                                    >
                                        Register Now
                                    </Button>

                                    {/* Learn More Button */}
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            color: '#2264ae',
                                            borderColor: '#2264ae',
                                            padding: '10px 24px',
                                            borderRadius: '30px',
                                            marginTop: 3, // Space between buttons
                                            '&:hover': {
                                                backgroundColor: '#2264ae',
                                                color: '#fff',
                                            },
                                        }}
                                        href="https://www.shubadinam.com" // Link to the website
                                        target="_blank" // Opens the link in a new tab
                                        rel="noopener noreferrer" // Security measure for links opening in a new tab
                                    >
                                        Visit Our Website
                                    </Button>
                                </Box>
                            </Grid>


                        </Grid>


                    </Grid>

                </Grid>
            </Container>

            {/* Testimonials Section */}
            <Container maxWidth="lg" sx={{ padding: '20px 20px', display: 'flex', justifyContent: 'center', mt: 2 }}>
                <SevalandingTestimonial />
            </Container>

            {/* FAQ Section */}
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

                {/* Header Section */}
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: 'center',
                            fontSize: '2rem',
                            fontWeight: '700',
                            // marginBottom: '10px',
                            color: '#2264ae',
                        }}
                    >
                        Frequently Asked Questions
                    </Typography>
                    <Typography variant="body1" color="text.secondary" mt={2}>
                        Sample text. Click to select the text box. Click again or double click to start editing the text.
                    </Typography>
                </Box>

                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={8}>
                        {FAQs.map((faq, index) => (
                            <Accordion key={index} sx={{ mb: 1 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`} >
                                    <Typography variant="body5" fontWeight="bold" sx={{ color: "#154880", flexGrow: 1 }}>
                                        {faq.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body2" color="text.secondary">
                                        {faq.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>

                    {/* Image Section */}
                    <Grid item xs={12} lg={4} mt={{ xs: 4, lg: 0 }} textAlign="center" sx={{ display: "none" }}>
                        <img
                            alt="Person cleaning the interior of a car with a brush"
                            src="https://storage.googleapis.com/a1aa/image/2KVjpo0XNCKYDJMzeWmf8Zg1dg9aKzfksV0rlQvB3vBTiLunA.jpg"
                            style={{ width: '100%', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
                        />
                    </Grid>
                </Grid>

            </Container>

            <FooterComponent />

        </div >
    );
};

export default VirtualsevaInstructions;
