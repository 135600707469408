import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import FooterComponent from "../components/footer";
import Swal from 'sweetalert2';
import LandingHeader from "../components/LandingHeader"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Button, Container, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { Country, State } from 'country-state-city';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import EmailIcon from '@mui/icons-material/Email'; // or any other icon you want to use
import { Person } from "@mui/icons-material";
import SlidingHeader from "../components/SlidingHeader";



AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});



const RegisterPage = () => {

    const sectionRef = useRef(null);
    const navigate = useNavigate();


    const [isVerifyclicked, setVerifiedClicked] = useState(false)


    useEffect(() => {

        window.scrollTo(0, 0);
        AOS.refresh();

    }, []);

    const [dynamicInput, DynamicInputFunction] = useState({
        userEmail: "",
        // userPassword: "",
        countryCode: "91",
        userPhone: "",
    })






    const [isSubmitClicked, SetIsSubmitClicked] = useState(false);

    const [isOptSent, SetIsOtpSent] = useState(false);

    const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);


    const verifyRegOtp = () => {


        setVerifiedClicked(true)

        // const encodedDetails = localStorage.getItem("details");
        // const decodedDetails = atob(encodedDetails);

        // console.log(decodedDetails);


        const regPhone = formData.userPhone

        // const regPhone = localStorage.getItem("email")
        const regOtp = sessionStorage.getItem("otp");
        // console.log(regOtp)
        // console.log(regPhone);

        const url = `https://backend.shubadinam.com/verifyRegOtp?userPhone=${regPhone}&&dialCode=${formData.dialCode}&&userOtp=${regOtp}`

        axios.get(url)
            .then((response) => {

                console.log(response.data);
                const token = response.data.token;
                const uniqueId = response.data.uniqueId
                localStorage.setItem("token", token);
                localStorage.setItem("SD_Id", uniqueId);
                localStorage.setItem("homeauth", true);

                window.location.href = '/dashboard_thankyou'; // Redirect to a success page

            })

            .catch((error) => {
                // console.log(error);

                setVerifiedClicked(false)

                setOtpInputs(["", "", "", ""]);

                const invaildOtp = error.response.data.message

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5',
                });
            })
    }

    const resendOtp = () => {
        clickSubmit()
    }

    const inputRefs = useRef([]);
    const [isNotNumber, setIsNotNumber] = useState(false)

    const otpHandler = (index, value) => {

        if (!/^\d*$/.test(value)) {
            value = ""; // Set value to an empty string if not a number
            setIsNotNumber(true)
            return
        }
        setIsNotNumber(false)
        const updatedInputs = [...otpInputs];
        updatedInputs[index] = value;
        setOtpInputs(updatedInputs);

        // Combine all input values and store in sessionStorage
        const otpValue = updatedInputs.join("").trim();
        sessionStorage.setItem("otp", otpValue);

        setOtpInputs(updatedInputs);

        if (value && index < otpInputs.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index, event) => {
        if (event.keyCode === 8 && !otpInputs[index] && index > 0) {
            // Handle Backspace key when input is empty
            inputRefs.current[index - 1].focus();
        }
    };

    const formRef = useRef(null)

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('IN'); // Default country as India (IN)
    const [selectedState, setSelectedState] = useState('');

    useEffect(() => {
        // Fetch all countries and set them
        setCountries(Country.getAllCountries());

        // Fetch the states of India by default
        const indiaStates = State.getStatesOfCountry('IN');  // Get states of India
        setStates(indiaStates);

        // You can set the default state if needed (optional)
        setSelectedState('');  // Select the first state of India by default
    }, []);

    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        dialCode: '',
        userPhone: '',
        userCountry: 'India',
        userState: '',

    });




    // country handler
    const handleCountryChange = (event) => {
        const countryCode = event.target.value;
        const country = countries.find(c => c.isoCode === countryCode); // Get full country name

        setSelectedCountry(countryCode);
        setSelectedState(''); // Reset state when country changes
        setFormData((prev) => ({
            ...prev,
            userCountry: country ? country.name : '', // Update formData with full country name
            userState: '' // Reset state in formData
        }));

        const countryStates = State.getStatesOfCountry(countryCode);
        setStates(countryStates);
    };

    // state handler
    const handleStateChange = (event) => {
        const stateCode = event.target.value;
        const state = states.find(s => s.isoCode === stateCode); // Get full state name

        setSelectedState(stateCode);
        setFormData((prev) => ({
            ...prev,
            userState: state ? state.name : '' // Update formData with full state name
        }));
    };

    // input handler
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // phone handler
    const handlePhoneChange = (value, country) => {
        console.log(country.dialCode);

        setFormData({
            ...formData,
            userPhone: value, // Store only the phone number
            dialCode: country.dialCode,
        });

    };

    // otp html
    const renderOtpInputs = () => (
        <Box
            id="reg-inputs"
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                padding: '10px 0',
            }}
        >
            {otpInputs.map((value, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    ref={ref => (inputRefs.current[index] = ref)}
                    onChange={e => otpHandler(index, e.target.value)}
                    onKeyDown={e => handleKeyDown(index, e)}
                    inputMode="numeric"
                    style={{
                        width: '40px',
                        height: '40px',
                        fontSize: '24px',
                        textAlign: 'center',
                        border: '2px solid #38a1f7',
                        borderRadius: '5px',
                        marginRight: '5px',
                        outline: 'none',
                        transition: 'border-color 0.3s',
                    }}
                    onFocus={(e) => e.target.style.borderColor = '#38a1f7'}
                    onBlur={(e) => e.target.style.borderColor = '#ccc'}
                />
            ))}
        </Box>
    );

    // pre-submit
    const clickSubmitcheck = (e) => {

        e.preventDefault()


        if (!/^\d+$/.test(formData.userPhone)) {
            alert('Please enter only numeric values in the phone number field.');
            return; // Do not proceed further if the input is invalid
        }


        Swal.fire({
            title: "Confirmation",
            html: `
                <div style="font-family: Arial, sans-serif; text-align: left;">
                    <p style="font-size: 16px;">Please confirm that you want to proceed with the following information:</p>
                    <ul style="list-style: none; padding: 0; margin: 0;">
                        <li style="margin-bottom: 10px;">
                            <strong>Name:</strong> <span style="color: #333;">${formData.userName}</span>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <strong>Email Address:</strong> <span style="color: #333;">${formData.userEmail}</span>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <strong>Phone Number:</strong> <span style="color: #333;">${formData.userPhone}</span>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <strong>Country:</strong> <span style="color: #333;">${formData.userCountry}</span>
                        </li>
                        <li style="margin-bottom: 10px;">
                            <strong>State:</strong> <span style="color: #333;">${formData.userState}</span>
                        </li>
                    </ul>
                </div>
            `,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "Cancel",
            backdrop: true,
        }).then((result) => {
            if (result.isConfirmed) {
                clickSubmit();
            }
        });


    }

    const clickSubmit = () => {

        if (!isSubmitClicked) {
            SetIsSubmitClicked(true);

            const link = "https://backend.shubadinam.com/upload/mailpass"
            axios.post(link, formData)
                .then((response) => {

                    if (response.data.errors) {

                        const invalidmailPass = response.data.errors[0].msg

                        Swal.fire({
                            title: 'Invalid',
                            text: invalidmailPass,
                            icon: 'error',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5',
                        });


                    }
                    else {

                        // shows otp 
                        SetIsOtpSent(true)

                        const verifySuccess = response.data.success
                        // localStorage.setItem('myDataKey', JSON.stringify(loggingRelation));
                        // localStorage.setItem('myDataKey2', JSON.stringify(loggingUser));

                    }

                    SetIsSubmitClicked(false);

                })
                .catch((error) => {
                    console.log(error); // Log the error for debugging

                    // Default error message
                    let backendError = "An unknown error occurred";
                    let userExist = null;

                    // Determine the type of error
                    if (error.response) {
                        userExist = error.response.data.error;
                        backendError = userExist || "An error occurred with the request.";
                    } else if (error.request) {
                        backendError = "No response received from the server.";
                    } else {
                        backendError = error.message;
                    }

                    // Specific message for network errors
                    if (backendError === "Network Error") {
                        backendError = "Network error, please check your internet connection.";
                    }

                    // Display the alert
                    Swal.fire({
                        title: userExist ? 'User Already Exist' : 'Error',
                        text: backendError,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#3085d6',
                        background: '#f5f5f5',
                    });
                });


            SetIsSubmitClicked(false);


        }

    }

    return (
        <Box>
            <HelmetProvider>
                <Helmet>
                    <title>Register </title>
                    <meta name="description" content="Register  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>


            <SlidingHeader />

            <Container maxWidth="lg" sx={{ mt: 20, mb: 20, }}>


                <Grid container spacing={4} display="flex" >
                    <Grid item xs={12} md={8} ref={formRef} sx={{ order: { xs: 1, md: 2 }, backgroundColor: "#ffff" }}>
                        <form
                            onSubmit={clickSubmitcheck} // Assuming clickSubmitcheck handles form submission
                            style={{
                                padding: '24px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                                textAlign: 'center',
                                marginBottom: { xs: '16px', md: '0' },
                            }}
                        >
                            <Typography
                                variant="h4"
                                color="secondary"
                                component="h1"
                                sx={{ fontWeight: 'bold', marginBottom: 5 }}
                            >
                                Sign Up
                            </Typography>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Name"
                                        name="userName"
                                        placeholder="Enter name"
                                        fullWidth
                                        sx={{ backgroundColor: "white" }}
                                        size="small"
                                        onChange={handleChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Person />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Email"
                                        name="userEmail"
                                        placeholder="Email address"
                                        fullWidth
                                        required
                                        size="small"
                                        onChange={handleChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <PhoneInput
                                        validateMobilePrefix={true}
                                        country={'in'} // Default country code
                                        value={formData.userPhone} // Display with the country code
                                        name="userPhone"
                                        onChange={handlePhoneChange}
                                        inputStyle={{
                                            width: '100%',
                                            height: '40px',
                                            borderRadius: '5px',
                                            border: '1px solid #ced4da',
                                            paddingLeft: '50px', // Space for the country code dropdown
                                        }}
                                        inputProps={{
                                            inputMode: "numeric"
                                        }}
                                        dropdownStyle={{
                                            borderRadius: '5px',
                                        }}
                                    />

                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth sx={{ textAlign: "start", textIndent: 15 }}>
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                            label="Country"
                                            placeholder="country"
                                            name="userCountry"
                                            required
                                        >
                                            {countries.map((country) => (
                                                <MenuItem key={country.isoCode} value={country.isoCode}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth sx={{ textAlign: "start", textIndent: 15 }}>
                                        <InputLabel>State</InputLabel>
                                        <Select
                                            value={selectedState}
                                            onChange={handleStateChange}
                                            label="State"
                                            disabled={!selectedCountry}
                                            name="userState"
                                        >
                                            {states.map((state) => (
                                                <MenuItem key={state.isoCode} value={state.isoCode}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>

                            {isOptSent ? (


                                <Box sx={{ maxWidth: 400, margin: '0 auto', padding: 3, boxShadow: 3, borderRadius: 2, mt: 5, mb: 5, backgroundColor: "#eaf5ffb3" }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                        OTP Verification
                                    </Typography>
                                    <Typography sx={{ mb: 2, color: 'text.secondary' }}>
                                        We have sent a verification code to your mobile number.
                                    </Typography>
                                    {isNotNumber && (
                                        <Typography color="error" sx={{ mb: 2 }}>
                                            (Note: Please enter numeric values.)
                                        </Typography>
                                    )}
                                    {/* OTP Inputs */}

                                    {renderOtpInputs()}

                                    <Grid item xs={12} md={12} >

                                        <Grid container spacing={1} justifyContent="center">

                                            <Grid item xs={6}>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={resendOtp}
                                                    sx={{ bgcolor: "white", }}
                                                    fullWidth
                                                >
                                                    Resend OTP
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={verifyRegOtp}
                                                    disabled={isVerifyclicked}
                                                    fullWidth
                                                >
                                                    {isVerifyclicked ? "Verifying" : "Verify me"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                                :
                                (
                                    <Box sx={{ mt: 3 }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{ mb: 2 }}
                                        >
                                            {isSubmitClicked ? 'Submitting...' : 'Submit'}
                                        </Button>
                                    </Box>)

                            }


                            <Typography sx={{ textAlign: 'center' }}>
                                Already have an account?
                                <NavLink to="/signin" style={{ textDecoration: 'none', marginLeft: 4 }}>
                                    <span style={{ color: '#38a1f7', fontWeight: 'bold' }}>Sign In</span>
                                </NavLink>
                            </Typography>
                        </form>

                    </Grid>

                    <Grid item xs={12} md={4} sx={{ order: { xs: 2, md: 1 } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', mb: 2 }}>
                                <img
                                    src={require("../images/signup-img.png")}
                                    alt="Sign Up"
                                    style={{ maxWidth: '100%', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', objectFit: "cover" }}
                                />
                            </Box>
                            <Box sx={{ padding: 2, textAlign: 'center' }}>
                                <Typography variant="h6" component="span" sx={{ fontWeight: '900' }}>
                                    Aligning Stars:
                                </Typography>
                                <Typography sx={{ marginTop: 1 }}>
                                    Embrace Your Janma Nakshatra/Tithi. Unlock insights into your destiny with just a click.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>



            </Container>


            <FooterComponent></FooterComponent>


        </Box >
    );
};


export default RegisterPage;







