import React, { useState } from 'react';
import { Box, Grid, Tabs, Tab, Typography } from '@mui/material';
import SlidingHeader from '../components/SlidingHeader';
import FooterComponent from '../components/footer';
import PageSlider from './utils/PageSlider';
import ScrollToTop from 'react-scroll-to-top';
import AvahantiHomamGallery from './utils/AvahantiHomamGallery';
import GanapathiHomamGallery from './utils/GanapathiHomamGallery';
import SuryaNamaskaramGallery from './utils/SuryaNamaskaramGallery';
import Services from './utils/services';

const categories = [
    { label: 'Avahanti Homam' },
    { label: 'Ganapathi Homam' },
    { label: 'Surya Namaskaram' },
    { label: 'Services' },
]; // Added more categories for demonstration

const GalleryScreen = () => {
    const [selectedCategory, setSelectedCategory] = useState(0);

    const handleCategoryChange = (event, newValue) => {
        setSelectedCategory(newValue);
    };

    // Context Menu Handlers
    const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent the default right-click menu
    };
    return (
        <div>
            <ScrollToTop smooth />
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle="Gallery"
            />

            <Box sx={{ padding: 2 }}>
                {/* Scrollable Tabs */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                    <Tabs
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="primary"
                        indicatorColor="primary"

                    >
                        {categories.map((category, index) => (
                            <Tab key={index} sx={{ textTransform: "none", fontWeight: "bold", fontSize: "1rem" }} label={category.label} />
                        ))}
                    </Tabs>
                </Box>

                {/* Display gallery based on the selected tab */}
                <Grid container spacing={2} sx={{ p: 1 }} onContextMenu={(e) => handleContextMenu(e)}>
                    {categories[selectedCategory].label === 'Avahanti Homam' ? (
                        <AvahantiHomamGallery />
                    ) : categories[selectedCategory].label === 'Ganapathi Homam' ? (
                        <GanapathiHomamGallery />
                    ) : categories[selectedCategory].label === 'Surya Namaskaram' ? (
                        <SuryaNamaskaramGallery />
                    ) : categories[selectedCategory].label === 'Services' ? (
                        <>
                            <Typography
                                variant="h6"
                                align="left"
                                gutterBottom
                                sx={{
                                    color: '#2c3e50',
                                    p: 2,
                                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                Exclusive Services:
                            </Typography>
                            <Services />
                        </>

                    ) : null}
                </Grid>
            </Box>

            <FooterComponent />
        </div>
    );
};

export default GalleryScreen;
