import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Container,
    Grid
} from '@mui/material';
import '@splidejs/splide/dist/css/splide.min.css';

const DashboardEvents = () => {
    const navigate = useNavigate();


    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 576);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleRouting = (page) => {
        navigate(`/virtual-seva/${page}`);
    };

    return (
        <Container maxWidth="lg">
            <Splide
                options={{
                    perPage: 3, // Display at least 3 cards
                    gap: 20, // Adjust gap as needed
                    pagination: true,
                    arrows: isSmallScreen ? false : true,
                    breakpoints: {
                        640: {
                            perPage: 2, // Display 2 cards on small screens
                        },
                        768: {
                            perPage: 2, // Display 2 cards on medium screens
                        },
                        992: {
                            perPage: 3, // Display 3 cards on larger screens
                        },
                    },
                }}
            >
                {[ // Create an array of events to map through
                    {
                        title: "Avahanti Homam",
                        image: require("../images/homamImages/lakshmi-01.png"),
                        instructions: "Avahanti-Homam",
                        booking: "Avahanti-Homam"
                    },
                    {
                        title: "Ganapathi Homam",
                        image: require("../images/homamImages/ganapathi-01.png"),
                        instructions: "Ganapathi-Homam",
                        booking: "Ganapathi-Homam"
                    },
                    {
                        title: "Surya Namaskaram",
                        image: require("../images/homamImages/suryan-01.png"),
                        instructions: "Surya-Namaskaram",
                        booking: "Surya-Namaskaram"
                    },
                    {
                        title: "Premium",
                        image: require("../images/homamImages/premium icon-01.png"),
                        instructions: "pricing",
                        booking: "pricing"
                    },
                    {
                        title: "Gift Shoppe",
                        image: require("../images/homamImages/gifts-01.png"),
                        instructions: "https://shop.shubadinam.com",
                        booking: "https://shop.shubadinam.com"
                    },
                ].map((event, index) => (
                    <SplideSlide key={index}>
                        <Card onClick={() => event.booking.startsWith("http") ? window.open(event.booking, "_blank") : handleRouting(event.booking)} sx={{ cursor: "pointer" }}>
                            <CardMedia
                                component="img"
                                height={isSmallScreen ? "60" : "100"} // Adjust height to reduce size
                                width={isSmallScreen ? "60" : "100"} // Adjust height to reduce size
                                image={event.image}
                                alt={event.title}
                                style={{ objectFit: 'contain', }} // Ensure the image fits within the defined dimensions
                            />
                            <CardContent sx={{ textAlign: 'center', backgroundColor: "aliceblue", p: 1 }}>
                                <Typography gutterBottom color="primary" variant={isSmallScreen ? "subtitle2" : "h6"} component="div" sx={{ mb: 1 }}>
                                    {event.title}
                                </Typography>

                                <Grid container spacing={1} justifyContent="center" alignItems="center">
                                    <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                                        <Button
                                            variant="outlined"
                                            // onClick={() => handleRouting(event.instructions)}
                                            sx={{
                                                bgcolor: "white",
                                                textTransform: "none",
                                                width: '100%', // Make button full width
                                                // fontSize: "1px !important"
                                            }}
                                        >
                                            Learn more
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} sm={6} display="flex" justifyContent="center" alignItems="center"> {/* Centered for both mobile and larger screens */}
                                        <Button
                                            className='dash-e-btn2 date-btn'
                                            size='small'
                                            variant="contained"
                                            // onClick={() =>
                                            //     event.booking.startsWith("https")
                                            //         ? window.open(event.booking, "_blank")
                                            //         : handleRouting(event.booking)
                                            // }
                                            sx={{
                                                color: "primary",
                                                textAlign: "center",
                                                textTransform: "none",
                                                width: '100%', // Ensure full width
                                            }}
                                        >
                                            {event.title === "Premium" || event.title === "Gift Shoppe" ? "Purchase" : "Book slot"}
                                        </Button>
                                    </Grid>
                                </Grid>



                            </CardContent>

                        </Card>
                    </SplideSlide>
                ))}
            </Splide>
        </Container >
    );
};

export default DashboardEvents;
