// BlogData.js
export const tithiData = [
    {
        title: "Tithi Food",
        description: "Here's an overview of the food preferences that individuals born on each Janma Tithi.",
        image: require("../../images/boxesImages/food2.jpg"),
        link: "/tithi-foods"
    },
    {
        title: "Tithi Characteristics",
        description: "This is a general overview of the characteristics associated with each Janma Tithi in Vedic astrology.",
        image: require("../../images/boxesImages/char2.jpg"),
        link: "/tithi-characteristics"
    },
    {
        title: "Tithi Deity",
        description: "In Vedic astrology and Hindu tradition, each Janma Tithi is associated with a presiding deity.",
        image: require("../../images/boxesImages/deity2.png"),
        link: "/tithi-deities"
    },
    {
        title: "Tithi Likes and Dislikes",
        description: "This is a general overview of the likes and dislikes that individuals born on each Janma Tithi might have, according to Vedic astrology.",
        image: require("../../images/boxesImages/likes22.jpg"),
        link: "/tithi-likes-dislikes"
    },
];
