import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Book, BookOnline, CardGiftcard, Star, } from '@mui/icons-material';
import {
    Container,
    Card,
    CardMedia,
    CardContent,
    Chip,
} from '@mui/material';
const HomamEvents = () => {
    const navigate = useNavigate();

    const handleRouting = (homam) => {
        window.location.href = `/${homam}`;
    };

    const products = [
        { id: "virtual-puja-seva", name: "Virutal Puja Seva", price: "₹250 - ₹500", img: require("../images/thumbnails/virtual pooja thumnail.jpg"), label: "Popular", icon: <BookOnline />, buttonText: "Learn More", },
        { id: "pricing", name: "Premium", price: "₹499", img: require("../images/landingservices/landing_premium.jpg"), label: "Featured", icon: <Star />, buttonText: "Learn More", },
        { id: "https://shop.shubadinam.com", name: "Gift Shoppe", price: "₹99 - ₹999 ", img: require("../images/thumbnails/gift poster-01.png"), label: "Exclusive", icon: <CardGiftcard />, isExternal: true, buttonText: "Gift Now", },
        { id: "https://shop.shubadinam.com/collections/books", name: "Books", price: "₹99 - ₹999 ", img: require("../images/landingservices/landing_books.jpg"), label: "Exclusive", icon: <Book />, buttonText: "Buy Now", isExternal: true, }
    ];

    return (
        <Box
            sx={{
                // background: 'linear-gradient(to bottom, #fdfbfb, #ebedee)',
                py: 4,
                minHeight: '90vh',
            }}
        >
            <Container maxWidth="xl">
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        color: '#2c3e50',
                        mb: 2,
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    Exclusive Services
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    sx={{
                        color: '#7f8c8d',
                        mb: 4,
                        fontStyle: 'italic',
                    }}
                >
                    Explore our services and exclusive products to enrich your life
                </Typography>

                <Grid container spacing={2} display="flex" alignItems="center" justifyContent="center">
                    {products.map((product, index) => (
                        <Grid item xs={6} sm={6} md={4} lg={2} key={index}>
                            <Card
                                onClick={() => product.isExternal ? window.open(product.id, "_blank") : handleRouting(product.id)}
                                sx={{
                                    cursor: "pointer",
                                    borderRadius: 4,
                                    boxShadow: 2,
                                    overflow: 'hidden',
                                    position: 'relative',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    background: 'linear-gradient(to bottom, #ffffff, #f7f8fa)',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: 10,
                                        background: 'linear-gradient(to bottom, #f7f8fa, #ffffff)',
                                    },
                                }}

                                data-aos="fade-up"
                                data-aos-delay={index * 100}
                            // data-aos-offset="-50"
                            >
                                {product.label && (
                                    <Chip
                                        size='small'
                                        label={product.label}
                                        color="primary"
                                        sx={{
                                            fontSize: "12px !important",
                                            position: 'absolute',
                                            p: 0,
                                            top: 4,
                                            right: 8,
                                            zIndex: 1,
                                            bgcolor: product.label === "Upcoming" ? "orange" : '#007bff',
                                        }}
                                    />
                                )}
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    image={product.img}
                                    alt={product.name}
                                    sx={{
                                        objectFit: 'contain',
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        justifyContent: "start",
                                        background: '#f9f9f9',
                                        pb: "10px !important",
                                    }}
                                >
                                    <Typography
                                        variant="body1" // Adjust variant as needed; `body5` doesn't exist
                                        fontWeight="bold"
                                        sx={{
                                            color: '#34495e',
                                            textAlign: 'start',
                                            fontSize: {
                                                xs: '12px', // Font size for small screens (e.g., mobile)
                                                sm: '16px', // Font size for medium screens (e.g., tablets)
                                                md: '18px', // Font size for large screens (e.g., desktops)
                                            },
                                        }}
                                    >
                                        {product.name}
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        sx={{
                                            fontSize: {
                                                xs: '12px', // Font size for small screens (e.g., mobile)
                                                sm: '12px', // Font size for medium screens (e.g., tablets)
                                                md: '14px', // Font size for large screens (e.g., desktops)
                                            },
                                            mb: 1,
                                            textAlign: "start"
                                        }}
                                    >
                                        Price:  {product.price}/-
                                    </Typography>
                                    <Button
                                        size='small'
                                        variant="contained"
                                        // startIcon={product.icon}
                                        sx={{
                                            bgcolor: "#263b77",
                                            fontSize: { xs: "14px !important", md: "1.5rem" },
                                            textTransform: 'none',
                                            borderRadius: 2,
                                            color: '#f0f0f0', // Light grey for contrast
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
                                        }}
                                        disabled={product.buttonText === "Upcoming" ? true : false}
                                    >
                                        {product.buttonText}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {/* View All Button */}
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button
                        onClick={() => navigate("/more-services")}
                        variant="contained"
                        color="secondary"
                        sx={{
                            textTransform: 'none',
                            borderRadius: 2,
                            p: 1,
                            background: '#007bff', // Primary button color
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                background: '#0056b3',
                                transform: 'scale(1.05)',
                                boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.25)',
                            },
                        }}
                    >
                        View All Services
                    </Button>
                </Box>
            </Container>
        </Box>

    );

};

export default HomamEvents;
