import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import * as XLSX from 'xlsx';
import { Download } from '@mui/icons-material';
import SDadminHeader from '../SDadminHeader';

const DownloadAll = () => {
    const [userType, setUserType] = useState('all');

    const handleDownload = async () => {
        try {
            // Fetch the data based on the selected user type
            const response = await fetch(`https://backend.shubadinam.com/admin/download?type=${userType}`);
            const data = await response.json();

            // Create a new workbook and add a worksheet from the fetched data
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, `${userType} Users`);

            // Write the Excel file to a Blob
            const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the Excel file
            const blob = new Blob([excelFile], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);

            // Create a link element to download the file
            const a = document.createElement('a');
            a.href = url;
            a.download = `${userType}-users.xlsx`;
            a.click();

            // Clean up
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading user data:', error);
        }
    };


    return (
        <div>
            <SDadminHeader />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    backgroundColor: '#f5f5f5',
                    padding: 2,
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        padding: 4,
                        width: '100%',
                        maxWidth: 400,
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                        Download User Data
                    </Typography>
                    <FormControl fullWidth sx={{ marginBottom: 3 }}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            label="Type"
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                        >
                            <MenuItem value="all">All Users</MenuItem>
                            <MenuItem value="premium">Premium Users</MenuItem>
                            <MenuItem value="non-premium">Non-Premium Users</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Download />}
                        onClick={handleDownload}
                    >
                        Download
                    </Button>
                </Paper>
            </Box>
        </div>
    );
};

export default DownloadAll;
