// import React, { useState, useEffect } from 'react';
// import { Container, Grid, TextField, Button, Box, Typography, Card, CardContent, CardMedia, IconButton } from '@mui/material';
// import socketIOClient from 'socket.io-client';
// import SendIcon from '@mui/icons-material/Send';
// import CommentIcon from '@mui/icons-material/Comment';

// const SOCKET_SERVER_URL = "http://localhost:4000"; // Change if necessary

// const RoughTwo = () => {
//   const [posts, setPosts] = useState([]);
//   const [newPostImage, setNewPostImage] = useState(null);
//   const [newPostText, setNewPostText] = useState('');
//   const [newComment, setNewComment] = useState('');
//   const [socket, setSocket] = useState(null);

//   useEffect(() => {
//     const socketConnection = socketIOClient(SOCKET_SERVER_URL);
//     setSocket(socketConnection);

//     // Listen for updated posts
//     socketConnection.on('updatePosts', (updatedPosts) => {
//       setPosts(updatedPosts);
//     });

//     return () => {
//       socketConnection.disconnect();
//     };
//   }, []);

//   const handlePostSubmit = () => {
//     if (newPostText || newPostImage) {
//       const postData = {
//         text: newPostText,
//         image: newPostImage,
//         comments: [],
//       };
//       socket.emit('newPost', postData);
//       setNewPostImage(null);
//       setNewPostText('');
//     }
//   };

//   const handleCommentSubmit = (postId) => {
//     if (newComment) {
//       const commentData = { postId, comment: newComment };
//       socket.emit('newComment', commentData);
//       setNewComment('');
//     }
//   };

//   const handleImageUpload = (e) => {
//     const file = e.target.files[0];
//     const formData = new FormData();
//     formData.append('image', file);

//     fetch('https://backend.shubadinam.com/community/upload', {
//       method: 'POST',
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setNewPostImage(data.imageUrl);
//       });
//   };

//   return (
//     <Container maxWidth="md" sx={{ padding: '2rem' }}>
//       <Typography variant="h4" align="center" gutterBottom>
//         Community Wall
//       </Typography>

//       {/* New Post Section */}
//       <Box sx={{ marginBottom: '20px' }}>
//         <TextField
//           label="What's on your mind?"
//           multiline
//           rows={4}
//           fullWidth
//           value={newPostText}
//           onChange={(e) => setNewPostText(e.target.value)}
//           sx={{ marginBottom: '1rem' }}
//         />
//         <input type="file" accept="image/*" onChange={handleImageUpload} />
//         {newPostImage && <img src={newPostImage} alt="Preview" width="100%" style={{ marginTop: '1rem', borderRadius: '8px' }} />}
//         <Button variant="contained" color="primary" onClick={handlePostSubmit} sx={{ marginTop: '1rem' }}>
//           Post
//         </Button>
//       </Box>

//       {/* Posts Display */}
//       <Grid container spacing={3}>
//         {posts.map((post) => (
//           <Grid item xs={12} key={post.id}>
//             <Card sx={{ display: 'flex', marginBottom: '20px', borderRadius: '10px' }}>
//               {post.image && (
//                 <CardMedia sx={{ width: 200, borderRadius: '8px', objectFit: 'cover' }} image={post.image} />
//               )}
//               <CardContent sx={{ flex: 1 }}>
//                 <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
//                   {post.text}
//                 </Typography>
//                 <Box sx={{ marginBottom: '1rem' }}>
//                   {post.comments && post.comments.length > 0 ? (
//                     post.comments.map((comment, index) => (
//                       <Typography key={index} variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
//                         <CommentIcon fontSize="small" sx={{ marginRight: '5px' }} /> {comment.comment}
//                       </Typography>
//                     ))
//                   ) : (
//                     <Typography variant="body2" sx={{ color: 'gray' }}>
//                       No comments yet
//                     </Typography>
//                   )}
//                 </Box>
//                 <TextField
//                   label="Add a comment"
//                   value={newComment}
//                   onChange={(e) => setNewComment(e.target.value)}
//                   fullWidth
//                   margin="normal"
//                   sx={{ marginBottom: '1rem' }}
//                 />
//                 <IconButton onClick={() => handleCommentSubmit(post.id)}>
//                   <SendIcon />
//                 </IconButton>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Container>
//   );
// };

// export default RoughTwo;


import React from 'react'

const RoughTwo = () => {
  return (
    <div>RoughTwo</div>
  )
}

export default RoughTwo