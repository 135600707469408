import React, { useState, useEffect } from 'react';
import axios from 'axios';


const SevaPayment = ({ userData, premiumDetails, status, sevaTitle, selectedService, selectedDate, personCount, personDetails, isChecked, disablePaybtn, showConfetti }) => {


    const [orderId, setOrderId] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false); // Track if the script is loaded
    const [isProcessing, setIsProcessing] = useState(false); // Prevent duplicate requests

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;

        script.onload = () => setIsScriptLoaded(true); // Mark script as loaded
        script.onerror = () => alert('Failed to load payment script. Please try again.');

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Clean up script on component unmount
        };
    }, []);


    const createOrder = async () => {

        if (!isScriptLoaded) {
            alert('Payment script is not loaded yet. Please wait.');
            return;
        }

        if (isProcessing) {
            alert('Processing your request. Please wait.');
            return;
        }

        setIsProcessing(true); // Set processing state to prevent duplicate clicks

        try {
            disablePaybtn(true);

            const allDetails = {
                userData,
                premiumDetails,
                status,
                sevaTitle,
                selectedService: {
                    name: selectedService.name,
                    price: selectedService.price
                },
                selectedDate,
                personCount,
                personDetails,
                isChecked

            };

            if (!allDetails.userData.userAddress || allDetails.userData.userAddress.trim() === "") {
                alert("Please enter address field");
                return
            }

            const response = await axios.post('https://backend.shubadinam.com/seva/homam-purchasing', allDetails)
                .then(response => {
                    console.log(response); // The entire response object, including data, status, etc.

                    const data = response.data; // This is the parsed JSON body returned by axios
                    console.log(data); // Log the data received from the server

                    if (data.order && data.order.id) {
                        setOrderId(data.order.id);
                        setTransactionId(data.order.receipt);
                        handlePayment(data.order.id, data.order.amount, data.order.notes.sevaNotes, allDetails);
                        disablePaybtn(false);
                        setIsProcessing(false); // Reset processing state
                    } else {
                        alert('Failed to create order');
                        window.location.reload();
                    }

                })
                .catch(error => {
                    console.error('Error during homam purchasing:', error); // Log the error if the request fails
                    alert('Error during homam purchasing, please try again');
                    window.location.reload();
                });
        } catch (error) {
            console.error('Error:', error.message);
            alert(error.message);
            window.location.reload();
        }
    };

    const handlePayment = (orderId, backendAmount, sevaNotes, allDetails) => {

        if (orderId) {
            const options = {
                key: 'rzp_live_gp41aILMbhUm19', // Replace with your Razorpay key
                amount: backendAmount,
                currency: 'INR',
                order_id: orderId,
                name: 'Shubadinam',
                description: 'Shubadinam Transaction',
                prefill: {
                },

                handler: function (response) {

                    const url = `https://backend.shubadinam.com/seva/sevapurchased/redirection`;

                    axios.post(url, {
                        paymentId: response.razorpay_payment_id, orderId: response.razorpay_order_id,
                        signatureId: response.razorpay_signature, personDetails: allDetails.userData, isPremiumSelected: isChecked
                    })
                        .then((response) => {
                            showConfetti(true)
                        })
                        .catch((error) => {
                            if (error.response) {
                                console.log("Error Response: ", error.response.data);
                                console.log("Error Status: ", error.response.status);
                            } else if (error.request) {
                                // The request was made but no response was received
                                console.log("Error Request: ", error.request);
                            } else {
                                // Something happened in setting up the request
                                console.log("Error Message: ", error.message);
                            }
                        });

                },
                notes: {
                    address: 'Razorpay Corporate Office',
                    sevaNotes: sevaNotes
                },
                theme: {
                    color: '#3399cc',
                },
                config: {
                    display: {
                        hide: [
                            { method: 'paylater' },
                            { method: 'emi' },
                            { method: 'wallet' },
                            { method: 'bank_transfer' },
                        ],
                        preferences: { show_default_blocks: true }
                    }
                },
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();

            rzp1.on('payment.success', (response) => {
            });

            rzp1.on('payment.failed', function (response) {
                alert(response.error.description);
            })

            rzp1.on('payment.error', (response) => {
                // console.log(response);
                alert(`Payment Failed. Error: ${response.error.description}`);
            });

            rzp1.on('payment.closed', () => {
                console.warn('Payment modal closed by user');
                alert('Payment process was not completed. Please try again.');
            });



        } else {
            alert('Order ID is missing. Please try again.');
        }
    };



    return (
        <div id="rzp-button1" onClick={createOrder} disabled={!isScriptLoaded || isProcessing} style={{ width: "100%", height: "100%", padding: "5px 15px", cursor: "pointer" }}>
            {isScriptLoaded || isProcessing ? 'Pay Now' : 'Loading ...'}
        </div>
    )
}

export default SevaPayment;