import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Typography, Box, Grid, Button } from '@mui/material';
import HeaderComponent from '../components/header';
import FooterComponent from '../components/footer';
import ScrollToTop from 'react-scroll-to-top';
import LandingHeader from '../components/LandingHeader';
import PageSlider from './utils/PageSlider';
import SlidingHeader from '../components/SlidingHeader';

const ShippingDelivery = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <div>
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>Shipping and Delivery</title>
                    <meta name="description" content="Shipping and Delivery - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>

            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle="Shipping and Delivery"
            />

            <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4, textAlign: "center" }}>


                <Box mb={4}>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        Thank you for shopping with Shubadinam.
                    </Typography>

                    <Typography variant="body1" color="textPrimary" paragraph>
                        Currently, there is no shipment available.
                    </Typography>
                </Box>

                {/* Add any CTA or more information here if needed */}
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Button href='/contact-us' variant="contained" color="primary" size="large">
                            Contact Support
                        </Button>
                    </Grid>
                </Grid>
            </Container>

            <FooterComponent />
        </div>
    );
};

export default ShippingDelivery;
