import React, { useState } from "react";

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SlidingHeader from "../components/SlidingHeader";

const ShopPage = () => {
  // const [dateTimeValue, setDateTimeValue] = useState('');

  // const handleDateTimeChange = (event) => {
  //   const newValue = event.target.value;
  //   console.log(newValue); // Log the value
  //   setDateTimeValue(newValue); // Update state with the new value
  // };

  const styles = {

  };


  return (

    <div style={{ height: "100%", width: "100%", textAlign: 'center' }}>
      <HelmetProvider>
        <Helmet>
          <title>Shop </title>
          <meta name="description" content="Shop  - Janma Nakshatra/Tithi Birthday Reminder Service." />
        </Helmet>
      </HelmetProvider>
      <SlidingHeader />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h2 style={{ textAlign: "center", fontWeight: "600", color: "black", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", marginTop: '50px', }}> Coming soon ...
      </h2>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <FooterComponent />

    </div>


  );
};

export default ShopPage;
