import React from "react";

const GanapathiHomamGallery = () => {
    // Dynamically import all images from the specific folder (images/gallery/Avahanti_Homam)
    const images = require.context('../../images/gallery/Ganapathy_Homam', false, /\.(png|jpe?g|svg|webp)$/);

    // Map the image paths
    const imageList = images.keys().map((fileName, index) => {
        const imagePath = images(fileName); // Get the image path
        return (
            <div key={index}>
                <img src={imagePath} alt={`Ganapathi_Homam Image ${index + 1}`} className="gallery-image" />
            </div>
        );
    });

    return (
        <div className="gallery-images">
            {imageList}
        </div>
    );
};


export default GanapathiHomamGallery