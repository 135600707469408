import React, { useState, useEffect } from 'react';
import {
    Grid, Paper, Typography, TextField, Button, Box, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SDadminHeader from './SDadminHeader';

const AdminEmailSender = () => {
    const [emailData, setEmailData] = useState({
        to: '',
        subject: '',
        content: '',
    });
    const [emailList, setEmailList] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // Fetch the list of emails from the backend when the component mounts
        const fetchEmails = async () => {
            try {
                const response = await fetch('https://backend.shubadinam.com/admin/fetchemailDetails');
                const result = await response.json();
                console.log(result);
                setEmailList(result); // Assuming 'result' is an array of emails
            } catch (error) {
                console.error('Error fetching email list:', error);
            }
        };

        fetchEmails();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value,
        });
    };

    const handleContentChange = (content) => {
        setEmailData({
            ...emailData,
            content,
        });
    };

    const handleSelectEmail = (email) => {
        // Update the 'To' field and store selected email details
        setEmailData({ ...emailData, to: email.userEmail });
        setSelectedEmail(email); // Store the selected email details
    };

    const filteredEmailList = emailList.filter((email) =>
        email.userName.toLowerCase().includes(searchQuery) ||
        email.userEmail.toLowerCase().includes(searchQuery)
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://backend.shubadinam.com/admin/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: emailData.to,
                    subject: emailData.subject,
                    content: emailData.content,
                }),
            });

            const result = await response.json();
            if (response.status === 200) {
                alert('Email Sent!');
                console.log('Email sent successfully:', result);
            } else {
                alert('Error sending email:', result.message);
                console.error('Error:', result);
            }
        } catch (error) {
            alert('Error sending email');
            console.error('Error:', error);
        }
    };




    return (
        <Box>
            <SDadminHeader />
            <Grid container justifyContent="space-evenly" alignItems="center" sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5', p: 3, mt: 6 }}>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                    {/* Sidebar displaying the email list */}
                    <Paper sx={{ padding: '1rem', minHeight: "500px", overflowy: "auto", width: 500 }} elevation={0}>
                        <Typography variant="h6" align="start" style={{ marginBottom: '1rem', }}>
                            Email List
                        </Typography>
                        {/* Search bar */}
                        <TextField
                            label="Search"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            sx={{ mb: 5 }}
                            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                            placeholder="Search by Name or Email"
                        />
                        {filteredEmailList.map((email, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                        <Typography>{index + 1}.</Typography>
                                        <Typography>{email.userName}</Typography>
                                        <Typography>{email.userEmail}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, bgcolor: "#eeeeee", padding: 1, borderRadius: 1 }}>
                                        <Typography>
                                            <b>Name:</b> {email.userName}
                                        </Typography>
                                        <Typography>
                                            <b>Email:</b> {email.userEmail}
                                        </Typography>
                                        <Typography>
                                            <b>Phone:</b> {email.userPhone}
                                        </Typography>
                                        <Typography>
                                            <b>Country:</b> {email.userCountry}
                                        </Typography>
                                        <Typography>
                                            <b>State:</b> {email.userState}
                                        </Typography>
                                        <Typography>
                                            <b>Type:</b> {email.userType}
                                        </Typography>
                                        <Typography>
                                            <b>Id:</b> {email.uniqueId}
                                        </Typography>
                                    </Box>

                                    <Box mt={2} display="flex" justifyContent="end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => handleSelectEmail(email)}
                                        >
                                            Compose
                                        </Button>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {/* Main form */}
                    <Paper style={{ padding: '2rem' }} elevation={0}>
                        <Typography variant="h5" align="start" style={{ marginBottom: '1rem', fontWeight: 'bold', color: '#333' }}>
                            Compose Email
                        </Typography>

                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="To"
                                name="to"
                                value={emailData.to}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                required
                            />
                            <TextField
                                label="Subject"
                                name="subject"
                                value={emailData.subject}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                required
                            />
                            <Typography variant="subtitle1" style={{ marginTop: '1rem' }}>
                                Content
                            </Typography>
                            <ReactQuill
                                value={emailData.content}
                                onChange={handleContentChange}
                                theme="snow"
                                style={{ marginBottom: '1rem' }}
                            />

                            <Box mt={3} display="flex" justifyContent="center">
                                <Button variant="contained" color="primary" type="submit">
                                    Send Email
                                </Button>
                            </Box>
                        </form>
                    </Paper>
                </Grid>
            </Grid>

        </Box>
    );
};

export default AdminEmailSender;
