import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

// css
import "./css/App.css";
import "./css/Tithi.css";
import "./css/ForgetPassword.css";
import "./css/Sidebar.css";
import "./css/Profile.css";
import './css/EmailForm.css';
import "./css/Pricing.css";
import "./css/admin.css";
import "./css/redirect.css";
import "./css/homam.css";
import "./css/shubadinamtv.css";

// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

import LoginScreen from "./screens/login";
import SingupScreen from "./screens/signup";


import HomeScreen from "./screens/home";
import AboutScreen from "./screens/about";
import FaqScreen from "./screens/faq";
import PrivacyPolicyScreen from "./screens/privacy-policy";
import TermsAndConditionsScreen from "./screens/terms-conditions";
import ContactScreen from "./screens/contact";
import MeaningsPage from "./screens/meaningsofNakshatra";
// import TestimonialScreen from "./screens/testimonials";

import DataSharingContext from "./context/data-sharing-context";
import SecondDataSharing from "./context/second-data-sharing";

import AdminPage from "./screens/admin-page";
import AdminAccess from "./screens/adminAccess";

// panchangam
import TamilPanchangam from "./DB Panchangam/tamilPanchangam.js";
import TeluguPanchangam from "./DB Panchangam/teluguPanchangam.js";
import KannadamPanchangam from "./DB Panchangam/KannadamPanchangam.js";
import MalayalamPanchangam from "./DB Panchangam/MalayalamPanchangam.js";
import HindiPanchangam from "./DB Panchangam/HindiPanchangam.js";
import GujaratiPanchangam from "./DB Panchangam/GujaratiPanchangam.js";
import BengaliPanchangam from "./DB Panchangam/Benglipanchangam.js";
import MarathiPanchangam from "./DB Panchangam/MarathiPanchangam.js"

// tithi
import TamilTithi from "./DB Tithi/TamilTithi.js";
import TelulguTithi from "./DB Tithi/TelulguTithi.js";
import KannadaTithi from "./DB Tithi/KannadaTithi.js";
import MalayalamTithi from "./DB Tithi/MalayalamTithi.js";
import HindiTithi from "./DB Tithi/HindiTithi.js";
import GujaratiTithi from "./DB Tithi/GujaratiTithi.js";
import BengaliTithi from "./DB Tithi/BengaliTithi.js";
import MarathiTithi from "./DB Tithi/MarathiTithi.js";

import VerifyEmailPage from "./screens/verify-email";
import EditEmailVerify from "./screens/EditEmailVerify";

import ProtectedRoute from "./screens/protectedroute";
import ForgotProtected from "./screens/ForgotProtected";

// Blogs
import BoxPage1 from "./screens/box1";
import BoxPage2 from "./screens/box2";
import BoxPage3 from "./screens/box3";
import BoxPage4 from "./screens/box4";
import BoxPage5 from "./screens/box5";
import BoxPage6 from "./screens/box6";
import BoxPage8 from "./screens/box8";
import BoxPage7 from "./screens/box7";

// Screens
import PageNotFound from "./screens/404-error";
import WhyUsPage from "./screens/why-us";
import BlogScreen from "./screens/BlogScreen";
import PasswordMail from "./screens/PasswordMail";
import ChangePassword from "./screens/ChangePassword";
import Verifyforgotpassword from "./screens/Verifyforgotpassword";
import RegisterPage from "./screens/RegisterPage";
import SigninPage from "./screens/SigninPage";
import ShopPage from "./screens/ShopPage";
import MyProfile from "./screens/MyProfile";
import Pricing from "./screens/Pricing.js";
import RefundPolicyPage from "./screens/RefundPolicy";
import AdminmailSend from "./screens/AdminmailSend";
import PremiumUsers from "./screens/PremiumUsers";
import NonPremiumUsers from "./screens/Non-PremiumUsers";
import ShippingDelivery from "./screens/ShippingDelivery";
import PricingPolicy from "./screens/PricingPolicy";
import RoughOne from "./screens/Rough/RoughOne";
import RoughTwo from "./screens/Rough/RoughTwo";
import RoughThree from "./screens/Rough/RoughThree";
import NakshatraBlogs from "./screens/NakshatraBlogs";
import TithiBlogs from "./screens/TithiBlogs";
import UserProfileDatabase from "./screens/databaseChanges/UserProfileDatabase";
import RoughFour from "./screens/Rough/RoughFour";

// admin
import AdminDashboard from "./admin/adminDashboard.js";
import AdminDatabase from "./admin/AdminDatabase.jsx";
import SignupVisitors from "./admin/SignupVisitors.jsx";
import LoginVisitors from "./admin/LoginVisitors.jsx";

// premium
import PremiumProfile from "./screens/Premium/PremiumProfile.jsx";
import PremiumCheckout from "./screens/Premium/PremiumCheckout.jsx";

// Redirection
import SignupRedirection from "./Redirection/SignupRedirection.jsx";
import PaymentSuccess from "./Redirection/PaymentSuccess.jsx";
import DateFinder from "./admin/DateFinder.jsx";
import ManageCustomer from "./admin/ManageCustomer.jsx";
import CustomerDetails from "./admin/CustomerDetails.jsx";
import PerCustomerDetails from "./admin/PerCustomerDetails.jsx";
import ContactInbox from "./admin/ContactInbox.jsx";
import InboxPerCustomer from "./admin/InboxPerCustomer.jsx";
import ContactSentItems from "./admin/ContactSentItems.jsx";
import SentPerPerson from "./admin/SentPerPerson.jsx";
import PremiumVisitors from "./admin/PremiumVisitors.jsx";
import Features from "./screens/Features.js";
import TotalCustomers from "./admin/TotalCustomers.jsx";
import AvahantiHomam from "./Homam/AvahantiHomam.jsx";
import GanapathiHomam from "./Homam/GanapathiHomam.jsx";
import SuryaNamaskaram from "./Homam/SuryaNamaskaram.jsx";
import NavagrahasuktaParatanam from "./Homam/NavagrahasuktaParatanam.jsx";
import AdminBillings from "./admin/AdminBillings.jsx";
import HomamDetails from "./Homam/HomamDetails.jsx";
import HomamPreview from "./Homam/HomamPreview.jsx";
import PackagesPage from "./screens/utils/PackagesPage.js";
import PlansPage from "./screens/utils/PlansPage.js";
import HomamCheckout from "./HomamPayment/HomamCheckout.jsx";
import PackagesPage2 from "./screens/utils/PackagesPage2.js";
import AllHomams from "./screens/AllHomams.js";
import ShubadinamTv from "./ShubadinamTv/ShubadinamTv.jsx";
import BookingsPage from "./Homam/BookingsPage.jsx";
import HistoryPage from "./Homam/HistoryPage.jsx";
import HomamAdmin from "./admin/HomamAdmin.jsx";
import HomamPurchasedReport from "./admin/HomamPurchasedReport.jsx";
import HomamBookersReport from "./admin/HomamBookersReport.jsx";
import HomamVisitors from "./admin/HomamVisitors.jsx";
import VirtualsevaInstructions from "./screens/VirtualsevaInstructions.jsx";
import HomampaymentSuccess from "./Redirection/HomampaymentSuccess.jsx";
import AdminPremiumupdate from "./admin/AdminPremiumupdate.jsx";
import UploadUtube from "./admin/UploadUtube.jsx";
import PremiumProtected from "./screens/Premium/PremiumProtected.jsx";
import UploadPanchangam from "./DB Panchangam/UploadPanchangam.jsx";
import UploadTithi from "./DB Tithi/UploadTithi.jsx";
import WebsiteViews from "./admin/WebsiteViews.jsx";
import RoughFive from "./screens/Rough/RoughFive.jsx";
import RoughSix from "./screens/Rough/RoughSix.jsx";
import RoughSeven from "./screens/Rough/RoughSeven.jsx";
import SevaLayout from "./homamutils/SevaLayout.jsx";
import DailypostGenerator from "./admin/DailypostGenerator.jsx";
import AdminEmailSender from "./admin/AdminEmailSender.jsx";
import TutorialComponent from "./screens/TutorialComponent.jsx";
import TutorialSteps from "./screens/utils/TutorialSteps.jsx";
import DownloadAll from "./admin/Downloaders/DownloadAll.jsx";
import GalleryScreen from "./screens/GalleryScreen.jsx";


const App = () => {

  const [globalVariable, UpdateDataShare] = useState([]);
  const globalFunction = (data) => {
    UpdateDataShare(data);
  };

  const [globalState, UpdateDataPass] = useState([]);
  const globalFunctionTwo = (data) => {
    UpdateDataPass(data);
  };

  return (

    <DataSharingContext.Provider value={{ globalFunction, globalVariable }}>
      <SecondDataSharing.Provider value={{ globalFunctionTwo, globalState }}>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<LoginScreen></LoginScreen>}></Route>



            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <SingupScreen></SingupScreen>
                </ProtectedRoute>
              }
            ></Route>

            {/* premium */}
            <Route path="profile-premium" element={<PremiumProfile></PremiumProfile>}></Route>
            <Route path="premium-checkout" element={
              <PremiumProtected>
                <PremiumCheckout></PremiumCheckout>
              </PremiumProtected>
            }></Route>


            <Route path="dashboard" element={
              <ProtectedRoute>
                <HomeScreen></HomeScreen>
              </ProtectedRoute>
            }></Route>
            <Route path="my-profile" element={
              <ProtectedRoute>
                <MyProfile></MyProfile>
              </ProtectedRoute>
            }></Route>

            <Route path="register" element={<RegisterPage></RegisterPage>}></Route>
            <Route path="signin" element={<SigninPage></SigninPage>}></Route>

            <Route path="111" element={<RoughOne></RoughOne>}></Route>
            <Route path="222" element={<RoughTwo></RoughTwo>}></Route>
            <Route path="333" element={<RoughThree></RoughThree>}></Route>
            <Route path="444" element={<RoughFour></RoughFour>}></Route>
            <Route path="555" element={<RoughFive />}></Route>
            <Route path="666" element={<RoughSix />}></Route>
            <Route path="777" element={<RoughSeven />}></Route>

            <Route path="about-us" element={<AboutScreen></AboutScreen>}></Route>
            <Route path="pricing" element={<Pricing></Pricing>}></Route>
            <Route path="gallery" element={<GalleryScreen />}></Route>

            {/* testing pages */}
            <Route path="features" element={<Features></Features>}></Route>
            <Route path="packages" element={<PackagesPage></PackagesPage>}></Route>
            <Route path="plans" element={<PlansPage></PlansPage>}></Route>
            <Route path="package" element={<PackagesPage2></PackagesPage2>}></Route>

            <Route path="faq" element={<FaqScreen></FaqScreen>}></Route>
            <Route path="blogs" element={<BlogScreen></BlogScreen>}></Route>
            <Route path="blogs-nakshatra" element={<NakshatraBlogs></NakshatraBlogs>}></Route>
            <Route path="blogs-tithi" element={<TithiBlogs></TithiBlogs>}></Route>
            <Route path="contact-us" element={<ContactScreen></ContactScreen>}></Route>
            <Route path="shipping" element={<ShippingDelivery></ShippingDelivery>}></Route>
            <Route path="privacypolicy" element={<PrivacyPolicyScreen></PrivacyPolicyScreen>}></Route>
            <Route path="termsandconditions" element={<TermsAndConditionsScreen></TermsAndConditionsScreen>}></Route>
            <Route path="refundandcancellationpolicy" element={<RefundPolicyPage></RefundPolicyPage>}></Route>
            <Route path="why-us" element={<WhyUsPage></WhyUsPage>}></Route>
            <Route path="pricing-policy" element={<PricingPolicy></PricingPolicy>}></Route>
            <Route path="meanings-of-janma-nakshatra" element={<MeaningsPage></MeaningsPage>}></Route>
            <Route path="forgotpassword" element={<PasswordMail></PasswordMail>}></Route>
            <Route path="change-password" element={<ForgotProtected><ChangePassword></ChangePassword></ForgotProtected>}></Route>

            <Route path="/tutorial" element={<TutorialComponent />} />
            <Route path="/tutorial/:tutorialName" element={<TutorialSteps />} />

            <Route path="/nakshatra-foods" element={<BoxPage1></BoxPage1>}></Route>
            <Route path="/nakshatra-characteristics" element={<BoxPage2></BoxPage2>}></Route>
            <Route path="/nakshatra-deities" element={<BoxPage3></BoxPage3>}></Route>
            <Route path="/nakshatra-likes-dislikes" element={<BoxPage4></BoxPage4>}></Route>
            <Route path="/tithi-foods" element={<BoxPage5></BoxPage5>}></Route>
            <Route path="/tithi-characteristics" element={<BoxPage6></BoxPage6>}></Route>
            <Route path="/tithi-deities" element={<BoxPage7></BoxPage7>}></Route>
            <Route path="/tithi-likes-dislikes" element={<BoxPage8></BoxPage8>}></Route>

            <Route path="/dashboard_thankyou" element={<SignupRedirection></SignupRedirection>}></Route>
            <Route path="/paymentsuccess" element={<PaymentSuccess></PaymentSuccess>}></Route>
            <Route path="/homam_thankyou" element={<HomampaymentSuccess></HomampaymentSuccess>}></Route>

            {/* HOMAM */}

            <Route path="/Avahanti-Homam-booking" element={<AvahantiHomam></AvahantiHomam>}></Route>
            <Route path="/Ganapathi-Homam-booking" element={<GanapathiHomam></GanapathiHomam>}></Route>
            <Route path="/Surya-Namaskaram-booking" element={<SuryaNamaskaram></SuryaNamaskaram>}></Route>
            <Route path="/Navagraha-Sukta-Parayanam-booking" element={<NavagrahasuktaParatanam></NavagrahasuktaParatanam>}></Route>


            <Route path="/homam-details" element={<HomamDetails></HomamDetails>}></Route>
            <Route path="/preview-homam" element={<HomamPreview></HomamPreview>}></Route>

            <Route path="/more-services" element={<AllHomams></AllHomams>}></Route>

            <Route path="/virtual-puja-seva" element={<VirtualsevaInstructions></VirtualsevaInstructions>}></Route>


            {/* Homam */}

            <Route path="/virtual-seva/:sevaname" element={<SevaLayout />}></Route>
            <Route path="/homam-checkout" element={<HomamCheckout></HomamCheckout>}></Route>

            {/* Shubadinam Tv */}

            <Route path="/shubadinam-tv" element={<ShubadinamTv></ShubadinamTv>}></Route>
            <Route path="/homam-bookings" element={<BookingsPage></BookingsPage>}></Route>
            <Route path="/homam-bookings-history" element={<HistoryPage></HistoryPage>}></Route>


            <Route path="verify/:id" element={<VerifyEmailPage></VerifyEmailPage>}></Route>
            <Route path="verifyforgetpassword/:id" element={<Verifyforgotpassword></Verifyforgotpassword>}></Route>
            <Route path="editemail/:id" element={<EditEmailVerify></EditEmailVerify>}></Route>
            <Route path="admin" element={<AdminAccess></AdminAccess>}></Route>

            {/* admin */}

            <Route path="admin-dashboard" element={<AdminDashboard></AdminDashboard>}></Route>
            <Route path="admin-today-views" element={<WebsiteViews />}></Route>
            <Route path="manage-customer" element={<ManageCustomer></ManageCustomer>}></Route>
            <Route path="manage-customers-details" element={<CustomerDetails></CustomerDetails>}></Route>
            <Route path="per-customer-detail" element={<PerCustomerDetails></PerCustomerDetails>}></Route>
            <Route path="contact/inbox" element={<ContactInbox></ContactInbox>}></Route>
            <Route path="contact/inbox/person-query" element={<InboxPerCustomer></InboxPerCustomer>}></Route>
            <Route path="contact/sent-items" element={<ContactSentItems></ContactSentItems>}></Route>
            <Route path="contact/sent-items/per-person" element={<SentPerPerson></SentPerPerson>}></Route>
            <Route path="monthly-billings" element={<AdminBillings></AdminBillings>}></Route>

            <Route path="homam-admin" element={<HomamAdmin></HomamAdmin>}></Route>
            <Route path="homam-purchased-report" element={<HomamPurchasedReport></HomamPurchasedReport>}></Route>
            <Route path="homam-customer-bookings" element={<HomamBookersReport></HomamBookersReport>}></Route>

            <Route path="admin/upload-youtube-videos" element={<UploadUtube></UploadUtube>}></Route>
            <Route path="admin/create-premium" element={<AdminPremiumupdate></AdminPremiumupdate>}></Route>
            <Route path="admin/generate-post" element={<DailypostGenerator />}></Route>
            <Route path="admin/compose-email" element={<AdminEmailSender />}></Route>
            <Route path="admin/download" element={<DownloadAll />}></Route>


            <Route path="admin-database" element={<AdminDatabase></AdminDatabase>}>

              <Route path="sendmail" element={<AdminmailSend></AdminmailSend>}></Route>
              <Route path="premiumusers" element={<PremiumUsers></PremiumUsers>}></Route>
              <Route path="non-premiumusers" element={<NonPremiumUsers></NonPremiumUsers>}></Route>
              <Route path="signup-visitors" element={<SignupVisitors></SignupVisitors>}></Route>
              <Route path="login-visitors" element={<LoginVisitors></LoginVisitors>}></Route>
              <Route path="premium-visitors" element={<PremiumVisitors></PremiumVisitors>}></Route>
              <Route path="homam-visitors" element={<HomamVisitors></HomamVisitors>}></Route>

              <Route path="total-customers" element={<TotalCustomers></TotalCustomers>}></Route>

              {/* panchangam db */}

              <Route path="upload-panchangam" element={<UploadPanchangam />}></Route>
              <Route path="upload-tithi" element={<UploadTithi />}></Route>

              <Route path="tamilpanchangam" element={<TamilPanchangam></TamilPanchangam>}></Route>
              <Route path="telugupanchangam" element={<TeluguPanchangam></TeluguPanchangam>}></Route>
              <Route path="kannadampanchangam" element={<KannadamPanchangam></KannadamPanchangam>}></Route>
              <Route path="malayalampanchangam" element={<MalayalamPanchangam></MalayalamPanchangam>}></Route>
              <Route path="gujaratipanchangam" element={<GujaratiPanchangam></GujaratiPanchangam>}></Route>
              <Route path="hindipanchangam" element={<HindiPanchangam></HindiPanchangam>}></Route>
              <Route path="bengalipanchangam" element={<BengaliPanchangam></BengaliPanchangam>}></Route>
              <Route path="marathipanchangam" element={<MarathiPanchangam></MarathiPanchangam>}></Route>

              <Route path="userdatabase" element={<UserProfileDatabase></UserProfileDatabase>}></Route>

              <Route path="tamiltithi" element={<TamilTithi></TamilTithi>}></Route>
              <Route path="telugutithi" element={<TelulguTithi></TelulguTithi>}></Route>
              <Route path="kannadamtithi" element={<KannadaTithi></KannadaTithi>}></Route>
              <Route path="malayalamtithi" element={<MalayalamTithi></MalayalamTithi>}></Route>
              <Route path="gujaratitithi" element={<GujaratiTithi></GujaratiTithi>}></Route>
              <Route path="hinditithi" element={<HindiTithi></HindiTithi>}></Route>
              <Route path="bengalitithi" element={<BengaliTithi></BengaliTithi>}></Route>
              <Route path="marathitithi" element={<MarathiTithi></MarathiTithi>}></Route>

              <Route path="date-finder" element={<DateFinder></DateFinder>}></Route>


            </Route>


            <Route path="adminpage" element={<AdminPage></AdminPage>}></Route>


            <Route path="shop" element={<ShopPage></ShopPage>}></Route>
            <Route path="*" element={<PageNotFound></PageNotFound>}></Route>
          </Routes>
        </BrowserRouter>
      </SecondDataSharing.Provider>
    </DataSharingContext.Provider>
  );
};
export default App;
