import React, { useState } from "react";
import { Box, Typography, Avatar, Grid, Paper, Button } from "@mui/material";
import { FormatQuote } from "@mui/icons-material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const testimonials = [
    {
        id: 1,
        name: "Priyakumari",
        message:
            "Shubadinam has transformed the way I celebrate my birthday. Embracing Indian traditions is giving a special feel on my birthday, sorry janmadin!",
        image:
            "https://placehold.co/400",
    },
    {
        id: 2,
        name: "Rahul",
        message:
            "I registered on Shubadinam last month for testing purpose. It was an eye-opening experience. The notification was very insightful. Highly recommend!",
        image:
            "https://placehold.co/400",
    },
    {
        id: 3,
        name: "Prashanth ",
        message:
            "It's a joy and a satisfying feeling to celebrate birthdays the Indian way. Thanks to Shubadinam for making it easy!",
        image:
            "https://placehold.co/400",
    },
    {
        id: 4,
        name: " Ananya",
        message:
            "As someone deeply connected to my Indian roots, I found Shubadinam very useful and definitely recommend it's a must-visit site for anyone seeking to preserve our Hindu identity!",
        image:
            "https://placehold.co/400",
    },
    // Add more testimonials here if needed
];

const SevalandingTestimonial = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 2) % testimonials.length);
    };

    const handlePrevious = () => {
        setCurrentIndex(
            (prevIndex) =>
                (prevIndex - 2 + testimonials.length) % testimonials.length
        );
    };

    // Handle showing two testimonials at once
    const displayTestimonials = [
        testimonials[currentIndex],
        testimonials[(currentIndex + 1) % testimonials.length],
    ];

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: { xs: 0, md: 6 },
            }}
        >
            <Box maxWidth="lg" px={{ xs: 0, md: 3 }} textAlign="center">
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        color: '#2c3e50',
                        mb: 2,
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    What Our Customers Say
                </Typography>
                <Typography variant="body1"
                    align="center"
                    sx={{
                        color: '#7f8c8d',
                        mb: 4,
                        fontStyle: 'italic',
                    }}>
                    Don’t take our word for it. Hear what our lovely customers are saying.
                </Typography>
                <Grid container justifyContent="center" spacing={4}>
                    {displayTestimonials.map((testimonial, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            key={testimonial.id}
                            display="flex"
                            alignItems="center"
                            flexDirection="row"
                        >
                            <Avatar
                                src={testimonial.image}
                                alt={`Profile picture of ${testimonial.name}`}
                                sx={{
                                    width: 60,
                                    height: 60,
                                    marginRight: 2,
                                }}
                            />
                            <Paper
                                elevation={3}
                                sx={{
                                    padding: 3,
                                    borderRadius: 3,
                                    position: "relative",
                                    backgroundColor: index % 2 === 0 ? "#1470bb" : "grey.200",
                                    color: index % 2 === 0 ? "white" : "text.secondary",
                                }}
                            >
                                <FormatQuote
                                    sx={{
                                        position: "absolute",
                                        top: 8,
                                        left: 8,
                                        fontSize: 30,
                                        color: index % 2 === 0 ? "white" : "primary.main",
                                    }}
                                />
                                <Typography variant="body1">{testimonial.message}</Typography>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    mt={2}
                                    color={index % 2 === 0 ? "white" : "text.primary"}
                                >
                                    {testimonial.name}
                                </Typography>
                                <FormatQuote
                                    sx={{
                                        position: "absolute",
                                        bottom: 8,
                                        right: 8,
                                        fontSize: 30,
                                        color: index % 2 === 0 ? "white" : "primary.main",
                                    }}
                                />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <Box mt={4} display="flex" justifyContent="center" gap={2}>
                    <Button variant="outlined" onClick={handlePrevious}>
                        <ArrowBackIosIcon />
                    </Button>
                    <Button variant="contained" onClick={handleNext}>
                        <ArrowForwardIosIcon />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default SevalandingTestimonial;
