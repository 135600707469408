/* global ShopifyBuy */ // Inform ESLint that ShopifyBuy is a global variable

import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardMedia, CardContent, Box, useMediaQuery, Button } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; // Import the default CSS for Splide
import { Skeleton } from '@mui/material'; // Import Skeleton component
import { NavLink } from 'react-router-dom';
import CardGiftcard from '@mui/icons-material/CardGiftcard';
import { useSwipeable } from 'react-swipeable';

const categories = [
    { name: 'Nicey', img: require("../images/shop/nicey.webp"), link: "https://shop.shubadinam.com/collections/nicey", description: "Thoughtful and memorable presents for every occasion." },
    { name: 'Kiddos', img: require("../images/shop/kiddos_shop.webp"), link: "https://shop.shubadinam.com/collections/kiddos", description: "Fun and educational gifts perfect for children." },
    { name: 'Utility', img: require("../images/shop/utility_shop.webp"), link: "https://shop.shubadinam.com/collections/utility", description: "Practical and useful items that everyone needs." },
    { name: 'Divine', img: require("../images/shop/nicey_shop.webp"), link: "https://shop.shubadinam.com/collections/divine", description: "Gifts that connect hearts to the divine." },
    { name: 'Gift Cards', img: require("../images/shop/giftcard_shop.webp"), link: "https://shop.shubadinam.com/collections/gift-cards", description: "Give the gift of choice so the recipient can choose what they desire." },

];

const ShopComponent = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:900px)');
    const [products, setProducts] = useState([]); // State to hold fetched products
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        // Load the Shopify Buy Button script
        const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

        const loadScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = scriptURL;
            script.onload = () => {
                fetchProducts(); // Fetch products after script is loaded
            };
            (document.head || document.body).appendChild(script);
        };

        const fetchProducts = async () => {
            const client = ShopifyBuy.buildClient({
                domain: '67cc47-90.myshopify.com',
                storefrontAccessToken: '184085a79bdc9a41b82921e03a10e1b0',
            });

            // Replace 'your-collection-id' with your actual collection ID
            const collectionId = 'gid://shopify/Collection/294958039092';

            try {
                const collection = await client.collection.fetchWithProducts(collectionId);
                const formattedProducts = collection.products.map((product) => ({
                    name: product.title,
                    img: product.images[0]?.src || 'https://via.placeholder.com/150',
                    link: product.onlineStoreUrl,
                    price: product.variants[0].price.amount
                }));

                setProducts(formattedProducts);
            } catch (error) {
                console.error("Error fetching products from collection:", error);
            }
        };

        // Load the script
        if (window.ShopifyBuy && window.ShopifyBuy.UI) {
            fetchProducts();
        } else {
            loadScript();
        }

        // Cleanup function
        return () => {
            const existingScript = document.querySelector(`script[src="${scriptURL}"]`);
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => console.log('Swiped Left'),
        onSwipedRight: () => console.log('Swiped Right'),
        trackMouse: true,  // Optional: track mouse events for swipe-like behavior on desktop
    });

    return (
        <Container maxWidth="lg" sx={{ py: 6, borderRadius: 2, }}>
            <Typography variant="h4"
                align="center"
                gutterBottom
                sx={{
                    fontWeight: 'bold',
                    color: '#2c3e50',
                    mb: 2,
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                }}>
                Gifting
            </Typography>
            <Typography variant="body1"
                align="center"
                sx={{
                    color: '#7f8c8d',
                    mb: 4,
                    fontStyle: 'italic',
                }}>On Shubadinam.com, we offer a wide range of handpicked gifts categorized to make your choices meaningful and easy!  <NavLink style={{ textDecoration: "none" }} to="https://shop.shubadinam.com/" target="_blank">View All Categories</NavLink></Typography>

            {/* <Box sx={{
                display: 'flex',
                overflowX: 'auto',  // Enables horizontal scrolling
                position: 'relative',
                width: '100%',
                background: '#053559',
                padding: 2,
                borderRadius: 1,
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
            }}>
                <Box sx={{
                    display: 'flex',
                    animation: isPaused ? 'none' : 'marquee 30s linear infinite',
                    gap: 2,
                    width: 'fit-content',
                }}
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}>
                    {[...categories, ...categories, ...categories, ...categories].map((category, index) => (
                        <Card
                            onClick={() => { window.open(category.link, '_blank'); }}  // Opens the link in a new tab
                            key={index}
                            sx={{
                                cursor: "pointer",
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                },
                                marginRight: 2,
                                width: 250,
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="150"
                                image={category.img}
                                alt={category.title}
                                sx={{
                                    borderTopLeftRadius: 2,
                                    borderTopRightRadius: 2,
                                    bgcolor: "#eeee"
                                }}
                            />
                            <CardContent sx={{ paddingBottom: 2, }}>
                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#333', fontSize: "1em" }}>
                                    {category.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.9rem' }}>
                                    {category.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>

            </Box> */}

            <Box sx={{
                display: 'flex',
                overflowX: 'auto',  // Enables horizontal scrolling
                position: 'relative',
                width: '100%',
                background: '#053559',
                padding: 2,
                borderRadius: 1,
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
                '&::-webkit-scrollbar': {
                    height: '8px', // Custom height of the scrollbar
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#38a1f7',  // Custom color for the thumb (the part that moves)
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#053559',  // Custom color for the track (the part the thumb moves along)
                },
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        width: 'fit-content',
                    }}
                    {...swipeHandlers} // Attach swipeable handlers here
                >
                    {[...categories].map((category, index) => (
                        <Card
                            onClick={() => { window.open(category.link, '_blank'); }}  // Opens the link in a new tab
                            key={index}
                            sx={{
                                cursor: 'pointer',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                },
                                marginRight: 2,
                                width: 200,
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="150"
                                image={category.img}
                                alt={category.title}
                                sx={{
                                    borderTopLeftRadius: 2,
                                    borderTopRightRadius: 2,
                                    bgcolor: "#eeee",
                                }}
                            />
                            <CardContent sx={{ paddingBottom: 2 }}>
                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#333', fontSize: '1em' }}>
                                    {category.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.9rem' }}>
                                    {category.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Box>

            <Typography variant="h5" component="h3" align="center" sx={{ mt: 4, fontWeight: 'bold', color: "black" }}>
                Not sure what to gift?
            </Typography>
            <Typography variant="body1"
                align="center"
                sx={{
                    color: '#7f8c8d',
                    mb: 4,
                    fontStyle: 'italic',
                }}>
                Buy gift cards. Give the gift of choice so the recipient can choose what they want.
            </Typography>

            <Splide
                options={{
                    type: 'slide',
                    perPage: isSmallScreen ? 2 : isMediumScreen ? 2 : 4,
                    gap: '1rem',
                    arrows: false,
                    pagination: true,
                    autoplay: false,
                    interval: 3000,
                    perMove: 1,
                }}
            >
                {products.length === 0 ? (
                    // Skeleton loading if products are empty
                    Array.from({ length: 4 }).map((_, index) => (
                        <SplideSlide key={index}>
                            <Card sx={{ width: 250, borderRadius: 2 }}>
                                <Skeleton variant="rectangular" width="100%" height={150} />
                                <CardContent sx={{ paddingTop: 2 }}>
                                    <Skeleton variant="text" width="80%" height={24} sx={{ marginBottom: 1 }} />
                                    <Skeleton variant="rectangular" width="80%" height={36} />
                                </CardContent>
                            </Card>
                        </SplideSlide>
                    ))
                ) :
                    (
                        products.map((product, index) => (
                            <SplideSlide key={index}>
                                <a href={product.link} target='_blank' style={{ textDecoration: "none !important", }}>
                                    <Card
                                        sx={{
                                            // width: 250,
                                            textDecoration: "none !important",
                                            borderRadius: 2,
                                            // boxShadow: 2,
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="150"
                                            image={product.img}
                                            alt={product.name}
                                            sx={{
                                                textDecoration: "none !important",
                                                borderTopLeftRadius: 2,
                                                borderTopRightRadius: 2,
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <CardContent
                                            sx={{
                                                textDecoration: "none !important",
                                                paddingTop: 2,
                                                borderBottom: "1px solid grey",
                                                borderBottomLeftRadius: 2,
                                                borderBottomRightRadius: 2,
                                                bgcolor: "#eeeeee21"
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                sx={{
                                                    textDecoration: "none !important",
                                                    color: '#333',
                                                    marginBottom: 1,
                                                }}
                                            >
                                                {product.name}
                                                {/* - <Typography
                                                variant="body5"
                                                component="span"
                                                sx={{
                                                    color: 'grey',
                                                    marginBottom: 1,
                                                    fontSize: "1em"
                                                }}
                                            >
                                                ₹{product.price} /-
                                            </Typography> */}
                                            </Typography>

                                            <Button
                                                size='small'
                                                startIcon={<CardGiftcard />}
                                                href={product.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                variant="contained"
                                                sx={{
                                                    p: 1,
                                                    color: '#fff',
                                                    borderRadius: 2,
                                                    textTransform: 'none', // Keeps the text case as is
                                                    '&:hover': {
                                                        color: '#fff',
                                                    }
                                                }}
                                            >
                                                {/* <Typography variant="body2" sx={{ textDecoration: 'none', color: "white" }}> */}
                                                View Gift
                                                {/* </Typography> */}
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </a>
                            </SplideSlide>
                        ))
                    )
                }
            </Splide>

            <style>
                {`
                    @keyframes marquee {
                        0% {
                            transform: translateX(0);
                        }
                        100% {
                            transform: translateX(-50%);
                        }
                    }
                `}
            </style>
        </Container>
    );
};

export default ShopComponent;
