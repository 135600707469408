import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Card, CardContent, CircularProgress, Grid, Box, Divider, Avatar, Button, CardMedia } from '@mui/material';
import { Share, Favorite, Comment } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import StorefrontIcon from '@mui/icons-material/Storefront';

function TwitterFeed() {
    const [tweets, setTweets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch data from the backend API
        axios.get('https://backend.shubadinam.com/tweets')
            .then((response) => {
                setTweets(response.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setError('Failed to load tweets');
                setLoading(false);
            });
    }, []);

    if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
    if (error) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><Typography variant="h6" color="error">{error}</Typography></Box>;

    return (
        <Box sx={{ bgcolor: "#eeeeeea1", p: { xs: 0, md: 2 } }}>
            <Container maxWidth="lg" >


                <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#1DA1F2' }}>
                    Daily Feeds
                </Typography>
                <Typography variant="body1" color="textSecondary" align="center" >
                    Stay updated with the Shubadinam trends and news!
                </Typography>

                <Box
                    sx={{
                        background: 'linear-gradient(to right, #f3e7e9, #e3eeff, #f3e7e9)', // Light and subtle gradient
                        padding: 1,
                        mt: 2,
                        borderRadius: 3,
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <marquee
                        behavior="scroll"
                        direction="left"
                        style={{
                            color: 'black',
                            letterSpacing: '0.5px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant='body5'
                            sx={{
                                mr: 4,
                                color: "red"
                            }}
                        >
                            *** Upcoming Events ***
                        </Typography>
                        <Typography
                            variant='body5'
                            sx={{
                                mr: 4,
                            }}
                        >
                            <EventIcon sx={{ mr: 1, fontSize: "medium" }} />
                            03-Jan-2025: Avahanti Homam
                        </Typography>
                        <Typography
                            variant='body5'
                            sx={{
                                mr: 4,
                            }}
                        >
                            <EventIcon sx={{ mr: 1, fontSize: "medium" }} />
                            05-Jan-2025: Ganapathi Homam
                        </Typography>
                        <Typography
                            variant='body5'
                            sx={{
                                mr: 4,
                            }}
                        >
                            <EventIcon sx={{ mr: 1, fontSize: "medium" }} />
                            10-Jan-2025: Surya Namaskaram
                        </Typography>
                        <Typography
                            variant='body5'
                            sx={{
                                mr: 4,
                            }}
                        >
                            <StorefrontIcon sx={{ mr: 1, fontSize: "medium" }} />
                            Exclusive Gifts & Shops:{' '}
                            <a
                                href="https://shop.shubadinam.com"
                                style={{
                                    color: 'royalblue',
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Shubadinam Shoppe | shop.shubadinam.com
                            </a>
                        </Typography>
                    </marquee>
                </Box>

                {/* Promotional Banner Section */}
                <Grid container spacing={2} sx={{ padding: 2 }}>
                    {/* Hero Section */}
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: 200,
                                background: 'url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwcICAgIBwcGBgcHCAoHBwYHBg8ICQcKFREWIhURExMYHCggGBolGxMfITEhJSkrLi4uFx8zODMsNygtLisBCgoKDQ0NDw8NDisZFRkrKzctKysrKysrKzctLS0tKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAKgBLAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQQH/8QAFRABAQAAAAAAAAAAAAAAAAAAAAH/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAgEF/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhEDEQA/AOGqQUCoqgAioxQIqCgLgAqoACoKAuMAFAoNgAqoACwUGwAFMUBUAFUADQAAABlAcCLWAKgKiqjBUVUABYKCoCpFVGAEXABWgAqAoLgKiqgAKjBQVABVAA0AAAAAAZQWOBFgC4wigqBFBUABcFAVAUFRgAqAsRVQAVUABYsAVAWIqowAVBQFAAAAAAAADKqK4MWAKYoCoKAuAqKqAQFQUBcYQBQoCoCoqoCxFXAAUxYAqAqKoAGgAAAAAAADKqRXCWEFVGBAVBQFwFRVQFRVRgBFwAVUABUFgCoCkFwAVUYAKgoCgAAAAAAAAABmgDhRYqKqMFRVwAFQUBUCKirjABQLEVUABUFAWKAqAqKqMFRVgA0AAAAAAAAAAZgHCixUVUYKirgAKgoC4CoqowAVAVFVAVFi4ALFQAFxiwBUBUVQANAAAAAAAAAAGYBwosVIqowipFXAAVBQFwUBUYALgoCoCxFioCxFi4ACoxQFwIpBoANAAAAAAAAAAGYBwooipFXAipFVAIEXBQFQUBUYQBcFgQVAVFVAWIsXAAVGKAuCwIKAAAAAAAAAAAAGYBwosioq4wipFVAICoKAuCgKjCALgsAVAVFioCxFi4ACoxQFwWBBQAAAAAAAAAAAAzAOFFigqMIoLgAKgoC4KAqMAFwUBUBYCoCwFwAFRigLgRQaADQAAAAAAAAAB//Z) no-repeat center center',
                                backgroundSize: 'cover',
                                borderRadius: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    zIndex: 1,
                                    color: 'white',
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 'bold',
                                        letterSpacing: 1,
                                        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '2rem' }, // Responsive font sizes
                                        textAlign: { xs: 'center', sm: 'center', md: 'left' }, // Adjust alignment for screen sizes
                                    }}
                                >
                                    Daily Feeds from Shubadinam
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        mt: 2,
                                        letterSpacing: 0.5,
                                        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Responsive font sizes
                                        textAlign: { xs: 'center', sm: 'center', md: 'left' }, // Adjust alignment for screen sizes
                                    }}
                                >
                                    Explore updates, live events, and announcements.
                                </Typography>

                            </Box>
                        </Box>
                    </Grid>

                    {/* Daily Feeds */}
                    <Grid item xs={12} md={8}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                            From (Twitter) 𝕏
                        </Typography>
                        <Grid container spacing={2}>
                            {/* Tweets Section */}
                            {tweets.map((tweet) => (
                                <Grid item xs={12} key={tweet.id}>
                                    <Card elevation={3} sx={{
                                        borderRadius: 2,
                                        border: '1px solid #e1e8ed',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': { boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', },
                                        maxWidth: 600,
                                        margin: 'auto'
                                    }}>
                                        <CardContent>
                                            {/* User Profile and Tweet */}
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <a href='https://twitter.com/Shubadinam' target='_blank' style={{ textDecoration: "none", display: 'flex', alignItems: 'center' }}>
                                                    <img src={require("../../images/New Shubadinam Logo/Shubadinam Star logo.png")} style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                                        <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 'bold' }}>
                                                            Shubadinam
                                                        </Typography>
                                                        <Typography variant="body5" color="textSecondary" component="div" >
                                                            @Shubadinam
                                                        </Typography>
                                                    </Box>
                                                </a>
                                            </Box>
                                            <Typography variant="body1" paragraph sx={{ mt: 2, fontSize: '1rem', color: "royalblue" }}>
                                                #ShubaDinamMoments
                                            </Typography>
                                            <Typography variant="body1" paragraph sx={{ mt: 2, fontSize: '1rem' }}>
                                                {tweet.text}
                                            </Typography>

                                            {/* Media Handling */}
                                            {tweet.media && tweet.media.length > 0 && (
                                                <Box >
                                                    {tweet.media.map((url, index) => {
                                                        const mediaType = tweet.media[index].type;
                                                        if (mediaType === 'video') {
                                                            return (
                                                                <Box key={index} mb={2} >
                                                                    <video controls style={{ width: '100%', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                                                        <source src={url} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                </Box>
                                                            );
                                                        }

                                                        return (
                                                            <Box key={index} mb={2}>
                                                                <img src={url} alt="media" style={{ width: '100%', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', objectFit: "contain" }} />
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            )}

                                            <Divider sx={{ my: 2 }} />

                                            {/* Engagement Metrics */}
                                            {tweet.public_metrics && (
                                                <a href='https://twitter.com/Shubadinam' target='_blank' style={{ textDecoration: "none" }}>
                                                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-around' }} >
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Share sx={{ mr: 1, color: 'primary.main' }} />
                                                            <Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                                                {tweet.public_metrics.retweet_count}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Favorite sx={{ mr: 1, color: 'error.main' }} />
                                                            <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>
                                                                {tweet.public_metrics.like_count}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Comment sx={{ mr: 1, color: 'grey' }} />
                                                            <Typography sx={{ color: 'grey', fontWeight: 'bold' }}>
                                                                {tweet.public_metrics.reply_count}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </a>
                                            )}

                                            {/* Follow Button */}
                                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    href={`https://twitter.com/Shubadinam`}
                                                    target="_blank"
                                                    sx={{
                                                        '&:hover': {
                                                            color: "white",
                                                        },
                                                        width: '50%',
                                                        textTransform: 'none',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    Join Us
                                                </Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    {/* Sidebar */}
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                            Announcements
                        </Typography>
                        {[1, 2].map((announcement) => (
                            <Card
                                sx={{ mb: 2, boxShadow: '0px 4px 10px rgba(0,0,0,0.1)', borderRadius: 2 }}
                                key={announcement}
                            >
                                <CardContent>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Announcement {announcement}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                                        Important details about the announcement go here.
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </Grid>

            </Container>
        </Box>
    );
}

export default TwitterFeed;

// import React from 'react'

// const RoughFive = () => {
//     return (
//         <div>RoughFive</div>
//     )
// }

// export default RoughFive
