import React from "react";
import { useEffect } from "react";
import FooterComponent from "../components/footer";
import ScrollToTop from "react-scroll-to-top";

import { Helmet, HelmetProvider } from 'react-helmet-async';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Box,
  IconButton,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageSlider from "./utils/PageSlider";
import SlidingHeader from "../components/SlidingHeader";


const FaqScreen = () => {

  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);


  const sd_faq = [
    {
      question: "What is the difference between Birthday as per English Calendar and Janma Dinam as per Indian Calendar?",
      answer:
        "Birthday as per the English calendar only takes into account the rotation of the earth around the Sun, which is 365 days. In the case of Janma Dinam as per the Indian calendar, we take into account the position of multiple celestial bodies like the Sun, Moon, Earth, Stars, and Planets, etc., which are also constantly rotating on different axes. Hence, it is more precise. The effects of these celestial bodies on the earth and its beings are recognized by both science and our Shastras. For example, the tides in the sea are high on specific days like Poornima and Amavasya, etc. You can find more articles about this in our blogs section."
    },
    {
      question: "Why birthday (Janma Dinam) as per the Indian calendar is considered a Shuba Dinam to celebrate?",
      answer:
        "Birthday as per the Indian Calendar falls on the day when many celestial bodies are on the same constellation as at the time of our birth. Hence, it is believed that the blessings received on that day from elders and gods are more concentrated. The effects of good karma done on that day multiply as compared to other common days. For example, a paper placed under the Sun may heat and not burn. But when a Magnifying lens is placed in between, the same paper may catch fire due to the concentrated stream of sun rays. You can find more articles about the effects of Nakshatras and Tithis on our personality in our blogs section."
    },
    {
      question: "What is Shubadinam.com?",
      answer: "Shubadinam.com is a unique online platform that helps you get notified easily about the Birth Star (Janma Nakshatra) or Birth Tithi (Janma Tithi) of your family and friends every year. It also provides insights into the significance of Nakshatra or Tithi in one's life journey."
    },
    {
      question: "How does Shubadinam.com work?",
      answer:
        "After free registration, you can add Unlimited Janma Dinam details of your Family and Friends. After this one-time setup, you will receive Notifications through Email, SMS, or WhatsApp as applicable."
    },
    {
      question: "Is Shubadinam.com a free service?",
      answer:
        "Absolutely! The basic services of Shubadinam.com are completely free to use. Our aim is to empower individuals to embrace and celebrate their birth star heritage. To make your celebrations convenient, we may provide some value-added services in the future that you can choose to buy if interested."
    },
    {
      question: "Why Shubadinam.com?",
      answer:
        "At Shubadinam, we're more than just a website. We're here to help you rediscover your roots and celebrate your Hindu identity. Imagine us as your guide on a journey to the ancient wisdom that's part of your heritage. We're not only about dates. Shubadinam shows you the magic of Birth Star (Janma Nakshatra)/Janma Tithi. It's like unlocking secrets of the stars on the day you were born. These cosmic constellations are a part of you, and we're here to help you celebrate your special day, your Janmadinam. It's not just about the past, it's about making tradition a part of your daily life. We're a community that connects people who love their Hindu heritage. Join us at Shubadinam to celebrate who you are, learn about your roots, and light up your unique cosmic spark."
    }
  ];

  const birthStar_faq = [
    {
      id: "panel7a",
      question: "What is a birth star (Janma Nakshatra)?",
      answer: "It is believed, a birth star (Janma Nakshatra), is a celestial entity representing the moon's position in the sky at the time of your birth. Each Nakshatra holds distinct qualities and influences that shape your personality and life path."
    },
    {
      id: "panel8a",
      question: "How does my birth star impact me?",
      answer: "It is believed, your birth star has a significant impact on various aspects of your life, including character traits, strengths, and challenges. Understanding your birth star can provide valuable insights into your nature and potential."
    },
    {
      id: "panel9a",
      question: "Can I have multiple birth stars?",
      answer: "No, each individual has one primary birth star based on their birth time."
    },
    {
      id: "panel10a",
      question: "What if the birth star comes twice in a month?",
      answer: (
        <>
          According to Dharma Shastras, the birth day must be celebrated every month in the 1st year of birth.
          <ol>
            <li>If the birth star comes on 2 days in a solar month, the second one only must be considered as the birth day.</li>
            <li>If the birth star is on 2 days (starting from the previous day to the next day), the next day only must be considered. The sunrise coming on the day of the star determines the birth day.</li>
            <li>Even if the star is there for a few minutes on the next day, only the next day is considered as the birth day.</li>
            <li>If the birth star is there in the next day for less than 8 minutes, only then the previous day must be considered.</li>
            <li>If the birth star does not happen to be there at the time of sunrise on any day (previous day or the next morning), then one must celebrate it in the next solar month.</li>
          </ol>
          <br />
          For more information, please read this blog post{' '}
          <a
            href="https://jayasreesaranathan.blogspot.com/2009/04/how-to-celebrate-birthday.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            jayasreesaranathan.blogspot.com
          </a>
        </>
      )
    },
    {
      id: "panel11a",
      question: "Is it okay to celebrate birthday on the day of the Nakshatra?",
      answer: "Yes, it is considered auspicious to celebrate your birthday (Janma Dinam) on the day of your Nakshatra. It is believed that celebrating your birth star enhances the blessings and energies associated with that celestial body."
    }
  ];

  const birthTithi_faq = [
    {
      question: "What is a birth Tithi?",
      answer: "It is believed, a birth Tithi is a lunar day calculated from the moon's position in relation to the sun at the time of your birth. Tithis hold spiritual significance and guide timing for various rituals and events."
    },
    {
      question: "How does my birth Tithi matter?",
      answer: "It is believed, your birth Tithi is a reflection of the moon phase on the day you were born. It provides insights into your individual characteristics and helps you align with the moon cycles."
    },
    {
      question: "Should I celebrate Janma Nakhshatra or Janma Tithi?",
      answer: "You can celebrate the birthday (Janma dinam) according to either Birth Star (Nakshatra) or Birth Tithi (Janma Tithi). Both are meaningful ways to connect with cosmic rhythms. It is purely as per traditional practices in the family. We recommend confirming the same with elders in the family. For example, some parts of Andhra Pradesh celebrate Janma Tithi, while other parts of the same state celebrate Janma Nakshatra."
    }
  ];


  const accordionStyles = {
    backgroundColor: "aliceblue",
    fontSize: "1.1rem",
    fontWeight: "700",
    color: "primary"
  };

  const iconButtonStyles = {
    size: "small"
  };

  const SdAccordion = ({ question, answer, index }) => (
    <Box sx={{ mb: index === sd_faq.length - 1 ? 4 : 2 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<IconButton sx={iconButtonStyles}><ExpandMoreIcon /></IconButton>}
          sx={accordionStyles}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <Typography sx={{ fontWeight: "700" }}>{question}</Typography>
        </AccordionSummary>
        <AccordionDetails role="region" aria-labelledby={`panel${index}-header`}>
          <Typography>{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );




  return (

    <Box >
      <HelmetProvider>
        <Helmet>
          <title>FAQs </title>
          <meta name="description" content="FAQs  - Janma Nakshatra/Tithi Birthday Reminder Service." />
        </Helmet>
      </HelmetProvider>
      <ScrollToTop smooth />

      <SlidingHeader />

      {/* Hero Section */}
      <PageSlider
        bgImage={require("../images/banners/all_hero_banner.jpg")}
        pageTitle={`FAQs`}

      />

      <Container maxWidth="md">
        <br />
        {sd_faq.map((item, index) => (
          <SdAccordion key={index} question={item.question} answer={item.answer} index={index} />
        ))}

        <Typography variant="h5" color="primary" sx={{ fontWeight: "bold", p: 2 }} align="start" gutterBottom>
          Birth Star FAQs:
        </Typography>

        {birthStar_faq.map((item, index) => (
          <SdAccordion key={index} question={item.question} answer={item.answer} index={index} />
        ))}

        <Typography variant="h5" color="primary" sx={{ fontWeight: "bold", p: 2 }} align="start" gutterBottom>
          Birth Tithi FAQs:
        </Typography>

        {birthTithi_faq.map((item, index) => (
          <SdAccordion key={index} question={item.question} answer={item.answer} index={index} />
        ))}
      </Container>


      <FooterComponent></FooterComponent>

    </Box >

  )
}
export default FaqScreen;






