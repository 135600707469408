import React, { useState } from "react";
import {
    Button,
    Typography,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";
import FooterComponent from "../components/footer";

const features = [
    {
        title: "Free_Sign_Up",
        image: require(`../images/tutorial/home.png`)
    },
    // {
    //     title: "Shubadinam Shop",
    //     image:
    //         "https://img.freepik.com/premium-vector/trendy-popup-shop-features-contemporary-design-with-large-windows-vibrant-striped-awning-trendy-popup-shop-with-clean-contemporary-logo-minimalist-simple-modern-vector-logo-design_1407614-684.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid",
    // },
    {
        title: "Premium_Sign_Up",
        image: require(`../images/tutorial/premium.png`)
    },
    // {
    //     title: "Virtual Seva",
    //     image:
    //         "https://img.freepik.com/premium-photo/view-decorated-durga-puja-indian-festival_612827-66676.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid",
    // },
    // {
    //     title: "Sign Up",
    //     image:
    //         "https://img.freepik.com/premium-vector/blue-white-picture-two-people-with-phone-that-says-log-cabin_1314854-10470.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid",
    // },
    // {
    //     title: "Wish Wall",
    //     image:
    //         "https://img.freepik.com/free-photo/american-colors-household-decorations-independence-day-celebration_23-2151469785.jpg?uid=R133306793&ga=GA1.1.1773690977.1730112906&semt=ais_hybrid",
    // },
];

const TutorialComponent = () => {
    const navigate = useNavigate();

    const handleCardClick = (feature) => {
        navigate(`/tutorial/${feature}`);
    };

    return (
        <Box>
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle="Tutorial"
            />

            <Box sx={{ p: 5, mb: 8 }}>
                <Grid container spacing={3}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                sx={{
                                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "15px",
                                    overflow: "hidden",
                                    transition: "transform 0.3s, box-shadow 0.3s",
                                    "&:hover": {
                                        transform: "scale(1.01)",
                                        boxShadow: 10,
                                    },
                                }}
                                onClick={() => handleCardClick(feature.title)}
                            >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="160"
                                        image={feature.image}
                                        alt={feature.title}
                                        sx={{
                                            objectFit: "cover",
                                        }}
                                    />
                                    <CardContent sx={{ textAlign: "center" }}>
                                        <Typography variant="h6" fontWeight="bold">
                                            {feature.title}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <FooterComponent />
        </Box>
    );
};

export default TutorialComponent;
