import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { format } from 'date-fns';


import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import { ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});


const DashboardHeader = () => {

    const [dashsignOut, setSignOut] = useState(false);
    const [isLesser992, setisLesser992] = useState(false);

    useEffect(() => {
        AOS.refresh();

        const auth = localStorage.getItem("isAuthenticated");
        if (auth) {
            setSignOut(true)
        }

        const handleResize = () => {
            if (window.innerWidth < 992) {
                setisLesser992(true);
            } else {
                setisLesser992(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = useNavigate();

    // dashboard signout
    const signuptoSignout = () => {
        setSignOut(false)
        localStorage.clear();
        sessionStorage.clear();
        navigate("/")
    }

    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const handleMobileNavToggle = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const handleDropdownClick = (e) => {
        e.preventDefault();
        if (isMobileNavOpen && e.target.nextElementSibling) {
            e.target.nextElementSibling.classList.toggle('dropdown-active');
        }
    };

    const [birthdayCount, setBirthdayCount] = useState(0);
    const [notificationNames, setNotificationNames] = useState([]);
    const [invisible, setInvisible] = useState();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // Function to fetch data from the backend and update birthdayCount state
        async function fetchBirthdayData() {
            try {

                const testdata = localStorage.getItem("fkey");
                const email = atob(testdata).replace(/"/g, ''); // Decode and then remove quotes

                const url = `https://backend.shubadinam.com/api/getRelativeBirthdays?userEmail=${email}`;

                axios.get(url)
                    .then((response) => {
                        const data = response.data;

                        // Get the date today
                        const today = new Date();

                        // Calculate the date 7 days after today
                        const sevenDaysAfter = new Date();
                        sevenDaysAfter.setDate(today.getDate() + 7);

                        // Filter the data based on the condition
                        const filteredData = data.filter(item => {
                            // Convert relative birthday string to Date object
                            const birthday = item.relDate
                            const formattedToday = format(today, "dd-MM-yyyy");
                            const formattedSevenDaysAfter = format(sevenDaysAfter, "dd-MM-yyyy");

                            // Check if the formatted birthday falls within the range
                            return birthday >= formattedToday && birthday <= formattedSevenDaysAfter;
                        });

                        // Update state with filtered data
                        setNotificationNames(filteredData);
                        setBirthdayCount(filteredData.length);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }


        fetchBirthdayData();
    }, []); // Empty dependency array to run the effect only once on component mount

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);

    const handleRouting = (page) => {
        // window.location.href = `/${page}`; // Set the URL to navigate to
        navigate(`/${page}`)
    }

    return (
        <>
            <header id="header" className={`fixed-top d-flex align-items-center header-mobile-open`}>
                <div className="container d-flex justify-content-between align-items-center">


                    <div className="logo">
                        <NavLink to="/">
                            <img src={require("../images/New Shubadinam Logo/Shubadinam White.png")} onContextMenu={(e) => e.preventDefault()} />
                        </NavLink>
                    </div>


                    <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
                        <ul>

                            <div>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <ol>
                                        {notificationNames.map((notification, index) => (

                                            <li key={index}>
                                                <MenuItem onClick={handleClose}>
                                                    {notification.relName}'s birthday falls on '{notification.relDate}'.
                                                </MenuItem>
                                            </li>
                                        ))}

                                    </ol>
                                </Menu>
                            </div>


                            {
                                isLesser992 ?
                                    <>



                                        <li><NavLink to="/my-profile" className={({ isActive }) => isActive ? "active" : ""}>My Account</NavLink></li>
                                        <li><NavLink to="/shubadinam-tv">Shubadinam TV<YouTubeIcon sx={{ mr: "50%" }} fontSize="small" color="error" /></NavLink></li>
                                        <li><NavLink to="/pricing">Premium</NavLink></li>

                                        <li><NavLink to="/virtual-puja-seva">Virtual Puja</NavLink></li>
                                        <li><NavLink to="/gallery">Gallery</NavLink></li>
                                        <li> <a href="https://shop.shubadinam.com/" target="_blank" rel="noopener noreferrer">Gift Shoppe</a></li>
                                        <li className="dropdown">
                                            <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
                                            <ul>
                                                <li className="dropdown">
                                                    {/* <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a> */}
                                                    <ul>
                                                        <li><a href="#">Deep Drop Down 1</a></li>
                                                        <li><a href="#">Deep Drop Down 2</a></li>
                                                        <li><a href="#">Deep Drop Down 3</a></li>
                                                        <li><a href="#">Deep Drop Down 4</a></li>
                                                        <li><a href="#">Deep Drop Down 5</a></li>
                                                    </ul>
                                                </li>
                                                <li><NavLink to="/about-us">About Us</NavLink></li>
                                                <li><NavLink to="/faq">FAQs</NavLink></li>
                                                <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                                <li><NavLink to="/why-us">Why Us</NavLink></li>
                                                <li><NavLink to="/contact-us">Contact Us</NavLink></li>

                                            </ul>

                                        </li>


                                        {/* <li><NavLink to="/about-us" className={({ isActive }) => isActive ? "active" : ""}>About Us</NavLink></li>
                                        <li><NavLink to="/faq">FAQs</NavLink></li>
                                        <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                        <li><NavLink to="/why-us">Why Shubadinam</NavLink></li>
                                        <li><NavLink to="/contact-us">Contact Us</NavLink></li> */}

                                        <li><NavLink to="/homam-bookings">My Bookings<EventAvailableRoundedIcon sx={{ mr: "50%" }} fontSize="small" /></NavLink></li>
                                        <li><NavLink to="/homam-bookings-history">Payment History<HistoryIcon sx={{ mr: "50%" }} fontSize="small" /></NavLink></li>

                                        {dashsignOut ?
                                            <li><NavLink to="/register" className="link">Sign Up</NavLink></li>

                                            :
                                            <li><a href="/" onClick={() => signuptoSignout()}>Sign Out</a></li>

                                        }

                                    </>

                                    :

                                    <>
                                        <li><NavLink to="/shubadinam-tv">Shubadinam TV<YouTubeIcon sx={{ ml: 1 }} fontSize="small" color="error" />
                                        </NavLink></li>

                                        <li><NavLink to="/pricing">Premium</NavLink></li>

                                        <li><NavLink to="/virtual-puja-seva">Virtual Puja</NavLink></li>
                                        <li><NavLink to="/gallery">Gallery</NavLink></li>
                                        <li> <a href="https://shop.shubadinam.com/" target="_blank" rel="noopener noreferrer">Gift Shoppe</a></li>
                                        <li className="dropdown">
                                            <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
                                            <ul>
                                                <li className="dropdown">
                                                    {/* <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a> */}
                                                    <ul>
                                                        <li><a href="#">Deep Drop Down 1</a></li>
                                                        <li><a href="#">Deep Drop Down 2</a></li>
                                                        <li><a href="#">Deep Drop Down 3</a></li>
                                                        <li><a href="#">Deep Drop Down 4</a></li>
                                                        <li><a href="#">Deep Drop Down 5</a></li>
                                                    </ul>
                                                </li>
                                                <li><NavLink to="/about-us">About Us</NavLink></li>
                                                <li><NavLink to="/faq">FAQs</NavLink></li>
                                                <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                                <li><NavLink to="/why-us">Why Us</NavLink></li>
                                                <li><NavLink to="/contact-us">Contact Us</NavLink></li>

                                            </ul>

                                        </li>




                                        <li className="dropdown" style={{ marginLeft: "25px", cursor: "pointer" }}>
                                            <Avatar
                                                onClick={handleDropdownClick}
                                                sx={{ width: 28, height: 28, color: "#020332", backgroundColor: "aliceblue" }} // Adjust size here
                                            />
                                            <ul className="d-header-menu">

                                                <li>


                                                    <MenuItem onClick={() => (handleRouting("my-profile"))}>
                                                        <ListItemIcon>
                                                            <AccountBoxIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        My Account
                                                    </MenuItem>
                                                    <MenuItem onClick={() => (handleRouting("homam-bookings"))}>
                                                        <ListItemIcon>
                                                            <EventAvailableRoundedIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        My Bookings
                                                    </MenuItem>
                                                    <MenuItem onClick={() => (handleRouting("homam-bookings-history"))}>
                                                        <ListItemIcon>
                                                            <HistoryIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Payment History
                                                    </MenuItem>
                                                    <Divider />
                                                    <MenuItem onClick={() => signuptoSignout()}>
                                                        <ListItemIcon>
                                                            <Logout fontSize="small" />
                                                        </ListItemIcon>
                                                        Sign Out
                                                    </MenuItem>
                                                </li>
                                            </ul>

                                        </li>


                                    </>
                            }








                        </ul>

                        <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle} />
                    </nav>
                </div >
            </header >
        </>
    );
};

export default DashboardHeader;