import React, { useState, useEffect } from 'react';
import LandingHeader from "../components/LandingHeader";
import Top20px from "../screens/Top20px";
import FooterComponent from "../components/footer";
import { Box, Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, Divider, Accordion, AccordionSummary, AccordionDetails, Container, TextField, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"
import StarsIcon from '@mui/icons-material/Stars';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Search } from '@mui/icons-material';
import SlidingHeader from '../components/SlidingHeader';

const HistoryPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [bookings, setBookings] = useState([]);
    const [isPremium, setPremium] = useState([]);

    const [totalspent, setTotalSpent] = useState([])


    useEffect(() => {
        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        const url = `https://backend.shubadinam.com/gethistory?localId=${localId}`;

        axios.get(url)
            .then(response => {
                // console.log(response.data);
                const { purchaseDetails, uniqueBookings, homamNames } = response.data;

                const amountsArray = purchaseDetails.map(detail => ((detail.amount) / 100));
                setTotalSpent(amountsArray);

                // Combine data from purchaseDetails and uniqueBookings
                const combinedData = uniqueBookings.map((booking, index) => {
                    const purchase = purchaseDetails.find(p => p.orderId === booking.order_id);
                    return {
                        ...booking,
                        ...purchase,
                        homamName: homamNames[index] || ''
                    };
                });


                setBookings(combinedData);
            })
            .catch(error => {
                // console.log(error);
            });
    }, []);


    useEffect(() => {
        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        const url = `https://backend.shubadinam.com/getisPremium?localId=${localId}`;

        axios.get(url)
            .then(response => {
                // console.log(response.data);

                setPremium(response.data);
                const newTotalSpent = response.data[0].amount;
                setTotalSpent(prevAmounts => [...prevAmounts, (newTotalSpent / 100)]);



            })
            .catch(error => {
                // console.log(error);
            });
    }, []);
    // console.log(totalspent);


    const spentSum = totalspent.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue);
    }, 0);

    const [searchQuery, setSearchQuery] = useState('');

    const filteredBookings = bookings.filter((booking) =>
        Object.values(booking).some((field) =>
            field != null && field.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    return (
        <Box>
            <SlidingHeader />
            <Container sx={{ mt: 15, mb: 2 }}>
                <Typography variant='h6' gutterBottom>Payment History</Typography>

                <Box sx={{ width: "100%", display: "flex", justifyContent: "end", p: 2 }}>
                    <TextField
                        label="Search here"
                        variant="outlined"
                        sx={{ maxWidth: 300 }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        margin="normal"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                {
                    isPremium.length === 0 && bookings.length === 0 ? (
                        <Typography variant="h6" color="text.secondary" align="center" sx={{ p: 2 }}>
                            Your payment history is empty.
                        </Typography>
                    ) :
                        isMobile ? (
                            // Mobile view using cards
                            <Grid container spacing={3}>
                                {/* Premium Users */}
                                {isPremium.map((premium, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={premium.id}>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} sm={1}>
                                                        <StarsIcon />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography>{premium.userName}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography>{premium.userEmail}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Typography>{premium.userPhone}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography>Premium - Rs. {premium.amount / 100}/-</Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography variant="body2">Amount: Rs. {premium.amount / 100}/-</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography variant="body2">Invoice Id: {premium.orderId}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography variant="body2">
                                                            Purchased On: {new Date(premium.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}{' '}
                                                            - {new Date(premium.entry_time).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit', hour12: true })}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                ))}

                                {/* Regular Bookings */}
                                {filteredBookings.slice().reverse().map((booking, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={booking.id}>
                                        <Accordion >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ bgcolor: "#eeee" }}>
                                                <Typography variant="body5" color="primary">
                                                    {bookings.length - index}.  {" "}{booking.homamName} -
                                                    {new Date(booking.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {/* First Row */}
                                                <Grid container spacing={2} alignItems="center">
                                                    {/* Entry Number */}
                                                    {/* <Grid item xs={12} sm={1}>
                                                        <Typography variant="subtitle2" color="textSecondary"># {index + 1}</Typography>
                                                    </Grid> */}
                                                    <Typography variant="subtitle2" color="textSecondary" sx={{ p: 2 }}>Payment Details:</Typography>
                                                    {/* Name */}
                                                    <Grid item xs={12} >
                                                        <Typography variant="subtitle2" color="textSecondary">Name:</Typography>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>{booking.accountHolderName}</Typography>
                                                    </Grid>

                                                    {/* Email */}
                                                    {/* <Grid item xs={12} >
                                                        <Typography variant="subtitle2" color="textSecondary">Email:</Typography>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>{booking.accountHolderEmail}</Typography>
                                                    </Grid> */}
                                                </Grid>

                                                {/* Spacer between rows */}
                                                <Grid item xs={12}><div style={{ margin: '8px 0' }} /></Grid>

                                                {/* Second Row */}
                                                <Grid container spacing={2} alignItems="center">
                                                    {/* Phone */}
                                                    {/* <Grid item xs={12}>
                                                        <Typography variant="subtitle2" color="textSecondary">Phone:</Typography>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>{booking.accountHolderPhone}</Typography>
                                                    </Grid> */}

                                                    {/* Purchase Date */}
                                                    <Grid item xs={12} >
                                                        <Typography variant="subtitle2" color="textSecondary">Purchase Date:</Typography>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                            {new Date(booking.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })} -
                                                            {new Date(booking.entry_time).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit', hour12: true })}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                {/* Divider */}
                                                <Divider style={{ margin: '16px 0' }} />

                                                <Grid container spacing={2} alignItems="center">
                                                    {/* Amount */}
                                                    <Grid item xs={12} >
                                                        <Typography variant="subtitle2" color="textSecondary">Amount:</Typography>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>Rs. {booking.amount}/-</Typography>
                                                    </Grid>

                                                    {/* Invoice ID */}
                                                    <Grid item xs={12} >
                                                        <Typography variant="subtitle2" color="textSecondary">Invoice Id:</Typography>
                                                        <Typography variant="body1" style={{ fontWeight: 500 }}>{booking.orderId}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>

                                        </Accordion>
                                    </Grid>
                                ))}

                            </Grid>
                        ) : (
                            // Desktop view using table
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>



                                        <TableRow>
                                            <TableCell sx={{ width: 100 }}>S.no</TableCell>
                                            <TableCell>Name</TableCell>
                                            {/* <TableCell>E-mail</TableCell>
                                            <TableCell sx={{ width: 150 }}>Phone</TableCell> */}
                                            <TableCell>Purchased</TableCell>
                                            <TableCell sx={{ width: 100 }}>Amount</TableCell>
                                            <TableCell>Invoice Id</TableCell>
                                            <TableCell>Purchased On</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isPremium.map((premuim, index) => (
                                            <TableRow key={premuim.id} sx={{ backgroundColor: "aliceblue" }}>
                                                <TableCell><StarsIcon /></TableCell>
                                                <TableCell>{premuim.userName}</TableCell>
                                                <TableCell> Premium</TableCell>
                                                <TableCell>Rs. {premuim.amount / 100}/-</TableCell>
                                                <TableCell>{premuim.orderId}</TableCell>
                                                <TableCell>
                                                    {new Date(premuim.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {filteredBookings.slice().reverse().map((booking, index) => (
                                            <TableRow key={booking.id}>
                                                <TableCell>{filteredBookings.length - index}</TableCell>
                                                <TableCell>{booking.accountHolderName}</TableCell>
                                                <TableCell>{booking.homamName}</TableCell>
                                                <TableCell>Rs. {booking.amount / 100}/-</TableCell>
                                                <TableCell sx={{ overflow: "auto", }}>{booking.orderId}</TableCell>
                                                <TableCell sx={{ overflow: "auto", }}>
                                                    {new Date(booking.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                }
            </Container>
            <FooterComponent />
        </Box>
    );
};

export default HistoryPage;
