import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../components/footer";
import Button from '@mui/material/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
import { Typography } from "@mui/material";
import TestimonialScreen from "./testimonials";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SlidingHeader from "../components/SlidingHeader";
import PageSlider from "./utils/PageSlider";
import SevalandingTestimonial from "../homamutils/SevalandingTestimonial";


import {
    Box,
    Grid,
    Card,
    CardContent,
    Chip,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Grow,
    Hidden,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSwipeable } from 'react-swipeable';
import Cancel from "@mui/icons-material/Cancel";
import { Star } from "@mui/icons-material";




const Pricing = () => {


    const Navigate = useNavigate();

    const isLogged = localStorage.getItem("token");

    // MENU BAR

    const [isGreater768, setGreater768] = useState(false);
    const auth = localStorage.getItem("isAuthenticated");
    const [isFreeUser, setFreeUser] = useState(false);
    const [daysLeft, setDaysLeft] = useState(0);
    const [timeLeft, setTimeLeft] = useState("")


    useEffect(() => {

        window.scroll(0, 0);

        const secureId = localStorage.getItem("SD_Id");

        if (secureId) {
            const url = `https://backend.shubadinam.com/getpremium?secureId=${secureId}`

            axios.get(url)
                .then((response) => {
                    setTimeLeft(response.data.timeLimit)
                    calculateDaysLeft(response.data.timeLimit)
                    if (response.data.type === "premium") {
                        setFreeUser(false)
                    } else {
                        setFreeUser(true)
                    }

                })
                .catch((error) => {
                    console.log(error)
                    // alert("Something unexpected happen.Please try again after sometime !")
                })

        }

        const isFreeRegister = sessionStorage.getItem("isFree")

        if (auth || isFreeRegister) {
            setFreeUser(true)
        }


        const handleResize = () => {
            setGreater768(window.innerWidth > 768);
        };

        // Initial setup
        handleResize();

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [Navigate]);


    const calculateDaysLeft = (purchaseDate) => {
        const purchaseDateTime = new Date(purchaseDate);
        const currentDate = new Date();
        const endDate = new Date(purchaseDateTime);
        endDate.setDate(purchaseDateTime.getDate() + 365); // Adding 365 days

        const timeDifference = endDate - currentDate;
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        setDaysLeft(daysLeft);
    };

    const gotoCheckout = (page) => {
        Navigate(`/${page}`)
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => console.log('Swiped Left'),
        onSwipedRight: () => console.log('Swiped Right'),
        trackMouse: true,  // Optional: track mouse events for swipe-like behavior on desktop
    });



    const PricingCard = ({ title, price, features, popular, available }) => (

        <Grow in timeout={1000}>
            <Card
                sx={{
                    p: { xs: 0, md: 3 },
                    borderRadius: 3,
                    boxShadow: 5,
                    backgroundColor: popular ? "#fffbeb" : "#ffffff",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                        transform: "scale(1.05)",
                    },
                    position: "relative",
                    overflow: "visible",
                    width: { xs: "300px", md: "auto" }
                }}
            >
                {popular && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "#f59e0b",
                            color: "white",
                            fontWeight: "bold",
                            px: 2,
                            py: 0.5,
                            borderRadius: "15px",
                            boxShadow: 3,
                        }}
                    >
                        Popular
                    </Box>
                )}
                <CardContent>
                    <Typography
                        variant="h6"
                        fontWeight="700"
                        gutterBottom
                        sx={{ fontSize: { xs: "1rem", md: "1.25rem" } }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h3"
                        fontWeight="800"
                        gutterBottom
                        sx={{ fontSize: { xs: "2rem", md: "3rem" }, color: popular ? "darkorange" : "#3b82f6" }}
                    >
                        ₹{price}
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ fontWeight: "400", fontSize: { xs: "0.9rem", md: "1.2rem" }, ml: 0.5 }}
                        >
                            {popular ? "Yearly" : ""}
                        </Typography>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        fontWeight="600"
                        sx={{
                            mb: 2,
                            textTransform: "uppercase",
                            color: "text.secondary",
                            fontSize: { xs: "0.8rem", md: "1rem" },
                        }}
                    >
                        Features
                    </Typography>
                    <List disablePadding>
                        {features.map((feature, index) => (
                            <ListItem
                                key={index}
                                disableGutters
                                sx={{
                                    py: 0.5,
                                    backgroundColor: index % 2 === 0 ? "#f9fafb" : "#ffffff",
                                    borderRadius: 1,
                                }}
                            >
                                <ListItemIcon>
                                    {available[index] ? (
                                        <CheckCircleIcon
                                            sx={{
                                                color: popular ? "darkorange" : "#3b82f6",
                                                fontSize: { xs: "1rem", md: "1.5rem" },
                                            }}
                                        />
                                    ) : (
                                        <Cancel
                                            sx={{
                                                color: "grey",
                                                fontSize: { xs: "1rem", md: "1.5rem" },
                                            }}
                                        />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={feature}
                                    primaryTypographyProps={{
                                        fontSize: { xs: "0.75rem", md: "0.875rem" },
                                        fontWeight: "500",
                                        color: !available[index] ? "grey" : "inherit",
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>

                    {
                        isLogged && !isFreeUser && daysLeft > 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column', // Stack elements vertically
                                    alignItems: 'center',
                                    backgroundColor: '#f5f5f5',
                                    padding: { xs: '8px 12px', sm: '10px 15px' },
                                    borderRadius: '8px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                    color: '#333',
                                    width: '100%',
                                    maxWidth: '600px',
                                    margin: '0 auto',
                                }}
                            >
                                {/* First line with Premium Purchased text */}
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: { xs: '4px', sm: '8px' } }}>
                                    <Star sx={{ color: 'darkorange', marginRight: 1, fontSize: { xs: '1.5rem', sm: '2rem' } }} />
                                    <Typography
                                        variant="body1"
                                        fontWeight="500"
                                        sx={{
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                        }}
                                    >
                                        Premium Purchased!
                                    </Typography>
                                </Box>

                                {/* Second line with days left and renewal message */}
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        variant="body1"
                                        fontWeight="500"
                                        sx={{
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                        }}
                                    >
                                        Only
                                    </Typography>
                                    <Chip
                                        label={`${daysLeft} days`}
                                        sx={{
                                            backgroundColor: 'darkorange',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                            marginLeft: '5px',
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        fontWeight="500"
                                        sx={{
                                            marginLeft: 1,
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                        }}
                                    >
                                        to renewal.
                                    </Typography>
                                </Box>
                            </Box>
                        )
                            :

                            <Button
                                onClick={() => gotoCheckout(popular ? "premium-checkout" : "register")}
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 2,
                                    background: popular
                                        ? "linear-gradient(90deg, #f59e0b, #d97706)"
                                        : "linear-gradient(90deg, #4b6cb7, #182848)",
                                    color: "white",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    borderRadius: 2,
                                    py: { xs: 1, md: 1.5 },
                                    fontSize: { xs: "0.85rem", md: "1rem" },
                                    boxShadow: popular
                                        ? "0px 4px 15px rgba(245, 158, 11, 0.4)"
                                        : "0px 4px 12px rgba(0, 0, 0, 0.15)",
                                    transition: "all 0.3s ease-in-out",
                                    "&:hover": {
                                        background: popular
                                            ? "linear-gradient(90deg, #d97706, #f59e0b)"
                                            : "linear-gradient(90deg, #182848, #4b6cb7)",
                                        boxShadow: popular
                                            ? "0px 6px 20px rgba(245, 158, 11, 0.5)"
                                            : "0px 6px 18px rgba(0, 0, 0, 0.25)",
                                        transform: "scale(1.05)",
                                    },
                                    "&:active": {
                                        transform: "scale(0.97)",
                                        boxShadow: popular
                                            ? "0px 3px 10px rgba(245, 158, 11, 0.3)"
                                            : "0px 3px 8px rgba(0, 0, 0, 0.15)",
                                    },
                                }}
                            >

                                {popular ? (
                                    "Get Premium Plan"
                                ) : (
                                    "Choose Basic Plan"
                                )}

                            </Button>

                    }
                </CardContent>
            </Card>
        </Grow>
    );






    return (




        <div>
            <SlidingHeader />

            <Box
                sx={{
                    textAlign: "center",
                    py: 10,
                    px: { xs: 2, md: 3 },
                    background: "linear-gradient(to bottom, #3b82f6, #1e293b)",
                    color: "white",
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h4"
                    fontWeight="700"
                    gutterBottom
                    sx={{ fontSize: { xs: "1.5rem", md: "2.125rem" }, color: "#0c197d" }}
                >
                    What You Get
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ mb: 4, fontSize: { xs: "0.9rem", md: "1rem" } }}
                >
                    Shubadinam helps you to find out your Birthday based on Indian Calendar / Panchang. Join Free or Choose Premium Membership for Exclusive Benefits.
                </Typography>


                <Box sx={{
                    display: { xs: "flex", md: "flex" },
                    width: "100% !important",
                    overflowX: 'auto',  // Enables horizontal scrolling
                    position: 'relative',
                    borderRadius: 1,
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
                    '&::-webkit-scrollbar': {
                        height: '8px', // Custom height of the scrollbar
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#38a1f7',  // Custom color for the thumb (the part that moves)
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#053559',  // Custom color for the track (the part the thumb moves along)
                    },
                }}>
                    <Box
                        sx={{
                            width: "100% !important",
                            display: 'flex',
                            alignItems: { xs: "none", md: "center" },
                            justifyContent: { xs: "none", md: "center" },
                            gap: 2,
                            mb: 2
                        }}
                        {...swipeHandlers} // Attach swipeable handlers here
                    >
                        <Grid>
                            <PricingCard
                                title="Basic plan"
                                price="0/ Free"
                                features={[
                                    "Find Nakshatra / Janma Tithi(As per Indian calender)",
                                    "Add family and friends(Unlimited Entries)",
                                    "Email Reminder(India and Global)",
                                    "Find Nakshatra / Janma Tithi(As per English Date of Birth)",
                                    "One Free Virtual Puja Seva Slot",
                                    "Free Delivery For Merchandise(India only)",
                                    "Multiple Advance reminders to plan your celebration",
                                    "SMS Reminder(India and Global)",
                                    "WhatsApp Reminder(India and Global)",
                                    "Ads Free",
                                ]}
                                available={[true, true, true, false, false, false, false, false, false]} // Mark unavailable features with false
                            />
                        </Grid>
                        <Grid>
                            <PricingCard
                                title="Premium plan"
                                price="499"
                                popular={true}
                                features={[
                                    "Find Nakshatra / Janma Tithi(As per Indian calender)",
                                    "Find Nakshatra / Janma Tithi(As per English Date of Birth)",
                                    "Add family and friends(Unlimited Entries)",
                                    "One Free Virtual Puja Seva Slot",
                                    "Free Delivery For Merchandise(India only)",
                                    "Multiple Advance reminders to plan your celebration",
                                    "Email Reminder(India and Global)",
                                    "SMS Reminder(India and Global)",
                                    "WhatsApp Reminder(India and Global)",
                                    "Ads Free",
                                ]}
                                available={[true, true, true, true, true, true, true, true, true, true]}
                            />
                        </Grid>
                    </Box>
                </Box>

            </Box>
            <FooterComponent />
        </div>




    );
};

export default Pricing;

