import React, { useEffect } from "react";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import ScrollToTop from "react-scroll-to-top";

import Top10px from "./Top10px";
import BottomSpace from "./BottomSpace";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageSlider from "./utils/PageSlider";
import SlidingHeader from "../components/SlidingHeader";
import { Container, Typography, Grid, Box } from "@mui/material";

const PrivacyPolicyScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Privacy Policy</title>
                    <meta name="description" content="Privacy Policy - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle="Privacy Policy"
            />
            <SlidingHeader />

            <Container maxWidth="lg" >
                <ScrollToTop smooth />

                {/* Content Section */}
                <Box mt={5}>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>
                                Introduction:
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                Shubadinam.com values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you use our website ("Site").
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>
                                Information We Collect:
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                We may collect personal information from you when you register on our Site, subscribe to our newsletter, or use certain features of the Site. This information may include your name, email address, birthdate, and any other information you voluntarily provide.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>
                                How We Use Your Information:
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                We use your information to provide and improve our services, respond to your inquiries, personalize your experience, and send you updates and promotions related to our Site.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>
                                Third-Party Service Providers:
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                We may share your information with third-party service providers to assist us in operating the Site and providing services to you. These providers are obligated to maintain the confidentiality and security of your information.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>
                                Cookies and Similar Technologies:
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                We may use cookies and similar technologies to collect information about your browsing activities on the Site. You can manage your cookie preferences through your browser settings.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>
                                Data Security:
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                We implement industry-standard security measures to protect your information from unauthorized access, disclosure, or alteration.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>
                                Children's Privacy:
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                Our Site is not intended for use by children under the age of 13. If we become aware that we have collected personal information from a child under 13 without parental consent, we will take steps to delete that information.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5" sx={{ color: "#38a1f7", fontWeight: 600 }}>
                                Changes to the Privacy Policy:
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                We reserve the right to modify or update this Privacy Policy at any time without notice. Any changes will be effective immediately upon posting on the Site.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <BottomSpace />
            </Container>

            <FooterComponent />

        </div>
    );
}

export default PrivacyPolicyScreen;
