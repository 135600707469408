import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import FooterComponent from "../components/footer";
import Swal from 'sweetalert2';
import Top10px from "./Top10px";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Button } from "@mui/material";
import SlidingHeader from "../components/SlidingHeader";



AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});


const SigninPage = () => {



    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.refresh();
    }, []);

    const sectionRef = useRef(null);
    const formRef = useRef(null);


    const [dynamicInput, dynamicInputFunction] = useState({
        userPhone: "",
        countryCode: "91"
    });


    const inputHandle = (event) => {
        const { name, value } = event.target;

        if (name === "userPhone") {
            dynamicInputFunction({ ...dynamicInput, userPhone: value });
        }
    };

    const handleCountryClick = (number) => {
        const numericCountryCode = number.replace(/\D/g, '');

        dynamicInputFunction({ ...dynamicInput, countryCode: numericCountryCode });
    };



    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const [isOptSent, SetIsOtpSent] = useState(false);

    const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);

    const clickSubmitcheck = () => {
        const userPhone = dynamicInput.userPhone;
        const countryCode = dynamicInput.countryCode;

        const url = `https://backend.shubadinam.com/createSigninOtp?userPhone=${userPhone}&&countryCode=${countryCode}`;

        axios.get(url)
            .then((response) => {
                console.log(response);
                const otpSent = response.data.message; // Message about the OTP sent
                const userId = response.data.userId; // User ID from the response
                const userEmail = response.data.userEmail; // User email if available

                // Assuming that userEmail is sent in the response, handle it accordingly
                if (userEmail) {
                    localStorage.setItem("fkey", btoa(JSON.stringify(userEmail))); // Store the encoded user email
                }

                // Display success alert with OTP message
                Swal.fire({
                    title: 'OTP Sent',
                    text: otpSent,
                    icon: 'success',
                    html: `${otpSent}<br><br><span style="color: red; font-size: 12px;">(* In case you did not receive the SMS OTP, you may use the Email OTP)</span>`,
                    confirmButtonText: 'Got it!',
                    confirmButtonColor: '#3085d6',
                    background: '#f5f5f5',
                });

                // Set the flag indicating OTP has been sent
                SetIsOtpSent(true);
            })
            .catch((error) => {
                if (error.response.status === 501) {
                    Swal.fire({
                        title: 'No Records Found',
                        text: error.response.data.message,
                        icon: 'warning',
                        confirmButtonText: 'Got it!',
                        confirmButtonColor: '#3085d6',
                        background: '#f5f5f5',
                    });
                } else {
                    // Handle other errors
                    Swal.fire({
                        title: 'Error',
                        text: 'An unexpected error occurred. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'Got it!',
                        confirmButtonColor: '#3085d6',
                        background: '#f5f5f5',
                    });
                }
            });
    };



    const verifySigninOtp = () => {

        const userPhone = dynamicInput.userPhone

        const signOtp = sessionStorage.getItem("otp");
        // console.log(signOtp)

        const url = `https://backend.shubadinam.com/verifySigninOtp?userPhone=${userPhone}&&userOtp=${signOtp}`

        axios.get(url)
            .then((response) => {
                // const validOtp = response.data.message
                // const navTo = localStorage.getItem("nav_")
                // if (navTo === "profile") {
                //     window.location.href = "/profile";
                //     return;
                // } else {
                const SD_Id = response.data.uniqueId;
                const token = response.data.token;


                localStorage.setItem("token", token);
                localStorage.setItem("SD_Id", SD_Id);
                localStorage.setItem('homeauth', 'true')

                window.location.href = "/dashboard";
                // clickSubmitbtn()
                // console.log(response);
                // }
            })

            .catch((error) => {
                // console.log(error);
                const invaildOtp = error.response.data.message

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5',
                });
            })
    }

    const resendOtp = () => {
        clickSubmitcheck()
    }

    const inputRefs = useRef([]);
    const [isNotNumber, setIsNotNumber] = useState(false)

    const handleInputChange = (index, value) => {

        if (!/^\d*$/.test(value)) {
            value = ""; // Set value to an empty string if not a number
            setIsNotNumber(true)
            return
        }
        setIsNotNumber(false)
        const updatedInputs = [...otpInputs];
        updatedInputs[index] = value;
        setOtpInputs(updatedInputs);

        // Combine all input values and store in sessionStorage
        const otpValue = updatedInputs.join("").trim();
        sessionStorage.setItem("otp", otpValue);

        setOtpInputs(updatedInputs);

        if (value && index < otpInputs.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index, event) => {
        // if (!/^\d$/.test(event.key)) {
        //     event.preventDefault();
        // }
        if (event.keyCode === 8 && !otpInputs[index] && index > 0) {
            // Handle Backspace key when input is empty
            inputRefs.current[index - 1].focus();
        }
    };




    return (


        <div className="overall-container">
            <HelmetProvider>
                <Helmet>
                    <title>Sign In </title>
                    <meta name="description" content="Sign In  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />



            <div className="overall-percent">
                <Top10px />

                <SlidingHeader />
                <br />

                <div className="double-container-signin" ref={sectionRef}>
                    {/* <br/> */}

                    <div className="signin-image-container" ref={formRef}>

                        <div className="signin-image">
                            {/* <video autoPlay loop muted className="signin-video">
                                <source src={require("../videos/777_1.mp4")} />
                            </video> */}
                            <img
                                src={require("../images/undraw_Gift_box_re_vau4.png")}
                                className="signin-video" onContextMenu={(e) => e.preventDefault()} />
                        </div>
                        <div className="signin-image-cnt2">
                            <p> <span style={{ fontWeight: "900" }}>Welcome Back:{" "}</span> Rediscover Your Janma Nakshatra/Tithi Journey. Reconnect with your cosmic path as you sign in.
                            </p>

                        </div>


                    </div>

                    <div className="login-form" >
                        <div className="login-flex-column">
                            <label>Registered Phone Number (Log In) </label></div>
                        <div className="login-inputForm">

                            {/* <input type="text" className="login-input" placeholder="Enter your Email" name="userEmail" onChange={inputHandle} onKeyPress={handleKeyPress} /> */}

                            <div className="ui-wrapper">
                                <input defaultChecked id="India" name="flag" type="radio" />
                                <input id="Austria" name="flag" type="radio" />
                                <input id="Belgium" name="flag" type="radio" />
                                <input id="India" name="flag" type="radio" />
                                <input id="Bulgaria" name="flag" type="radio" />
                                <input id="Croatia" name="flag" type="radio" />
                                <input id="Cyprus" name="flag" type="radio" />
                                <input id="Czech" name="flag" type="radio" />
                                <input id="Denmark" name="flag" type="radio" />
                                <input id="Estonia" name="flag" type="radio" />
                                <input id="Finland" name="flag" type="radio" />
                                <input id="France" name="flag" type="radio" />
                                <input id="Germany" name="flag" type="radio" />
                                <input id="Greece" name="flag" type="radio" />
                                <input id="Hungary" name="flag" type="radio" />
                                <input id="Iceland" name="flag" type="radio" />
                                <input id="Ireland" name="flag" type="radio" />
                                <input id="Italy" name="flag" type="radio" />
                                <input id="Latvia" name="flag" type="radio" />
                                <input id="Liechtenstein" name="flag" type="radio" />
                                <input id="Lithuania" name="flag" type="radio" />
                                <input id="Luxembourg" name="flag" type="radio" />
                                <input id="Malta" name="flag" type="radio" />
                                <input id="Netherlands" name="flag" type="radio" />
                                <input id="Norway" name="flag" type="radio" />
                                <input id="Poland" name="flag" type="radio" />
                                <input id="Portugal" name="flag" type="radio" />
                                <input id="Romania" name="flag" type="radio" />
                                <input id="Slovakia" name="flag" type="radio" />
                                <input id="Slovenia" name="flag" type="radio" />
                                <input id="Spain" name="flag" type="radio" />
                                <input id="Sweden" name="flag" type="radio" />
                                <input className="dropdown-checkbox" name="dropdown" id="dropdown" type="checkbox" />
                                <label className="dropdown-container" htmlFor="dropdown" />
                                <div className="input-wrapper">
                                    {/* <legend>
                                        <label htmlFor="phonenumber">
                                            Phonenumber*
                                        </label>
                                    </legend> */}
                                    <div className="textfield">
                                        <input pattern="\d+" maxLength={10} inputMode="numeric" value={dynamicInput.userPhone} id="phonenumber" type="text" name="userPhone" onChange={inputHandle} />
                                        <span className="invalid-msg">This is not a valid phone number</span>
                                    </div>
                                </div>
                                <div className="select-wrapper">
                                    <ul>
                                        <li className="India" onClick={() => handleCountryClick('+91')}><label htmlFor="India"><span>🇮🇳</span>India (+91)</label></li>
                                        <li className="Austria" onClick={() => handleCountryClick('+43')}><label htmlFor="Austria"><span>🇦🇹</span>Austria (+43)</label></li>
                                        <li className="Belgium" onClick={() => handleCountryClick('+32')}><label htmlFor="Belgium"><span>🇧🇪</span>Belgium (+32)</label></li>
                                        <li className="Bulgaria" onClick={() => handleCountryClick('+359')}><label htmlFor="Bulgaria"><span>🇧🇬</span>Bulgaria (+359)</label></li>
                                        <li className="Croatia" onClick={() => handleCountryClick('+385')}><label htmlFor="Croatia"><span>🇭🇷</span>Croatia (+385)</label></li>
                                        <li className="Cyprus" onClick={() => handleCountryClick('+357')}><label htmlFor="Cyprus"><span>🇨🇾</span>Cyprus (+357)</label></li>
                                        <li className="Czech" onClick={() => handleCountryClick('+420')}><label htmlFor="Czech"><span>🇨🇿</span>Czech Republic (+420)</label></li>
                                        <li className="Denmark" onClick={() => handleCountryClick('+45')}><label htmlFor="Denmark"><span>🇩🇰</span>Denmark (+45)</label></li>
                                        <li className="Estonia" onClick={() => handleCountryClick('+372')}><label htmlFor="Estonia"><span>🇪🇪</span>Estonia (+372)</label></li>
                                        <li className="Finland" onClick={() => handleCountryClick('+358')}><label htmlFor="Finland"><span>🇫🇮</span>Finland (+358)</label></li>
                                        <li className="France" onClick={() => handleCountryClick('+33')}><label htmlFor="France"><span>🇫🇷</span>France (+33)</label></li>
                                        <li className="Germany" onClick={() => handleCountryClick('+49')}><label htmlFor="Germany"><span>🇩🇪</span>Germany (+49)</label></li>
                                        <li className="Greece" onClick={() => handleCountryClick('+30')}><label htmlFor="Greece"><span>🇬🇷</span>Greece (+30)</label></li>
                                        <li className="Hungary" onClick={() => handleCountryClick('+36')}><label htmlFor="Hungary"><span>🇭🇺</span>Hungary (+36)</label></li>
                                        <li className="Iceland" onClick={() => handleCountryClick('+354')}><label htmlFor="Iceland"><span>🇮🇸</span>Iceland (+354)</label></li>
                                        <li className="Ireland" onClick={() => handleCountryClick('+353')}><label htmlFor="Ireland"><span>🇮🇪</span>Republic of Ireland (+353)</label></li>
                                        <li className="Italy" onClick={() => handleCountryClick('+39')}><label htmlFor="Italy"><span>🇮🇹</span>Italy (+39)</label></li>
                                        <li className="Latvia" onClick={() => handleCountryClick('+371')}><label htmlFor="Latvia"><span>🇱🇻</span>Latvia (+371)</label></li>
                                        <li className="Liechtenstein" onClick={() => handleCountryClick('+423')}><label htmlFor="Liechtenstein"><span>🇱🇮</span>Liechtenstein (+423)</label></li>
                                        <li className="Lithuania" onClick={() => handleCountryClick('+370')}><label htmlFor="Lithuania"><span>🇱🇹</span>Lithuania (+370)</label></li>
                                        <li className="Luxembourg" onClick={() => handleCountryClick('+352')}><label htmlFor="Luxembourg"><span>🇱🇺</span>Luxembourg (+352)</label></li>
                                        <li className="Malta" onClick={() => handleCountryClick('+356')}><label htmlFor="Malta"><span>🇲🇹</span>Malta (+356)</label></li>
                                        <li className="Netherlands" onClick={() => handleCountryClick('+31')}><label htmlFor="Netherlands"><span>🇳🇱</span>Netherlands (+31)</label></li>
                                        <li className="Norway" onClick={() => handleCountryClick('+47')}><label htmlFor="Norway"><span>🇳🇴</span>Norway (+47)</label></li>
                                        <li className="Poland" onClick={() => handleCountryClick('+48')}><label htmlFor="Poland"><span>🇵🇱</span>Poland (+48)</label></li>
                                        <li className="Portugal" onClick={() => handleCountryClick('+351')}><label htmlFor="Portugal"><span>🇵🇹</span>Portugal (+351)</label></li>
                                        <li className="Romania" onClick={() => handleCountryClick('+40')}><label htmlFor="Romania"><span>🇷🇴</span>Romania (+40)</label></li>
                                        <li className="Slovakia" onClick={() => handleCountryClick('+421')}><label htmlFor="Slovakia"><span>🇸🇰</span>Slovakia (+421)</label></li>
                                        <li className="Slovenia" onClick={() => handleCountryClick('+386')}><label htmlFor="Slovenia"><span>🇸🇮</span>Slovenia (+386)</label></li>
                                        <li className="Spain" onClick={() => handleCountryClick('+34')}><label htmlFor="Spain"><span>🇪🇸</span>Spain (+34)</label></li>
                                        <li className="Sweden" onClick={() => handleCountryClick('+46')}><label htmlFor="Sweden"><span>🇸🇪</span>Sweden (+46)</label></li>
                                    </ul>

                                </div>
                            </div>



                        </div>
                        <br />

                        {
                            isOptSent &&

                            <form id="reg-form" className="reg-form" style={{ alignSelf: "center" }}>
                                <div id="reg-title1" className="reg-title">OTP</div>
                                <p id="reg-message" className="reg-message">We have sent a verification code to your mobile number</p>
                                {
                                    isNotNumber && <p>(Note : Please enter numeric values.)</p>
                                }
                                <div id="reg-inputs" className="reg-inputs">
                                    {otpInputs.map((value, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength={1}
                                            value={value}
                                            ref={ref => inputRefs.current[index] = ref}
                                            onChange={e => handleInputChange(index, e.target.value)}
                                            onKeyDown={e => handleKeyDown(index, e)}
                                            inputMode="numeric"
                                        />
                                    ))}
                                </div>
                            </form>
                        }



                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                            gap={2} // spacing between buttons
                            sx={{ mt: 2 }}
                        >
                            {isOptSent ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="grey"
                                        onClick={resendOtp}
                                        sx={{ width: '150px', }}
                                    >
                                        Resend OTP
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={verifySigninOtp}
                                        sx={{ width: '150px' }}
                                    >
                                        Verify me
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={clickSubmitcheck}
                                    disabled={dynamicInput.userPhone.length < 10}
                                    sx={{ width: '150px', textAlign: "center" }}
                                >
                                    {isSubmitClicked ? 'Submitting...' : 'Submit'}
                                </Button>
                            )}
                        </Box>

                        <p className="login-p">Don't have an account?
                            <NavLink className="login-span" to="/pricing"><span >Sign Up</span></NavLink>
                        </p>



                    </div>


                    <span style={{ height: "10%" }}></span>




                </div>



                <FooterComponent></FooterComponent>

            </div>





        </div >

    );
};

export default SigninPage;