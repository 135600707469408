// NakshatraBlogs.js
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Grid, Card, CardContent, CardMedia, Typography, Button, Container } from '@mui/material';
import FooterComponent from "../components/footer";
import { NavigateNext } from "@mui/icons-material";
import PageSlider from "./utils/PageSlider";
import SlidingHeader from "../components/SlidingHeader";
import { nakshatraData } from "./utils/nakshatraData ";

const NakshatraBlogs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Nakshatra Blogs</title>
                <meta name="description" content="Nakshatra Blogs - Janma Nakshatra/Tithi Birthday Reminder Service." />
            </Helmet>
            <SlidingHeader />

            {/* Hero Section */}
            <PageSlider
                bgImage={require("../images/banners/all_hero_banner.jpg")}
                pageTitle={`Nakshatra Related Blogs`}
            />

            <Container maxWidth="lg" sx={{ p: 4 }}>
                <Grid container spacing={4}>
                    {nakshatraData.map((blog, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <a href={blog.link} style={{ textDecoration: 'none' }}>
                                <Card elevation={3} sx={{ height: '100%', borderRadius: "24px", bgcolor: "aliceblue" }}>
                                    <CardMedia
                                        component="img"
                                        height="160"
                                        image={blog.image}
                                        alt={blog.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5">
                                            {blog.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {blog.description}
                                        </Typography>
                                    </CardContent>
                                    <Button endIcon={<NavigateNext />} size="medium" variant="contained" color="primary" sx={{ margin: 2, textTransform: "none" }}>
                                        Read more
                                    </Button>
                                </Card>
                            </a>
                        </Grid>
                    ))}
                </Grid>

            </Container>
            <FooterComponent />
        </HelmetProvider>
    );
};

export default NakshatraBlogs;
